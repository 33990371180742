import { useAppDispatch, useAppSelector } from 'store/hook';
import { toggleLoginModal, toggleRegModal } from 'store/ui';
import { ClubApi } from 'apis';
import Modal from 'components/utils/Modal';
import { useEffect, useState } from 'react';
import { Club, ClubModel } from 'utils/types';
import { handleHTTPError } from 'store/error';
import constants from 'utils/constants';
import NewRegister from './NewRegister';
import WithClub from './WithClub';
import RadioBox from 'components/form-controls/RadioBox';

const REG_OPTIONS = {
    NEW: 'New Account',
    WITH_CLUBACCOUNT: 'With Club Account',
};

const RegisterModal = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.ui.isOpenRegModal);
    const [regOption, setRegOptioin] = useState(REG_OPTIONS.WITH_CLUBACCOUNT);
    const [clubs, setClubs] = useState<Club[]>([]);

    useEffect(() => {
        const params = {
            status: constants.CLUB_STATUS.ACTIVATED,
            isDefault: false,
            'ID.ne': constants.DEMOCLUB_ID,
            // allowReservations: true,
        };
        ClubApi.retrieve(params)
            .then((data) => {
                const clubs = (data.data as ClubModel[]).map((cData) => {
                    return new Club(cData);
                });
                setClubs(clubs);
            })
            .catch((error) => {
                dispatch(handleHTTPError(error));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = () => {
        dispatch(toggleRegModal());
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="sm:max-w-lg">
            <div className="flex justify-center -mt-4">
                <img src="/logo256.png" className=" w-24 h-24" alt="logo256" />
            </div>
            <h1 className="text-2xl tracking-wider text-gray-700 text-center font-bold mb-6">REGISTER</h1>
            <div className="px-12 pb-10">
                <div className="flex items-center justify-around mb-5">
                    <RadioBox
                        label="New Account"
                        checked={regOption === REG_OPTIONS.NEW}
                        id={REG_OPTIONS.NEW}
                        name="regOptions"
                        onChange={(checked) => {
                            if (checked) setRegOptioin(REG_OPTIONS.NEW);
                        }}
                    />
                    <RadioBox
                        label="With Club Account"
                        checked={regOption === REG_OPTIONS.WITH_CLUBACCOUNT}
                        id={REG_OPTIONS.WITH_CLUBACCOUNT}
                        name="regOptions"
                        onChange={(checked) => {
                            if (checked) setRegOptioin(REG_OPTIONS.WITH_CLUBACCOUNT);
                        }}
                    />
                </div>
                {regOption === REG_OPTIONS.NEW && <NewRegister />}
                {regOption === REG_OPTIONS.WITH_CLUBACCOUNT && <WithClub clubs={clubs} />}
                <p className="text-xs text-center font-light mt-8">Do you have an account of Community Events?</p>
                <p
                    className="text-emerald-600 hover:text-emerald-700 text-sm text-center cursor-pointer"
                    onClick={() => {
                        dispatch(toggleRegModal());
                        dispatch(toggleLoginModal());
                    }}
                >
                    Login
                </p>
            </div>
        </Modal>
    );
};

export default RegisterModal;
