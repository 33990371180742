import { SearchIcon } from '@heroicons/react/outline';
import { CmtyEventApi, UserApi } from 'apis';
import Button from 'components/form-controls/Button';
import TextBox from 'components/form-controls/TextBox';
import Modal from 'components/utils/Modal';
import Pagination from 'components/utils/Pagination';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleHTTPError } from 'store/error';
import { useAppDispatch } from 'store/hook';
import { showAlert } from 'store/ui';
import { classNames } from 'utils';
import { CmtyEvent, User, UserModel } from 'utils/types';
import GameLevelModal from './GameLevelModal';

type FormValues = {
    lastName: string;
};

type PropsType = {
    event: CmtyEvent;
    isOpen: boolean;
    toggle: () => void;
    callback: () => void;
};
const EventRegModal = ({ event, isOpen, toggle, callback }: PropsType) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [isOpenGameLevel, setOpenGameLevel] = useState<boolean>(false);

    const { register, formState, handleSubmit, reset, watch } = useForm<FormValues>({
        defaultValues: { lastName: '' },
    });
    const watchValues = watch(['lastName']);

    useEffect(() => {
        reset({ lastName: '' });
    }, [isOpen, reset]);

    const search = (data: FormValues) => {
        searchUsers(0, data.lastName);
    };

    const toggleGameLevel = () => {
        setOpenGameLevel(!isOpenGameLevel);
    };

    const searchUsers = (skip: number = 0, lastName?: string) => {
        if (!lastName) lastName = watchValues[0];
        setLoading(true);
        const params = { lastName, skip, isCmtyEventUser: true, limit: 5 };
        UserApi.retrieve(params)
            .then((data) => {
                setLoading(false);
                const users = (data.data as UserModel[]).map((user) => new User(user));
                setUsers(users);
                setTotalCount(data.totalCount || 0);
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    const regUser = (gameLevel?: number) => {
        if (!selectedUser) return;
        if (event.isRequireLevel() && !gameLevel) {
            return toggleGameLevel();
        }
        setLoading(true);
        const params = {
            event: event._id,
            user: selectedUser._id,
            gameLevel: event.isRequireLevel() ? gameLevel : undefined,
        };
        CmtyEventApi.regUser(params)
            .then((data) => {
                setLoading(false);
                dispatch(showAlert({ type: 'success', message: data.message }));
                toggle();
                callback();
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    const pageChange = (page: number) => {
        setCurrentPage(page);
        searchUsers(page, watchValues[0]);
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                size="sm:max-w-xl"
                title="Register Member"
                headBorder
                loading={loading}
            >
                <form className="p-4 md:p-6 grid grid-cols-6 gap-3" onSubmit={handleSubmit(search)} autoComplete="off">
                    <h4 className="text-red-500 text-center text-sm col-span-6 mb-3">
                        *** The system doesn't collect payment for registered members by admin ***
                    </h4>
                    <div className=" col-span-6 sm:col-span-2"></div>
                    <TextBox
                        className="col-span-6 sm:col-span-3"
                        name="lastName"
                        labelClassName="mb-2"
                        placeholder="michale"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <Button type="submit" className="col-span-6 sm:col-span-1">
                        <SearchIcon className="w-5 h-5 inline-block" />
                    </Button>
                </form>
                <div className="px-4 md:px-6 pb-4 md:pb-6">
                    <table className="min-w-full border rounded-md">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left"></th>
                                <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Name</th>
                                <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Age</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr
                                    key={index}
                                    className={classNames(
                                        'border-t text-sm text-gray-600 hover:bg-gray-300 cursor-pointer',
                                        selectedUser?._id === user._id
                                            ? 'bg-gray-300'
                                            : index % 2
                                            ? 'bg-gray-50'
                                            : 'bg-white'
                                    )}
                                    onClick={() => {
                                        setSelectedUser(user);
                                    }}
                                >
                                    <td className=" px-4 py-3"></td>
                                    <td className=" px-4 py-3">
                                        {user.fullName} ({user.getGender()}
                                        {user.getGameLevel() ? `:${user.getGameLevel()}` : ''})
                                    </td>
                                    <td className=" px-4 py-3">{user.age}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        totalCount={totalCount}
                        page={currentPage}
                        pageRangeDisplayed={3}
                        countPerPage={2}
                        onChange={pageChange}
                    />
                </div>
                <div className="flex justify-end  rounded-b-md px-6 py-4 bg-gray-50">
                    <Button
                        textColor="text-gray-700"
                        className="border border-gray-300"
                        color="bg-white"
                        hoverColor="bg-gray-50"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                    <Button className="ml-3 min-w-24" onClick={regUser}>
                        Ok
                    </Button>
                </div>
            </Modal>
            <GameLevelModal isOpen={isOpenGameLevel} toggle={toggleGameLevel} callback={regUser} />
        </>
    );
};

export default EventRegModal;
