import moment from 'moment-timezone';
import { useAppSelector } from 'store/hook';
import constants from 'utils/constants';
import { Club, CmtyEvent, CmtyEventLocation } from 'utils/types';
import CmtyEventPhotos from '../cmtyevent-photos';

type PropsType = {
    event: CmtyEvent;
};

const CmtyEventInfo = ({ event }: PropsType) => {
    const user = useAppSelector((state) => state.auth.user);
    const displayPrice = () => {
        if (event.isFree) return 'Free';
        const price = event.price || 0;
        const club = event.club as Club;
        if (user && user.originClubs.includes(club._id) && event.discount)
            return `$ ${price.toFixed(2)} (${event.discount * 100}% Discount)`;
        else return `$ ${price.toFixed(2)}`;
    };

    return (
        <div>
            <div className="pb-3">
                <label className="text-sm text-gray-500 font-normal mb-1 block">Club Name</label>
                <p className="text-base text-gray-700 font-medium">{(event.club as Club).displayName}</p>
            </div>
            <div className="py-3">
                <label className="text-sm text-gray-500 font-normal mb-1 block">Event Title</label>
                <p className="text-base text-gray-700 font-medium">{event.title}</p>
            </div>
            <div className="py-3">
                <label className="text-sm text-gray-500 font-normal mb-1 block">Description</label>
                <div className=" overflow-auto  max-h-40">
                    <p className="text-sm text-gray-700 font-medium">{event.description}</p>
                </div>
            </div>
            {event.venue !== constants.VIRTUAL_CMTYEVENT_VENUE && event.location && (
                <div className="py-3">
                    <label className="text-sm text-gray-500 font-normal mb-1 block">Event Place</label>
                    <p className="text-base text-gray-700 font-medium">
                        {(event.location as CmtyEventLocation).name} <span className="px-2 text-dark">|</span>{' '}
                        {(event.location as CmtyEventLocation).address}
                    </p>
                    <CmtyEventPhotos className="mt-1" photos={(event.location as CmtyEventLocation).photos} />
                </div>
            )}
            {event.venue === constants.VIRTUAL_CMTYEVENT_VENUE && event.url && (
                <div className="py-3">
                    <label className="text-sm text-gray-500 font-normal mb-1 block">Reference URL</label>
                    <p className="text-base text-gray-700 font-medium">{event.url}</p>
                </div>
            )}
            <div className="py-3">
                <label className="text-sm text-gray-500 font-normal mb-1 block">Event Date</label>
                <p className="text-base text-gray-700 font-medium">
                    {moment(event.start).format('ddd, MMM DD, YYYY')} {moment(event.start).format('hh:mm A')} ~{' '}
                    {moment(event.end).format('hh:mm A')}
                </p>
            </div>
            <div>
                <label className="text-sm text-gray-500 font-normal mb-1 block">Price</label>
                <p className="text-base text-gray-700 font-medium">{displayPrice()}</p>
            </div>
        </div>
    );
};

export default CmtyEventInfo;
