import { CmtyEventApi } from 'apis';
import CmtyEventList from 'components/cmtyevents/eventlist';
import Select from 'components/form-controls/Select';
import Pagination from 'components/utils/Pagination';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { handleHTTPError } from 'store/error';
import { useAppDispatch, useAppSelector } from 'store/hook';
import constants from 'utils/constants';
import { Club, CmtyEvent, CmtyEventModel, CmtyEventRegUser } from 'utils/types';

const AdvocateCmtyEvents = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.user);
    const clubs = useAppSelector((state) => state.club.clubs);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [events, setEvents] = useState<CmtyEvent[]>([]);
    const [club, setClub] = useState<string>();

    useEffect(() => {
        setCurrentPage(0);
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [club]);

    const search = useCallback(
        (skip = 0) => {
            const params: any = {
                'status.ne': constants.CMTYEVENT_STATUS.CANCELED,
                'start.gte': moment().format('YYYY-MM-DD HH:mm'),
                'club.or':
                    (!club || club === 'all') && (user?.advocateClubs ?? []).length > 0
                        ? user?.advocateClubs.join(',')
                        : club,
                skip,
                limit: 10,
                sort_by: 'start',
                order_by: 'asc',
                isReqAdvocator: true,
                advocator: user?._id,
            };
            fetchEvents(params);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [club]
    );

    const fetchEvents = (params: any) => {
        CmtyEventApi.retrieve(params)
            .then((data) => {
                let events: CmtyEvent[] = (data.data as CmtyEventModel[]).map((evt) => new CmtyEvent(evt));
                events = events.map((evt) => {
                    const regUsers = evt.regUsers as CmtyEventRegUser[];
                    evt.regUsers = (regUsers || []).filter((v, i, self) => self.indexOf(v) === i);
                    return evt;
                });
                setEvents(events);
                setTotalCount(data.totalCount || 0);
                if ((data.totalCount || 0) <= currentPage * 10) setCurrentPage(Math.max(0, currentPage - 1));
            })
            .catch((error) => {
                dispatch(handleHTTPError(error));
            });
    };

    const editEvent = (event: CmtyEvent) => {
        navigate(`/advocate-events/${event._id}`);
    };

    const pageChange = (page: number) => {
        setCurrentPage(page);
        search(page * 10);
    };

    const getAdvocateClubOptions = () => {
        const options = clubs
            .filter((x: Club) => (user?.advocateClubs || []).includes(x._id))
            .map((club) => {
                return { value: club._id, label: club.displayName };
            });
        return [{ value: 'all', label: 'All Advocate Organizations' }, ...options];
    };

    return (
        <div className="bg-white rounded-md p4 md:p-10">
            <div className="flex justify-end mb-4">
                <Select
                    name="advocateClub"
                    className="w-full md:w-60"
                    placeholder="All Organizations"
                    options={getAdvocateClubOptions()}
                    value={club}
                    onChange={(value) => setClub(value as string)}
                />
            </div>
            <CmtyEventList events={events} onEdit={editEvent} isAdvocate={true} />
            <Pagination
                totalCount={totalCount}
                page={currentPage}
                pageRangeDisplayed={5}
                countPerPage={10}
                onChange={pageChange}
            />
        </div>
    );
};

export default AdvocateCmtyEvents;
