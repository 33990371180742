import React from 'react';
import Button from 'components/form-controls/Button';

const HomeRegOrganization: React.FC = () => {
    return (
        <div
            className="w-full h-auto lg:h-screen white relative bg-white grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-0"
            id="reg-organization"
        >
            <div className="col-span-1 bg-white flex items-center">
                <div className="p-4 md:p-20 text-center">
                    <p className="text-center text-emerald-600 font-bold mb-5 text-xl">Organization</p>
                    <h2 className="w-full text-gray-800 text-4xl md:text-5xl text-center font-bold pl-4 md:pl-10 mb-10">
                        Register your Organization
                    </h2>
                    <p className=" text-gray-500 text-xl md:text-2xl text-center mb-20">
                        A great benefit for travelers, virtual club members, and park districts to gain greater revenue
                        traction with their resources​​
                    </p>
                    <Button
                        className="bg-transparent border-2 rounded-md border-emerald-600 hover:text-white text-2xl px-10"
                        textColor="text-emerald-600"
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_ADMIN_URL}/signup`);
                        }}
                    >
                        Register Organization
                    </Button>
                </div>
            </div>
            <div
                className="col-span-1 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: 'url("/images/carousel/2.jpg")' }}
            />
        </div>
    );
};

export default HomeRegOrganization;
