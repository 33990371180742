import { Fragment, useState, useRef, forwardRef, useEffect } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Controller } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { classNames } from 'utils';
import { XIcon } from '@heroicons/react/outline';

type PropsType = {
    className?: string;
    label?: string | JSX.Element;
    labelClassName?: string;
    required?: boolean;
    inputClassName?: string;
    name: string;
    defaultValue?: string | string[];
    value?: string | string[];
    options: SelectOptionType[];
    disabled?: boolean;
    clearable?: boolean;
    placeholder?: string;
    onChange?: (value: string | string[]) => void;
    valid?: boolean;
    validation?: any;
};

const SearchableSelect = ({
    label,
    className = '',
    labelClassName = '',
    required = false,
    name,
    options,
    value,
    disabled,
    onChange,
    defaultValue,
    placeholder,
    clearable = false,
    validation, // { control, rules, formState}
}: PropsType) => {
    const selectRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (selectRef.current && validation) {
            const errorKeys = Object.keys(validation?.formState?.errors);
            if (errorKeys.length > 0 && errorKeys[0] === name) selectRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation?.formState.submitCount]);

    return (
        <div className={className || ''}>
            {label && (
                <>
                    {typeof label === 'string' ? (
                        <label
                            htmlFor={name}
                            className={classNames(
                                'block text-sm font-medium text-gray-700 mb-1 text-left',
                                labelClassName || ''
                            )}
                        >
                            {label}
                            {required && <span className=" text-red-500 ml-1">*</span>}
                        </label>
                    ) : (
                        <>{label}</>
                    )}
                </>
            )}
            {validation?.control ? (
                <Controller
                    name={name}
                    control={validation.control}
                    rules={validation.rules}
                    render={({ field: { ref, ...rest } }) => (
                        <SingleSelect
                            defaultValue={defaultValue}
                            options={options}
                            disabled={disabled}
                            clearable={clearable}
                            placeholder={placeholder}
                            valid={!validation?.formState?.errors[name]}
                            ref={selectRef}
                            {...rest}
                        />
                    )}
                />
            ) : (
                <SingleSelect
                    name={name}
                    className={className}
                    value={value}
                    placeholder={placeholder}
                    options={options}
                    clearable={clearable}
                    disabled={disabled}
                    onChange={onChange}
                    ref={selectRef}
                />
            )}
        </div>
    );
};

export default SearchableSelect;

const SingleSelect = forwardRef<HTMLButtonElement, PropsType>(
    (
        {
            defaultValue,
            value,
            name,
            options,
            disabled,
            onChange,
            valid = true,
            clearable,
            placeholder = 'Select...',
        }: PropsType,
        ref
    ) => {
        const [selectedValue, selectValue] = useState<string | undefined>('');
        const [query, setQuery] = useState('');

        const filteredOptions =
            query === ''
                ? options
                : options.filter((option) =>
                      option.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
                  );

        useEffect(() => {
            if (options.length === 0) selectValue('');
            else selectValue(value as string);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, options]);

        return (
            <div className="w-full relative">
                <Combobox
                    value={selectedValue || ''}
                    disabled={disabled}
                    onChange={(value: string) => {
                        selectValue(value);
                        if (onChange) onChange(value);
                    }}
                >
                    <Combobox.Button
                        className={classNames(
                            'bg-white relative w-full min-h-10 border border-gray-300 rounded-md shadow-sm overflow-hidden flex items-center',
                            'pr-2 text-left focus:outline-none focus:ring-1 sm:text-sm cursor-pointer',
                            !valid
                                ? 'focus:border-red-500 focus:ring-red-500'
                                : 'focus:border-emerald-600 focus:ring-emerald-600'
                        )}
                        ref={ref}
                    >
                        <Combobox.Input
                            placeholder={placeholder}
                            autoComplete="off"
                            className="flex-grow border-none focus:ring-0 py-2 pr-5 text-sm leading-5 text-gray-900 min-w-24"
                            displayValue={(value: string) =>
                                filteredOptions.find((option) => option.value === value)?.label || ''
                            }
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        {clearable && value && (
                            <XIcon
                                className="w-4 h-4 text-gray-400 hover:text-gray-600"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    selectValue('');
                                    if (onChange) onChange('');
                                }}
                            />
                        )}
                        <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options
                            static
                            className={`w-full absolute mt-1 z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
                        >
                            {filteredOptions.length === 0 && query !== '' ? (
                                <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredOptions.map((option) => (
                                    <Combobox.Option
                                        key={option.value}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-emerald-600' : 'text-gray-900',
                                                'select-none relative py-2 pl-3 pr-9 cursor-pointer'
                                            )
                                        }
                                        value={option.value}
                                    >
                                        {({ selected, active }) => (
                                            <div className="flex pr-3 justify-between">
                                                <div
                                                    className={`${
                                                        active || selected ? 'font-semibold' : 'font-normal'
                                                    } truncate capitalize`}
                                                >
                                                    {option.label}
                                                </div>
                                                {option.icon && <div className="h-5 w-5 ">{option.icon}</div>}
                                                {selected ? (
                                                    <span
                                                        className={`${
                                                            active ? 'text-white' : 'text-emerald-600'
                                                        } absolute inset-y-0 right-0 flex items-center pr-4`}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </div>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </Combobox>
            </div>
        );
    }
);
