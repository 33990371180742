import constants from 'utils/constants';
import { Club, ClubModel, ClubMsg } from './club';
import { City } from './sys-setting';
import { User, UserModel } from './user';
import { getTimeZoneMoment } from 'utils';
import { CmtyEventIdea, CmtyEventIdeaModel } from './cmtyeventidea';

export type CmtyEventEligibleLevel = {
    from: number;
    to: number;
};

export type CmtyEventModel = {
    _id: string;
    groupId: string;
    club: ClubModel | string;
    start: string;
    end: string;
    location?: CmtyEventLocationModel | string;
    eventType: string;
    isMatchEvent?: boolean;
    isAdvocateOnly?: boolean;
    venue: string;
    url?: string;
    description: string;
    title: string;
    isRepeat: boolean;
    noExpired: boolean;
    isReqAdvocator: boolean;
    advocator?: UserModel | string;
    ageRanges: string[];
    eligibleGender: string;
    eligibleLevel?: CmtyEventEligibleLevel;
    canRegMultiWeeks: boolean;
    isReqRegister: boolean;
    maxRegCount: number;
    isRequireMsg: boolean;
    isFree: boolean;
    price?: number;
    isWeeklyPay: boolean;
    payBeforeDays?: number;
    payByDate: string;
    isActivated: boolean;
    clubMsg: ClubMsg | string;
    status: string;
    regUsers?: CmtyEventRegUserModel[] | string[];
    repeatWeeks?: number;
    displayColor: string;
    allowGuests: boolean;
    maxGuestsCount: number;
    discount?: number;
    referenceIdea?: CmtyEventIdeaModel | string;
    referenceIdeaUser?: UserModel | string;
};

export class CmtyEvent {
    _id: string;
    groupId: string;
    club: Club | string;
    start: string;
    end: string;
    location?: CmtyEventLocation | string;
    eventType: string;
    isMatchEvent: boolean;
    isAdvocateOnly: boolean;
    venue: string;
    url?: string;
    description: string;
    title: string;
    isRepeat: boolean;
    noExpired: boolean;
    isReqAdvocator: boolean;
    advocator?: UserModel | string;
    ageRanges: string[];
    eligibleGender: string;
    eligibleLevel?: CmtyEventEligibleLevel;
    canRegMultiWeeks: boolean;
    isReqRegister: boolean;
    maxRegCount: number;
    isRequireMsg: boolean;
    isFree: boolean;
    price?: number;
    isWeeklyPay: boolean;
    payBeforeDays?: number;
    payByDate: string;
    isActivated: boolean;
    clubMsg?: ClubMsg | string;
    status: string;
    regUsers: CmtyEventRegUser[] | string[];
    repeatWeeks: number;
    displayColor: string;
    allowGuests: boolean;
    maxGuestsCount: number;
    discount?: number;
    referenceIdea?: CmtyEventIdea | string;
    referenceIdeaUser?: User | string;

    constructor(data: CmtyEventModel) {
        this._id = data._id;
        this.groupId = data.groupId;
        this.club = data.club;
        this.start = data.start;
        this.end = data.end;
        this.location = data.location
            ? typeof data.location === 'string'
                ? data.location
                : new CmtyEventLocation(data.location)
            : undefined;
        this.eventType = data.eventType;
        this.isMatchEvent = data.isMatchEvent ?? false;
        this.isAdvocateOnly = data.isAdvocateOnly ?? false;
        this.venue = data.venue;
        this.url = data.url;
        this.description = data.description;
        this.title = data.title;
        this.isRepeat = data.isRepeat ?? true;
        this.noExpired = data.noExpired ?? false;
        this.isReqAdvocator = data.isReqAdvocator ?? false;
        this.advocator = data.advocator;
        this.ageRanges = data.ageRanges;
        this.eligibleGender = data.eligibleGender;
        this.eligibleLevel = data.eligibleLevel;
        this.canRegMultiWeeks = data.canRegMultiWeeks ?? true;
        this.isReqRegister = data.isReqRegister ?? true;
        this.maxRegCount = data.maxRegCount || 1000;
        this.isRequireMsg = data.isRequireMsg ?? false;
        this.isFree = data.isFree ?? false;
        this.price = data.price;
        this.isWeeklyPay = data.isWeeklyPay ?? true;
        this.payBeforeDays = data.payBeforeDays || 3;
        this.payByDate = data.payByDate;
        this.isActivated = data.isActivated ?? false;
        this.clubMsg = data.clubMsg;
        this.status = data.status || constants.CMTYEVENT_STATUS.PUBLISHED;
        this.regUsers = data.regUsers || [];
        this.repeatWeeks = data.repeatWeeks || 1;
        this.displayColor = data.displayColor;
        this.allowGuests = data.allowGuests ?? false;
        this.maxGuestsCount = data.allowGuests ? data.maxGuestsCount ?? 1 : 0;
        this.discount = data.discount || 0;
        this.referenceIdea = data.referenceIdea
            ? typeof data.referenceIdea === 'string'
                ? data.referenceIdea
                : new CmtyEventIdea(data.referenceIdea)
            : undefined;
        this.referenceIdeaUser = data.referenceIdeaUser
            ? typeof data.referenceIdeaUser === 'string'
                ? data.referenceIdeaUser
                : new User(data.referenceIdeaUser)
            : undefined;
    }

    getClub(): Club | null {
        if (this.club && typeof this.club !== 'string') return this.club;
        return null;
    }

    getClubId(): string | undefined {
        return typeof this.club === 'string' ? this.club : this.getClub()?._id;
    }

    getLocation(): CmtyEventLocation | null {
        if (this.location && typeof this.location !== 'string') return this.location;
        return null;
    }

    getLocationId(): string | undefined {
        return typeof this.location === 'string' ? this.location : this.getLocation()?._id;
    }

    getReferenceIdea(): CmtyEventIdea | null {
        if (this.referenceIdea && typeof this.referenceIdea !== 'string') return this.referenceIdea;
        return null;
    }

    getReferenceIdeaId(): string | undefined {
        return typeof this.referenceIdea === 'string' ? this.referenceIdea : this.getReferenceIdea()?._id;
    }

    getReferenceIdeaUser(): User | null {
        if (this.referenceIdeaUser && typeof this.referenceIdeaUser !== 'string') return this.referenceIdeaUser;
        return null;
    }

    getReferenceIdeaUserId(): string | undefined {
        return typeof this.referenceIdeaUser === 'string' ? this.referenceIdeaUser : this.getReferenceIdeaUser()?._id;
    }

    getWaitListCount(): number {
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const waitListCount = regUsers
            .filter((ru) => ru.isWaitList)
            .reduce((s, ru) => (s += (ru.guestsCount || 0) + 1), 0);
        return waitListCount;
    }

    getRegUserCount(): number {
        const regUsers = this.regUsers as CmtyEventRegUser[];
        let regUserCount = regUsers
            .filter((ru) => !ru.isWaitList)
            .reduce((s, ru) => (s += (ru.guestsCount || 0) + 1), 0);
        regUserCount = regUserCount >= this.maxRegCount ? this.maxRegCount : regUserCount;
        return regUserCount;
    }

    getUserRegistration(user?: User): CmtyEventRegUser | null {
        if (!user) return null;
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((regUser) => regUser.user === user?._id);
        return regUser || null;
    }

    getUserRegStatus(user?: User): string {
        if (!user) return '';
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((regUser) => regUser.user === user?._id);
        if (!regUser) return '';
        if (regUser.isWaitList) return 'waitlist';
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED) return 'confirmed';
        return 'registered';
    }

    getUserRegGuests(user?: User): Guest[] {
        if (!user) return [];
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((regUser) => regUser.user === user?._id);
        if (!regUser) return [];
        return regUser.guests || [];
    }

    didDonate(user?: User): boolean {
        if (!user) return false;
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((regUser) => regUser.user === user?._id);
        return regUser?.isDonated || false;
    }

    canReg(user?: User): boolean {
        if (getTimeZoneMoment((this.club as Club).timezone).format('YYYY-MM-DD HH:mm') > this.end) return false;
        if (
            this.status === constants.CMTYEVENT_STATUS.FINISHED ||
            this.status === constants.CMTYEVENT_STATUS.COMPLETED ||
            !this.isReqRegister
        )
            return false;
        if (!user) return true;
        if (this.isAdvocateOnly && (!user.isCmtyAdvocator || !user.isAdvocateClub(this.getClubId() ?? '')))
            return false;
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((regUser) => regUser.user === user._id);
        if (regUser) return false;
        return true;
    }

    canUnReg(user?: User): boolean {
        if (!user) return false;
        if (getTimeZoneMoment((this.club as Club).timezone).format('YYYY-MM-DD HH:mm') > this.end) return false;
        if (this.status === constants.CMTYEVENT_STATUS.FINISHED || this.status === constants.CMTYEVENT_STATUS.COMPLETED)
            return false;
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((regUser) => regUser.user === user?._id);
        if (regUser) return true;
        return false;
    }

    canPay(user?: User): boolean {
        if (!user) return false;
        if (this.isFree) return false;
        if (getTimeZoneMoment((this.club as Club).timezone).format('YYYY-MM-DD HH:mm') > this.end) return false;
        if (this.status === constants.CMTYEVENT_STATUS.FINISHED || this.status === constants.CMTYEVENT_STATUS.COMPLETED)
            return false;
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((regUser) => regUser.user === user._id);
        if (this.isMatchEvent && !regUser?.isSelected) return false;
        if (!regUser?.isWaitList && regUser?.status === constants.CMTYEVENT_REGUSER_STATUS.REGISTERED) return true;
        return false;
    }

    canUnCheckIn(user?: User): boolean {
        if (!user) return false;
        if (getTimeZoneMoment((this.club as Club).timezone).format('YYYY-MM-DD HH:mm') > this.end) return false;
        if (this.canReg(user)) return false;
        if (this.canPay(user)) return false;
        if (this.status === constants.CMTYEVENT_STATUS.FINISHED || this.status === constants.CMTYEVENT_STATUS.COMPLETED)
            return false;
        const regUsers = this.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((ru) => ru.user === user._id);
        if (
            !regUser?.isWaitList &&
            regUser?.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED &&
            regUser.isArrived
        )
            return true;
        return false;
    }

    canDisplayMap(): boolean {
        if (!this.location || this.venue === constants.VIRTUAL_CMTYEVENT_VENUE) return false;
        return (this.location as CmtyEventLocation).location?.lat ? true : false;
    }

    isCanRegMultiWeeks(user?: User): boolean {
        return this.canReg(user) && this.repeatWeeks > 1 && this.canRegMultiWeeks;
    }

    isFull(): boolean {
        return this.maxRegCount <= this.getRegUserCount();
    }

    shouldPay(): boolean {
        return getTimeZoneMoment((this.club as Club).timezone).format('YYYY-MM-DD') >= this.payByDate;
    }

    isRequireLevel(): boolean {
        if (!this.isMatchEvent) return false;
        if (!this.eligibleLevel) return false;
        // if (this.eligibleLevel?.from === 0 && this.eligibleLevel?.to === 1000) return false;
        return true;
    }

    isAllEligibleLevel = () => {
        return this.eligibleLevel?.from === 0 && this.eligibleLevel?.to === 1000;
    };

    canEditGuests = (user?: User | null) => {
        return (
            this.allowGuests &&
            user &&
            (this.canReg(user) || this.canUnReg(user) || this.canPay(user) || this.canUnCheckIn(user))
        );
    };
}

export type CmtyEventLocationModel = {
    _id: string;
    club?: ClubModel | string;
    user?: UserModel | string;
    name: string;
    city: string;
    county: string;
    state: string;
    zipCode: string;
    address: string;
    location: {
        lat: number;
        lng: number;
    };
    photos: string[];
    isActivated: boolean;
    isDeleted: boolean;
};
export class CmtyEventLocation {
    _id: string;
    club?: Club | string;
    user?: User | string;
    name: string;
    city: string;
    county: string;
    state: string;
    zipCode: string;
    address: string;
    location: {
        lat: number;
        lng: number;
    };
    photos: string[];
    isActivated: boolean;
    isDeleted: boolean;

    constructor(data: CmtyEventLocationModel) {
        this._id = data._id;
        this.club = data.club ? (typeof data.club === 'string' ? data.club : new Club(data.club)) : undefined;
        this.user = data.user ? (typeof data.user === 'string' ? data.user : new User(data.user)) : undefined;
        this.name = data.name;
        this.city = data.city;
        this.county = data.county;
        this.state = data.state;
        this.zipCode = data.zipCode;
        this.address = data.address;
        this.location = data.location;
        this.photos = data.photos;
        this.isActivated = data.isActivated ?? true;
        this.isDeleted = data.isDeleted ?? false;
    }
}

export type CmtyEventRegUserModel = {
    _id: string;
    event: CmtyEventModel | string;
    user: UserModel | string;
    preferPartner?: UserModel | string;
    partner?: UserModel | string;
    isWaitList: boolean;
    status: string;
    cancelBy?: string;
    cancelReason?: string;
    regBy?: string;
    payment: string;
    gameLevel?: number;
    guests: Guest[];
    guestsCount: number;
    isDonated?: boolean;
    isSelected?: boolean;
    isArrived?: boolean;
};

export class CmtyEventRegUser {
    _id: string;
    event: CmtyEventModel | string;
    user: UserModel | string;
    preferPartner?: UserModel | string;
    partner?: UserModel | string;
    isWaitList: boolean;
    status: string;
    cancelBy?: string;
    cancelReason?: string;
    regBy?: string;
    payment: string;
    gameLevel?: number;
    guests: Guest[];
    guestsCount: number;
    isDonated?: boolean;
    isSelected?: boolean;
    isArrived?: boolean;

    constructor(data: CmtyEventRegUserModel) {
        this._id = data._id;
        this.event = data.event;
        this.user = typeof data.user === 'string' ? data.user : new User(data.user);
        this.preferPartner = data.preferPartner;
        this.partner = data.partner;
        this.isWaitList = data.isWaitList;
        this.status = data.status;
        this.cancelBy = data.cancelBy;
        this.cancelReason = data.cancelReason;
        this.regBy = data.regBy;
        this.payment = data.payment;
        this.guests = data.guests || [];
        this.guestsCount = data.guestsCount || 0;
        this.isDonated = data.isDonated ?? false;
        this.gameLevel = data.gameLevel;
        this.isSelected = data.isSelected ?? false;
        this.isArrived = data.isArrived ?? false;
    }
}

export type CmtyEventRegResult = {
    eventDate: string;
    message?: string;
    regUser?: CmtyEventRegUserModel;
};

export type CmtyEventCanPayResult = {
    event: CmtyEventModel;
    canPay: boolean;
    message?: string;
    level?: 'success' | 'error' | 'warning';
    guests: Guest[];
};

export type CmtyEventFilterOptions = {
    zipCode?: string;
    city?: City;
    organization?: string;
    stDate?: string;
    etDate?: string;
    advocate?: string;
    eventType?: string;
    ageRanges?: string;
    eventFee?: string;
    venue?: string;
    eligibleGender?: string;
    eligibleLevel?: string;
};

export type Guest = {
    name: string;
    email?: string;
    phoneNumber?: string;
    isMember?: boolean;
};
