import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
import { AlertType, AlertModalState } from 'utils/types';

type UiState = {
    isOpenSideBar: boolean;
    isOpenLoginModal: boolean;
    isOpenRegModal: boolean;
    isOpenAlertModal: boolean;
    alertModal: AlertModalState;
    isOpenCmtyDetailModal: boolean;
    isOpenCmtyRegResultModal: boolean;
    isCommonLoading: boolean;
    hasMyIdeas: boolean;
};

const initialState: UiState = {
    isOpenSideBar: false,
    isOpenLoginModal: false,
    isOpenRegModal: false,
    isOpenAlertModal: false,
    isOpenCmtyDetailModal: false,
    isOpenCmtyRegResultModal: false,
    isCommonLoading: false,
    hasMyIdeas: false,
    alertModal: {
        message: '',
    },
};

const ui = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showAlert: (state, action: PayloadAction<AlertType>) => {
            const { type, message } = action.payload;
            if (message) {
                if (type === 'success') toast.success(message);
                if (type === 'error') toast.error(message);
                if (type === 'warning') toast.error(message);
            }
        },
        toggleSideBar: (state) => {
            state.isOpenSideBar = !state.isOpenSideBar;
        },
        toggleLoginModal: (state) => {
            state.isOpenLoginModal = !state.isOpenLoginModal;
        },
        toggleRegModal: (state) => {
            state.isOpenRegModal = !state.isOpenRegModal;
        },
        toggleCmtyEventDetailModal: (state) => {
            state.isOpenCmtyDetailModal = !state.isOpenCmtyDetailModal;
        },
        toggleCmtyRegResultModal: (state) => {
            state.isOpenCmtyRegResultModal = !state.isOpenCmtyRegResultModal;
        },
        showAlertModal: (state, action: PayloadAction<AlertModalState>) => {
            state.isOpenAlertModal = !state.isOpenAlertModal;
            state.alertModal = action.payload;
        },
        closeAlertModal: (state) => {
            state.isOpenAlertModal = !state.isOpenAlertModal;
        },
        setCommonLoading: (state, action: PayloadAction<boolean>) => {
            state.isCommonLoading = action.payload;
        },
        setHasMyIdeas: (state, action: PayloadAction<boolean>) => {
            state.hasMyIdeas = action.payload;
        },
    },
});

export const {
    toggleSideBar,
    toggleLoginModal,
    toggleRegModal,
    showAlert,
    showAlertModal,
    closeAlertModal,
    toggleCmtyEventDetailModal,
    toggleCmtyRegResultModal,
    setCommonLoading,
    setHasMyIdeas,
} = ui.actions;
export default ui.reducer;
