/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { putClubs, putCmtyClub, putIdeaDefaultClub, putSysSetting } from 'store/club';
import { ClubApi, CmtyEventIdeaApi } from 'apis';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { Club, ClubModel, SysSetting } from 'utils/types';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Layouts from 'layouts';
import SuspenseLoader from 'components/utils/SuspenseLoader';
import Home from 'pages/home';
import Profile from 'pages/profile';
import MyCmtyEvents from 'pages/myevents';
import AdvocateCmtyEventEdit from 'pages/advocate-events/edit';
import AdvocateCmtyEvents from 'pages/advocate-events';
import AdvocateOnlyCmtyEvents from 'pages/advocate-only-events';
import MyEventIdeas from 'pages/myeventideas';
import MyEventIdeaDetails from 'pages/myeventideas/Details';
import CmtyEventIdeas from 'pages/eventideas';
import CmtyEventIdeaDetails from 'pages/eventideas/Details';
import moment from 'moment-timezone';
import constants from 'utils/constants';
import { setHasMyIdeas } from 'store/ui';
import Invoices from 'pages/invoices';

const Loader = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const CmtyEvents = Loader(lazy(() => import('pages/cmtyevents')));
const CmtyEventDetails = Loader(lazy(() => import('pages/cmtyevents/Details')));

// const NotFound = Loader(lazy(() => import('pages/notfound')));
const AccountVerify = Loader(lazy(() => import('pages/account-verify')));

const PaymentComplete = Loader(lazy(() => import('pages/payments/complete')));
const PaymentCancel = Loader(lazy(() => import('pages/payments/Cancel')));
const PaymentByToken = Loader(lazy(() => import('pages/payments/ByToken')));
const PaymentCheckout = Loader(lazy(() => import('pages/payments/checkout')));

const publicRoutes = ['/cmtyevents', '/payments/cancel', '/payments/cmtyevent', '/payments/complete'];

const AppRoutes = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);

    const initApp = useCallback(() => {
        ClubApi.retrieve({ isDefault: true }).then((data) => {
            const clubs = (data.data as ClubModel[]) || [];
            const club = new Club(clubs[0]);
            dispatch(putCmtyClub(club));
        });
        ClubApi.retrieve({ isIdeaDefault: true }).then((data) => {
            const clubs = (data.data as ClubModel[]) || [];
            const club = new Club(clubs[0]);
            dispatch(putIdeaDefaultClub(club));
        });
        ClubApi.retrieve({ allowCmtyEvent: true }).then((data) => {
            const clubs = (data.data as ClubModel[]) || [];
            dispatch(putClubs(clubs));
        });
        ClubApi.sysSetting({}).then((data) => {
            dispatch(putSysSetting(data.data as SysSetting));
        });
        if (user) {
            const params = {
                user: user?._id,
                'apxStart.gte': moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                'status.or': [constants.CMTYEVENT_IDEA_STATUS.PUBLISHED].join(','),
            };
            CmtyEventIdeaApi.retrieve(params).then((data) => {
                if (data.totalCount > 0) dispatch(setHasMyIdeas(true));
                else dispatch(setHasMyIdeas(false));
            });
        }
    }, []);

    useEffect(() => {
        initApp();
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="accountverify">
                <Route path=":token" element={<AccountVerify />} />
            </Route>
            <Route path="*" element={<Layouts publicRoutes={publicRoutes} />}>
                <Route path="cmtyevents">
                    <Route index element={<CmtyEvents />} />
                    <Route path=":id" element={<CmtyEventDetails />} />
                </Route>
                <Route path="eventideas">
                    <Route index element={<CmtyEventIdeas />} />
                    <Route path=":id" element={<CmtyEventIdeaDetails />} />
                </Route>
                <Route path="payments">
                    <Route path="complete" element={<PaymentComplete />} />
                    <Route path="cancel" element={<PaymentCancel />} />
                    <Route path="checkout" element={<PaymentCheckout />} />
                    <Route path="cmtyevent">
                        <Route path=":token" element={<PaymentByToken />} />
                    </Route>
                </Route>
                <Route path="invoices" element={<Invoices />} />
                <Route path="myevents" element={<MyCmtyEvents />} />
                <Route path="advocate-only-events" element={<AdvocateOnlyCmtyEvents />} />
                <Route path="myeventideas">
                    <Route index element={<MyEventIdeas />} />
                    <Route path=":id" element={<MyEventIdeaDetails />} />
                </Route>
                <Route path="advocate-events">
                    <Route index element={<AdvocateCmtyEvents />} />
                    <Route path=":id" element={<AdvocateCmtyEventEdit />} />
                </Route>
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<Navigate to="cmtyevents" replace />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
