import { CheckCircleIcon, CurrencyDollarIcon, GiftIcon, PencilAltIcon } from '@heroicons/react/outline';
import Button from 'components/form-controls/Button';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { showAlertModal, toggleLoginModal } from 'store/ui';
import { classNames } from 'utils';
import constants from 'utils/constants';
import { Club, CmtyEvent, CmtyEventLocation, User } from 'utils/types';
import CmtyEventRegCount from '../CmtyEventRegCount';

type PropsType = {
    event: CmtyEvent;
    isAdvocator?: boolean;
    onSelect: () => void;
    onEdit: () => void;
};

const CmtyEventListItem = ({ event, isAdvocator, onSelect, onEdit }: PropsType) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const systemSetting = useAppSelector((state) => state.club.systemSetting);
    const user = useAppSelector((state) => state.auth.user);

    const onRegister = () => {
        if (!user) return dispatch(toggleLoginModal());
        if (!event.isReqRegister) return;

        const missingFields = [];
        if (!user.birthYear) missingFields.push('year of birth');
        if ((user.gender ?? constants.GENDER.NOPREFER) === constants.GENDER.NOPREFER)
            missingFields.push('correct gender');
        if (missingFields.length > 0) {
            return dispatch(
                showAlertModal({
                    type: 'warning',
                    title: 'Incomplete Profile',
                    message: `Please add ${missingFields.join(',')} in your profile`,
                    buttons: [
                        {
                            type: 'ok',
                            label: 'OK',
                            value: 'ok',
                        },
                    ],
                    handler: regModalResult,
                })
            );
        }
        onSelect();
    };

    const regModalResult = (value: string) => {
        navigate('/profile');
    };

    const onUnRegister = () => {
        if (!user) return dispatch(toggleLoginModal());
        onSelect();
    };

    const displayStatusBadge = () => {
        if (
            event.status === constants.CMTYEVENT_STATUS.FINISHED ||
            event.status === constants.CMTYEVENT_STATUS.COMPLETED
        ) {
            return (
                <div className="p-2 py-1 ml-3 rounded-full border border-amber-600 text-xs text-amber-600">
                    FINISHED
                </div>
            );
        }
        return '';
    };

    const displayRestrictRegBadge = () => {
        if (event.isReqRegister) return null;
        return (
            <p className="p-2 py-1 ml-3 text-center rounded-full border border-red-600 text-xs text-red-600">
                Restricted Registration
            </p>
        );
    };

    const displayAdvocateEventBadge = () => {
        if (!event.isAdvocateOnly) return null;
        return (
            <div className="p-2 py-1 ml-3 rounded-full border border-red-400 text-xs text-red-400">
                For Only Advocates
            </div>
        );
    };

    return (
        <div className="w-full border rounded-md p-6 mb-4 block lg:grid grid-cols-6 gap-2 text-gray-600 relative overflow-hidden">
            {event.canUnCheckIn(user) && (
                <div className="absolute -bottom-10 -right-10 rotate-45 bg-emerald-400 w-20 h-20" />
            )}
            <div className="col-span-5 block lg:grid grid-cols-3">
                <div className="col-span-2">
                    <p className="text-sm font-medium">
                        {moment(event.start).format('ddd, MMM DD, YYYY')} {moment(event.start).format('hh:mm A')} ~{' '}
                        {moment(event.end).format('hh:mm A')}
                    </p>
                    <div className="flex items-center flex-wrap">
                        <h5
                            className="text-lg text-emerald-600 leading-6 hover:text-emerald-800 hover:underline cursor-pointer inline-block"
                            onClick={onEdit}
                        >
                            {event.title}
                        </h5>{' '}
                        {displayAdvocateEventBadge()}
                        {displayRestrictRegBadge()}
                        {displayStatusBadge()}
                    </div>
                    <p className="text-sm leading-6">
                        <span className=" font-medium capitalize">{event.eventType}</span>
                        <span className="px-2">|</span>
                        <span className="font-medium capitalize">{event.venue}</span>
                        <span className="px-2">|</span>
                        <span className="font-medium capitalize">
                            {event.eligibleGender === constants.CMTYEVENT_GENDERTYPES.ALL
                                ? 'All Genders'
                                : event.eligibleGender}
                        </span>
                        <span className="px-2">|</span>
                        {event.isMatchEvent && (
                            <>
                                <span className="font-medium capitalize">
                                    {event.isAllEligibleLevel()
                                        ? 'All Levels'
                                        : `Level: (${event.eligibleLevel?.from} ~ ${event.eligibleLevel?.to})`}
                                </span>
                                <span className="px-2">|</span>
                            </>
                        )}
                        <span className="font-medium capitalize">
                            {event.ageRanges.length === systemSetting.cmtyEventAgeRanges.length
                                ? 'All Ages'
                                : event.ageRanges.join(',')}
                        </span>
                    </p>
                    <p className="text-xs mb-3">{`${event.description.slice(0, 200)}...`}</p>
                    {event.venue !== constants.VIRTUAL_CMTYEVENT_VENUE && event.location && (
                        <p className="text-sm font-medium leading-5">
                            <span className="text-xs font-light">Place: </span>
                            <span className="font-medium">
                                {(event.location as CmtyEventLocation).name}&nbsp;&nbsp;|&nbsp;&nbsp;
                                {(event.location as CmtyEventLocation).city},{' '}
                                {(event.location as CmtyEventLocation).state}
                            </span>
                        </p>
                    )}
                    {event.venue === constants.VIRTUAL_CMTYEVENT_VENUE && event.url && (
                        <p className="text-sm font-medium leading-5">
                            <span className="text-xs font-light">Reference Url: </span>
                            <span className="font-medium">{event.url}</span>
                        </p>
                    )}
                    {event.advocator && (
                        <p className="text-sm font-medium leading-5">
                            <span className="text-xs font-light">Advocator: </span>
                            <span className="font-medium ">{(event.advocator as User).fullName}</span>
                        </p>
                    )}
                    <div className="text-sm flex items-center">
                        {(event.club as Club).logoUrl ? (
                            <img
                                src={(event.club as Club).logoUrl}
                                className="h-8 w-8 rounded-full border-gray-300 border"
                                alt="club logo"
                            />
                        ) : (
                            <div className=" rounded-full w-8 h-8 bg-gray-300 flex justify-center items-center">
                                {(event.club as Club).displayName[0].toUpperCase()}
                            </div>
                        )}
                        <p className="ml-2 font-medium">{(event.club as Club).displayName}</p>
                    </div>
                </div>
                <div className="col-span-1 mt-2 lg:mt-0">
                    <div className="flex justify-center">
                        <CmtyEventRegCount event={event} className="max-w-72 w-full" />
                    </div>
                    {!isAdvocator && (
                        <div
                            className={`${
                                event.getUserRegStatus(user) === 'waitlist'
                                    ? 'text-amber-600'
                                    : event.getUserRegStatus(user) === 'registered'
                                    ? 'text-emerald-500'
                                    : 'text-emerald-600 font-medium'
                            } mt-2 flex justify-center items-center text-sm`}
                        >
                            {event.getUserRegStatus(user) && (
                                <>
                                    {event.getUserRegStatus(user) === 'confirmed' && !event.isFree ? (
                                        <CurrencyDollarIcon className="w-5 h-5" />
                                    ) : (
                                        <>
                                            {event.didDonate(user) ? (
                                                <GiftIcon className="w-5 h-5" />
                                            ) : (
                                                <CheckCircleIcon className="w-5 h-5" />
                                            )}
                                        </>
                                    )}
                                    <div className="font-medium ml-3">
                                        {event.getUserRegStatus(user) === 'registered'
                                            ? 'Registered for Event'
                                            : event.getUserRegStatus(user) === 'confirmed'
                                            ? `Confirmed ${!event.isFree ? '(Paid)' : ''}`
                                            : 'Registered to Waitlist'}
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="col-span-1 block md:flex justify-end items-center mt-2 md:mt-0">
                <div className="w-full md:w-44">
                    {!isAdvocator ? (
                        <>
                            <Button
                                textColor="text-gray-700"
                                className="border border-gray-300 w-full mb-2"
                                color="bg-white"
                                hoverColor="bg-gray-50"
                                onClick={onSelect}
                            >
                                Details
                            </Button>
                            {event.canReg(user) && (
                                <Button
                                    className={classNames(
                                        event.getRegUserCount() === event.maxRegCount
                                            ? 'bg-amber-600 hover:bg-amber-700'
                                            : 'bg-emerald-600 hover:bg-emerald-700',
                                        'mb-2 w-full px-0'
                                    )}
                                    onClick={onRegister}
                                >
                                    {event.getRegUserCount() === event.maxRegCount
                                        ? 'Register to Waitlist'
                                        : 'Register for event'}
                                </Button>
                            )}
                            {event.canUnReg(user) && (
                                <Button className="bg-amber-600 hover:bg-amber-700 mb-2 w-full" onClick={onUnRegister}>
                                    UnRegister
                                </Button>
                            )}
                            {event.canPay(user) && (
                                <Button className="bg-emerald-600 hover:bg-emerald-700 mb-2 w-full" onClick={onSelect}>
                                    Pay And Confirm
                                </Button>
                            )}
                        </>
                    ) : (
                        <Button className="bg-emerald-600 hover:bg-emerald-700 mb-2 w-full" onClick={onEdit}>
                            <>
                                <PencilAltIcon className="h-4 w-5 mr-3 inline-block" />
                                <span>Edit</span>
                            </>
                        </Button>
                    )}

                    <h6 className="mt-2 mb-0 font-bold text-sm text-center">
                        {event.isFree ? 'Free' : `$${(event.price || 0).toFixed(2)}`}
                    </h6>
                </div>
            </div>
        </div>
    );
};

export default CmtyEventListItem;
