import { useForm } from 'react-hook-form';
import { AuthApi } from 'apis';
import Button from 'components/form-controls/Button';
import Select from 'components/form-controls/Select';
import TextBox from 'components/form-controls/TextBox';
import { handleHTTPError } from 'store/error';
import { useAppDispatch } from 'store/hook';
import { showAlert } from 'store/ui';
import constants from 'utils/constants';
import { User } from 'utils/types';
import Modal from 'components/utils/Modal';
import { useEffect, useState } from 'react';

type FormValues = {
    firstName: string;
    lastName: string;
    gender: string;
    email: string;
    phoneNumber?: string;
    zipCode: string;
    birthYear: string;
};

interface EditSubAccountModalProps {
    account: User | null;
    isOpen: boolean;
    toggle: () => void;
    callback: () => void;
}

const EditSubAccountModal: React.FC<EditSubAccountModalProps> = ({ isOpen, toggle, account, callback }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const { register, formState, handleSubmit, control, reset } = useForm<FormValues>({
        defaultValues: {},
    });

    useEffect(() => {
        reset({
            firstName: account?.firstName || '',
            lastName: account?.lastName || '',
            gender: account?.gender !== undefined && account?.gender !== null ? String(account?.gender) : '',
            email: account?.email || '',
            phoneNumber: account?.phoneNumber || '',
            zipCode: account?.zipCode || '',
            birthYear: account?.birthYear,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const save = (data: FormValues) => {
        let params: any = {
            ...data,
            gender: Number(data.gender),
        };
        if (account) params = { ...params, _id: account?._id };
        if (account) updateAccount(params);
        else createAccount(params);
    };

    const createAccount = (params: {}) => {
        setLoading(true);
        AuthApi.createSubAccount(params)
            .then((data) => {
                setLoading(false);
                dispatch(showAlert({ type: 'success', message: 'Sub account has been added.' }));
                callback();
                toggle();
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    const updateAccount = (params: {}) => {
        setLoading(true);
        AuthApi.update(params)
            .then((data) => {
                setLoading(false);
                dispatch(showAlert({ type: 'success', message: 'Sub account has been updated.' }));
                callback();
                toggle();
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    const getGenderOptions = () => {
        return [
            {
                value: `${constants.GENDER.MALE}`,
                label: 'Male',
            },
            {
                value: `${constants.GENDER.FEMALE}`,
                label: 'Female',
            },
        ];
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="sm:max-w-xl"
            title={account ? 'Update Sub Account' : 'Add Sub Account'}
            headBorder
            loading={loading}
        >
            <form onSubmit={handleSubmit(save)} className="grid grid-cols-2 gap-4 p-4 sm:p-6" autoComplete="off">
                <TextBox
                    className="col-span-2 sm:col-span-1"
                    name="firstName"
                    required
                    label="First Name"
                    placeholder="John"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <TextBox
                    className="col-span-2 sm:col-span-1"
                    name="lastName"
                    required
                    label="Last Name"
                    placeholder="Michael"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <TextBox
                    className="col-span-2 sm:col-span-1"
                    name="email"
                    required
                    label="Email Address"
                    placeholder="john@mail.com"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                            pattern:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        },
                    }}
                />
                <Select
                    className="col-span-2 sm:col-span-1"
                    name="gender"
                    required
                    label="Gender"
                    placeholder="...select"
                    options={getGenderOptions()}
                    defaultValue=""
                    validation={{
                        control,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <TextBox
                    className="col-span-2 sm:col-span-1"
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="4578963258"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                    }}
                />
                <TextBox
                    className="col-span-2 sm:col-span-1"
                    name="zipCode"
                    label="Zip Code"
                    placeholder="94568"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: false,
                            minLength: 5,
                            maxLength: 5,
                        },
                    }}
                />
                <TextBox
                    className="col-span-2 sm:col-span-1"
                    name="birthYear"
                    label="Year of Birth"
                    placeholder="1986"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: false,
                            minLength: 4,
                            maxLength: 4,
                        },
                    }}
                />
                <div className="col-span-2 flex justify-end mt-5">
                    <Button className=" w-36" type="submit">
                        {account ? 'Update' : 'Add'}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default EditSubAccountModal;
