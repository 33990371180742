import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-scroll';
import {
    ChevronDownIcon,
    DeviceMobileIcon,
    IdentificationIcon,
    LibraryIcon,
    MenuIcon,
    NewspaperIcon,
    UserCircleIcon,
    XIcon,
} from '@heroicons/react/outline';
import { classNames } from 'utils';
import { useAppDispatch } from 'store/hook';
import { toggleLoginModal, toggleRegModal } from 'store/ui';
import './index.scss';

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const regOrganization = () => {
        window.open(`${process.env.REACT_APP_ADMIN_URL}/signup`, '__blank');
    };

    const regUser = () => {
        dispatch(toggleRegModal());
    };

    const loginOrganization = () => {
        window.open(`${process.env.REACT_APP_ADMIN_URL}/signin`, '__blank');
    };

    const loginUser = () => {
        dispatch(toggleLoginModal());
    };

    const registerMenus = [
        {
            name: 'Register Organization',
            description: 'To post community events',
            onClick: regOrganization,
            icon: LibraryIcon,
        },
        {
            name: 'Register Member',
            description: 'To attend community events',
            onClick: regUser,
            icon: IdentificationIcon,
        },
    ];

    const loginMenus = [
        {
            name: 'Login Organization',
            description: 'To post community events',
            onClick: loginOrganization,
            icon: LibraryIcon,
        },
        {
            name: 'Login Member',
            description: 'To check your community events',
            onClick: loginUser,
            icon: UserCircleIcon,
        },
    ];

    return (
        <div className="fixed top-0 left-0 w-full h-auto bg-white z-20 ">
            <div className="flex justify-between items-center md:space-x-10 px-4 xl:px-32 py-2 mx-auto">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                    <a href="/" className="flex items-center">
                        <span className="sr-only">MyCommunityLives</span>
                        <img className="w-auto" src="/logo.png" alt="logo" />
                        <div className="ml-3  text-emerald-600 hover:text-emerald-700">
                            <h1 className="text-2xl font-bold leading-5">MCTL</h1>
                            <p className="text-sm font-light">Opark, Inc</p>
                        </div>
                    </a>
                </div>
                <div className="hidden lg:flex flex-grow justify-end items-center">
                    <Link
                        className="text-lg px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer"
                        to="app-download"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >
                        Download App
                    </Link>
                    <Link
                        className="text-lg px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer"
                        to="participating-organizations"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                    >
                        Organizations
                    </Link>
                    <p
                        onClick={() => {
                            navigate('/cmtyevents');
                        }}
                        className="text-lg px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer"
                    >
                        Find Events
                    </p>
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button className="text-xl px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center">
                                <span>Login</span>
                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-0 sm:px-0">
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="relative bg-white p-7 grid grid-cols-1 gap-3">
                                        {loginMenus.map((menu) => (
                                            <Menu.Item key={menu.name}>
                                                {({ active }) => (
                                                    <div
                                                        className={classNames(
                                                            'col-span-1 flex items-center p-2 transition duration-150 ease-in-out rounded-lg cursor-pointer',
                                                            active ? 'hover:bg-gray-50' : ''
                                                        )}
                                                        onClick={menu.onClick}
                                                    >
                                                        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10  sm:h-12 sm:w-12 bg-emerald-100 rounded-md">
                                                            <menu.icon
                                                                aria-hidden="true"
                                                                className="text-emerald-500 p-2"
                                                            />
                                                        </div>
                                                        <div className="ml-4">
                                                            <p className="text-base font-medium text-gray-900">
                                                                {menu.name}
                                                            </p>
                                                            <p className="text-sm text-gray-500">{menu.description}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                    <div className="p-4 bg-gray-50">
                                        <div className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                            <span className="flex items-center">
                                                <span className="text-sm font-medium text-gray-900">
                                                    MyCommunityLives
                                                </span>
                                            </span>
                                            <span className="block text-sm text-gray-500">
                                                Solicits and enables your community members to propose ideas for
                                                activities that can then be converted automatically into events​
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button className="text-xl px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center">
                                <span>Register</span>
                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-0 sm:px-0">
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="relative bg-white p-7 grid grid-cols-1 gap-3">
                                        {registerMenus.map((menu) => (
                                            <Menu.Item key={menu.name}>
                                                {({ active }) => (
                                                    <div
                                                        className={classNames(
                                                            'col-span-1 flex items-center p-2 transition duration-150 ease-in-out rounded-lg cursor-pointer',
                                                            active ? 'hover:bg-gray-50' : ''
                                                        )}
                                                        onClick={menu.onClick}
                                                        key={menu.name}
                                                    >
                                                        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10  sm:h-12 sm:w-12 bg-emerald-100 rounded-md">
                                                            <menu.icon
                                                                aria-hidden="true"
                                                                className="text-emerald-500 p-2"
                                                            />
                                                        </div>
                                                        <div className="ml-4">
                                                            <p className="text-base font-medium text-gray-900">
                                                                {menu.name}
                                                            </p>
                                                            <p className="text-sm text-gray-500">{menu.description}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                    <div className="p-4 bg-gray-50">
                                        <div className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                            <span className="flex items-center">
                                                <span className="text-sm font-medium text-gray-900">
                                                    MyCommunityLives
                                                </span>
                                            </span>
                                            <span className="block text-sm text-gray-500">
                                                Enables community residents to view, register, and pay for activities in
                                                neighboring communities and far away places. ​
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
                <div className="lg:hidden flex items-center">
                    <Menu as="div">
                        <Menu.Button>
                            {({ open }) => (open ? <XIcon className="h-8 w-8" /> : <MenuIcon className="h-8 w-8" />)}
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute z-10 w-screen top-20 right-0 px-0">
                                <div className="overflow-hidden shadow-xl ring-1 ring-black ring-opacity-5">
                                    <div className="relative bg-white p-7 grid grid-cols-2 gap-x-3">
                                        <div className="col-span-2 md:col-span-1 border-r-0 md:border-r-2">
                                            <Link
                                                className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                                to="app-download"
                                                spy={true}
                                                smooth={true}
                                                offset={0}
                                                duration={500}
                                            >
                                                <DeviceMobileIcon className="w-8 h-8" />
                                                <div className="ml-3">Download App</div>
                                            </Link>
                                            <Link
                                                className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                                to="participating-organizations"
                                                spy={true}
                                                smooth={true}
                                                offset={-100}
                                                duration={500}
                                            >
                                                <LibraryIcon className="w-8 h-8" />
                                                <div className="ml-3">Organizations</div>
                                            </Link>
                                            <div
                                                onClick={() => {
                                                    navigate('/cmtyevents');
                                                }}
                                                className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                            >
                                                <NewspaperIcon className="w-8 h-8" />
                                                <div className="ml-3">Find Events</div>
                                            </div>
                                        </div>
                                        <div className="col-span-2 md:col-span-1">
                                            <div
                                                onClick={() => {
                                                    window.open(`${process.env.REACT_APP_ADMIN_URL}/signup`, '__blank');
                                                }}
                                                className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                            >
                                                <LibraryIcon className="w-8 h-8" />
                                                <div className="ml-3">Register Organization</div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    dispatch(toggleRegModal());
                                                }}
                                                className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                            >
                                                <IdentificationIcon className="w-8 h-8" />
                                                <div className="ml-3">Register Member</div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    window.open(`${process.env.REACT_APP_ADMIN_URL}/signin`, '__blank');
                                                }}
                                                className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                            >
                                                <LibraryIcon className="w-8 h-8" />
                                                <div className="ml-3">Login Organization</div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    dispatch(toggleLoginModal());
                                                }}
                                                className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                            >
                                                <IdentificationIcon className="w-8 h-8" />
                                                <div className="ml-3">Login Member</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 bg-gray-50">
                                        <div className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                            <span className="flex items-center">
                                                <span className="text-sm font-medium text-gray-900">
                                                    MyCommunityLives
                                                </span>
                                            </span>
                                            <span className="block text-sm text-gray-500">
                                                Solicits and enables your community members to propose ideas for
                                                activities that can then be converted automatically into events​
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    );
};

export default Header;
