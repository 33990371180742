import React from 'react';
import GoogleMapReact from 'google-map-react';
import { LocationMarkerIcon } from '@heroicons/react/solid';

type PropsType = {
    location: {
        lat: number;
        lng: number;
    };
    className?: string;
};

const defaultCenter = {
    lat: 34.036389,
    lng: -118.304242,
};

const Marker = ({ lat, lng }: { lat: number; lng: number }) => {
    return <LocationMarkerIcon className="w-10 h-10 text-red-500" />;
};

const CmtyEventPlaceMap = ({ location, className }: PropsType) => {
    return (
        <div className={className || ''}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyC5Fztlw_97tIB3qWaIuESjqT5dnBg0JjU' }}
                defaultCenter={defaultCenter}
                center={location}
                defaultZoom={15}
                style={{ minHeight: '10rem', position: 'relative', height: '100%' }}
            >
                <Marker lat={location.lat} lng={location.lng} />
            </GoogleMapReact>
        </div>
    );
};

export default CmtyEventPlaceMap;
