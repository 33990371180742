import HomeCarousel from 'components/home/carousel';
import HomeDownloadApp from 'components/home/download-app';
import HomeAboutUs from 'components/home/aboutus';
import HomeRegOrganization from 'components/home/reg-organization';
import HomeOrganizations from 'components/home/our-organizations';
import Header from 'layouts/homelayout/Header';
import Footer from 'layouts/homelayout/Footer';

const Home = () => {
    return (
        <div id="home">
            <Header />
            <HomeCarousel />
            <HomeAboutUs />
            <HomeRegOrganization />
            <HomeDownloadApp />
            <HomeOrganizations />
            <Footer />
        </div>
    );
};

export default Home;
