import React from 'react';

const HomeDownloadApp: React.FC = () => {
    return (
        <div
            className="w-full h-auto lg:h-screen white relative bg-white grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-0"
            id="app-download"
        >
            <div className="col-span-1 bg-white flex justify-center items-center">
                <img src="/images/app-download/app-download.png" alt="mobile download" className=" w-full" />
            </div>
            <div className="col-span-1 bg-white flex items-center pb-10 lg:pb-0">
                <div className="p-4 md:p-20 text-center">
                    <h2 className="w-full text-gray-800 text-4xl md:text-5xl text-center font-bold pl-4 md:pl-10 mb-10">
                        Download Mobile App
                    </h2>
                    <p className=" text-gray-500 text-xl md:text-2xl text-center mb-20">
                        A great benefit for travelers, virtual club members, and park districts to gain greater revenue
                        traction with their resources​​
                    </p>
                    <div className="flex items-center justify-center flex-wrap">
                        <img
                            src="/images/app-download/appstoredownload.png"
                            alt="appstoredownload"
                            className="h-16 md:mr-5 mb-5 md:mb-0"
                        />
                        <img
                            src="/images/app-download/googlestoredownload.png"
                            alt="googlestoredownload"
                            className="h-16"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeDownloadApp;
