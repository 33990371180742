import { useAppDispatch, useAppSelector } from 'store/hook';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { showAlert, toggleRegModal } from 'store/ui';
import { AuthApi } from 'apis';
import TextBox from 'components/form-controls/TextBox';
import Button from 'components/form-controls/Button';
import Select from 'components/form-controls/Select';
import { useState } from 'react';
import { login } from 'store/auth';
import { User } from 'utils/types';
import { handleHTTPError } from 'store/error';

type FormValues = {
    tempClubName: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    favoriteClubs: string[];
};

type PropsType = {
    className?: string;
};

const NewRegister = ({ className }: PropsType) => {
    const dispatch = useAppDispatch();
    const clubs = useAppSelector((state) => state.club.clubs);
    const navigate = useNavigate();
    const { register, formState, handleSubmit, control } = useForm<FormValues>({
        defaultValues: { tempClubName: '', firstName: '', lastName: '', email: '', phoneNumber: '', favoriteClubs: [] },
    });
    const [loading, setLoading] = useState(false);

    const submit = (data: FormValues) => {
        if (loading) return;
        const { tempClubName, firstName, lastName, email, phoneNumber, favoriteClubs } = data;
        const params = {
            email,
            isCmtyEventUser: true,
            tempClubName: tempClubName || undefined,
            firstName,
            lastName,
            phoneNumber,
            favoriteClubs,
        };
        setLoading(true);
        AuthApi.register(params)
            .then((data) => {
                dispatch(showAlert({ type: 'success', message: data.message }));
                setLoading(false);
                const { user, token } = data;
                if (user?.isActivated) {
                    dispatch(login({ user: new User(user), token }));
                    navigate('/profile?redirect=/cmtyevents');
                }
                dispatch(toggleRegModal());
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    const getClubOptions = () => {
        return clubs.map((club) => {
            return { value: club._id, label: club.displayName };
        });
    };

    return (
        <form className={className || ''} onSubmit={handleSubmit(submit)} autoComplete="off">
            <div className="block sm:grid sm:grid-cols-2 gap-3 mb-5">
                <TextBox
                    className=" col-span-2"
                    name="tempClubName"
                    label="Please input your club name"
                    placeholder="Mission Tennis Club"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                    }}
                />
                <Select
                    className="col-span-2"
                    name="favoriteClubs"
                    label="Favorite Organizations"
                    placeholder="Please select ..."
                    isMulti
                    options={getClubOptions()}
                    defaultValue={[]}
                    validation={{
                        control,
                        formState,
                    }}
                />
                <TextBox
                    className=" col-span-1"
                    name="firstName"
                    label="First Name"
                    placeholder="John"
                    defaultValue=""
                    required
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <TextBox
                    className=" col-span-1"
                    name="lastName"
                    label="Last Name"
                    placeholder="Michael"
                    defaultValue=""
                    required
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <TextBox
                    className="col-span-1"
                    name="email"
                    label="Email Address"
                    placeholder="john@mail.com"
                    defaultValue=""
                    required
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                            pattern:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        },
                    }}
                />
                <TextBox
                    className="col-span-1"
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="4578954568"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                    }}
                />
            </div>
            <Button className="w-full" type="submit" loading={loading}>
                Register
            </Button>
        </form>
    );
};

export default NewRegister;
