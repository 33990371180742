import PageContentLayout from 'layouts/PageContentLayout';
import { CmtyEventIdeaApi } from 'apis';
import { useEffect, useState } from 'react';
import { CmtyEventIdea, CmtyEventIdeaModel, EventIdeaFilterOptions } from 'utils/types';
import moment from 'moment-timezone';
import constants from 'utils/constants';
import { handleHTTPError } from 'store/error';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setCommonLoading, toggleLoginModal } from 'store/ui';
import Pagination from 'components/utils/Pagination';
import CmtyEventIdeaList from 'components/cmtyeventideas/eventidealist';
import CmtyEventIdeaSearchBox from 'components/cmtyeventideas/search-box';

const CmtyEventIdeas = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const systemSetting = useAppSelector((state) => state.club.systemSetting);
    const filterOptions = useAppSelector((state) => state.criteria.eventIdeaFilterOptions);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [ideas, setIdeas] = useState<CmtyEventIdea[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        search(0, filterOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOptions]);

    const search = (skip = 0, options?: EventIdeaFilterOptions) => {
        const {
            title,
            zipCode,
            city,
            club,
            stDate,
            etDate,
            eventType,
            venue,
            ageRanges,
            feeType,
            eligibleGender,
            eligibleLevel,
        } = options || {};
        let params: any = {
            'title.in': title || undefined,
            'apxStart.gte': stDate ? moment(stDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            'apxStart.lte': etDate
                ? moment(etDate).format('YYYY-MM-DD')
                : moment().add(1, 'months').format('YYYY-MM-DD'),
            eventType: eventType || undefined,
            venue: venue || undefined,
            ageRanges: ageRanges === 'all' ? undefined : ageRanges,
            eligibleGender: eligibleGender === 'all' ? undefined : eligibleGender,
            eligibleLevel: eligibleLevel ? Number(eligibleLevel) : undefined,
            zipCode: zipCode === 'all' || !zipCode ? undefined : zipCode,
            city: city?.city || undefined,
            club: club?._id || undefined,
            status: constants.CMTYEVENT_IDEA_STATUS.PUBLISHED,
            skip,
            limit: 10,
            sort_by: 'apxStart',
            order_by: 'asc',
        };
        if (feeType === constants.CMTYEVENT_FEETYPES.FREE) params = { ...params, isFree: true };
        if (feeType === constants.CMTYEVENT_FEETYPES.LESS10) params = { ...params, 'price.lte': 10 };
        if (feeType === constants.CMTYEVENT_FEETYPES.GREATER10) params = { ...params, 'price.gt': 10 };
        searchIdeas(params);
    };

    const searchIdeas = (params: any) => {
        setLoading(true);
        CmtyEventIdeaApi.retrieve(params)
            .then((data) => {
                setLoading(false);
                let ideas: CmtyEventIdea[] = (data.data as CmtyEventIdeaModel[]).map((idea) => new CmtyEventIdea(idea));
                setIdeas(ideas);
                setTotalCount(data.totalCount || 0);
                if ((data.totalCount || 0) <= currentPage * 10) setCurrentPage(Math.max(0, currentPage - 1));
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    const onLike = (idea: CmtyEventIdea) => {
        if (!user) return dispatch(toggleLoginModal());
        if (idea.isMyLikeIdea(user)) {
            dispatch(setCommonLoading(true));
            const params = { _id: idea._id, user: user?._id };
            CmtyEventIdeaApi.unlike(params)
                .then((data) => {
                    dispatch(setCommonLoading(false));
                    const newIdea = new CmtyEventIdea(data.data as CmtyEventIdeaModel);
                    const newIdeas = [...ideas];
                    const index = newIdeas.findIndex((ida) => ida._id === idea._id);
                    newIdeas.splice(index, 1, newIdea);
                    setIdeas(newIdeas);
                })
                .catch((error) => {
                    dispatch(setCommonLoading(false));
                    dispatch(handleHTTPError(error));
                });
        } else {
            dispatch(setCommonLoading(true));
            const params = { _id: idea._id, user: user?._id };
            CmtyEventIdeaApi.like(params)
                .then((data) => {
                    dispatch(setCommonLoading(false));
                    const newIdea = new CmtyEventIdea(data.data as CmtyEventIdeaModel);
                    const newIdeas = [...ideas];
                    const index = newIdeas.findIndex((ida) => ida._id === idea._id);
                    newIdeas.splice(index, 1, newIdea);
                    setIdeas(newIdeas);
                })
                .catch((error) => {
                    dispatch(setCommonLoading(false));
                    dispatch(handleHTTPError(error));
                });
        }
    };

    const pageChange = (page: number) => {
        setCurrentPage(page);
        search(page * 10, filterOptions);
    };

    const getPageTitle = () => {
        if (filterOptions.zipCode) {
            const area = systemSetting.zipCodes.find((code) => code.value === filterOptions.zipCode);
            if (filterOptions.club) {
                return (
                    <span>
                        Event Ideas of {filterOptions.club.displayName}
                        <br />
                        in {area?.name}
                        <span className="text-base ml-2">(Zip Code Starting with {filterOptions.zipCode})</span>
                    </span>
                );
            } else if (filterOptions.city) {
                return (
                    <span>
                        Event Ideas of {filterOptions.city.city}
                        <br />
                        in {area?.name}
                        <span className="text-base ml-2">(Zip Code Starting with {filterOptions.zipCode})</span>
                    </span>
                );
            } else
                return (
                    <>
                        Event Ideas in {area?.name} <br />
                        <span className="text-base ml-2">(Zip Code Starting with {filterOptions.zipCode})</span>
                    </>
                );
        } else {
            if (filterOptions.club) {
                return `Community Events of ${filterOptions.club.displayName}`;
            } else return 'Community Events in All Geographic Area';
        }
    };

    return (
        <PageContentLayout scrollTop={false}>
            <CmtyEventIdeaSearchBox />
            <h3 className="page-title">{getPageTitle()}</h3>
            <CmtyEventIdeaList ideas={ideas} onLike={onLike} loading={loading} />
            <Pagination
                totalCount={totalCount}
                page={currentPage}
                pageRangeDisplayed={5}
                countPerPage={10}
                onChange={pageChange}
            />
        </PageContentLayout>
    );
};

export default CmtyEventIdeas;
