import { useAppSelector } from 'store/hook';
import { useForm } from 'react-hook-form';
import Modal from 'components/utils/Modal';
import Button from 'components/form-controls/Button';
import { useEffect } from 'react';
import Select from 'components/form-controls/Select';
import constants from 'utils/constants';
import { capitalizeString } from 'utils';
import { EventIdeaFilterOptions } from 'utils/types';
// import TextBox from 'components/form-controls/TextBox';

type FormValues = {
    eventType: string;
    ageRanges: string;
    feeType: string;
    venue: string;
    eligibleGender: string;
    eligibleLevel: string;
};

type PropsType = {
    isOpen: boolean;
    toggle: () => void;
    filterOptions: EventIdeaFilterOptions;
    callback: (options: EventIdeaFilterOptions) => void;
};

const EventIdeaMoreFilterModal = ({ isOpen, toggle, callback, filterOptions }: PropsType) => {
    const systemSetting = useAppSelector((state) => state.club.systemSetting);
    const { formState, handleSubmit, control, watch, reset } = useForm<FormValues>({
        defaultValues: filterOptions,
    });
    // const [isMatchEvent, setIsMatchEvent] = useState(false);

    useEffect(() => {
        if (isOpen) {
            reset(filterOptions);
            // if (
            //     filterOptions.eventType &&
            //     systemSetting.cmtyEventTypes.find(
            //         (type) => type.toLowerCase() === filterOptions.eventType?.toLowerCase()
            //     )?.isMatchEvent
            // ) {
            //     setIsMatchEvent(true);
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // if (name === 'eventType') {
            //     const isMatchEvent = systemSetting.cmtyEventTypes.find(
            //         (type) => type.name.toLowerCase() === value.eventType?.toLowerCase()
            //     )?.isMatchEvent;
            //     setIsMatchEvent(isMatchEvent ?? false);
            //     if (!isMatchEvent) reset({ ...value, eligibleLevel: undefined });
            // }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const submit = (data: FormValues) => {
        callback(data);
        toggle();
    };

    const getEventVenueOptions = () => {
        return systemSetting.cmtyVenues.map((venue) => {
            return { value: venue, label: venue };
        });
    };

    const getEventTypeOptions = () => {
        return systemSetting.cmtyEventTypes.map((type) => {
            return { value: type, label: type };
        });
    };

    const getAgeRangeOptions = () => {
        return [
            { value: 'all', label: 'All' },
            ...systemSetting.cmtyEventAgeRanges.map((age) => {
                return { value: age.name, label: capitalizeString(age.name) };
            }),
        ];
    };

    const getEligibleGenderOptions = () => {
        return Object.values(constants.CMTYEVENT_GENDERTYPES).map((value) => {
            return { value, label: capitalizeString(value) };
        });
    };

    const getFeeTypeOptions = () => {
        return Object.values(constants.CMTYEVENT_FEETYPES).map((fee) => {
            return { value: fee, label: capitalizeString(fee) };
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="sm:max-w-lg" title="More Filters" headBorder>
            <form onSubmit={handleSubmit(submit)} autoComplete="off">
                <div className="block md:grid grid-cols-2 gap-3 p-6 border-b-2">
                    <Select
                        className=" col-span-1"
                        name="venue"
                        label="Is it indoor, outdoor, virtual?​"
                        placeholder="All Event Venues"
                        options={getEventVenueOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                        }}
                    />
                    <Select
                        className=" col-span-1"
                        name="eventType"
                        label="Event Type"
                        placeholder="All Event Types"
                        options={getEventTypeOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                        }}
                    />
                    <Select
                        className=" col-span-1"
                        name="ageRanges"
                        label="Age Range"
                        placeholder="All Age Range"
                        options={getAgeRangeOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                        }}
                    />
                    <Select
                        className=" col-span-1"
                        name="eligibleGender"
                        label="Eligible Genders"
                        placeholder="All Genders"
                        options={getEligibleGenderOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                        }}
                    />
                    {/* {isMatchEvent && (
                        <TextBox
                            className="w-100 mb-3"
                            name="eligibleLevel"
                            label="Eligible Player Level"
                            placeholder="4.5"
                            defaultValue=""
                            type="text"
                            validation={{
                                register,
                                formState,
                                rules: {
                                    required: false,
                                    min: 0,
                                },
                            }}
                        />
                    )} */}
                    <Select
                        className=" col-span-1"
                        name="feeType"
                        label="Event Fee"
                        placeholder="All"
                        options={getFeeTypeOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                        }}
                    />
                </div>
                <div className="flex justify-end px-6 py-4">
                    <Button
                        textColor="text-emerald-600"
                        className=" text-emerald-600 border-2 border-emerald-600 hover:bg-emerald-600 hover:text-white bg-transparent mr-2"
                        onClick={toggle}
                    >
                        Close
                    </Button>
                    <Button type="submit">Filter</Button>
                </div>
            </form>
        </Modal>
    );
};

export default EventIdeaMoreFilterModal;
