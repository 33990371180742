import { AdjustmentsIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import EventIdeaMoreFilterModal from './MoreFilterModal';
import moment from 'moment-timezone';
import DatePicker from 'components/form-controls/datepicker';
import Button from 'components/form-controls/Button';
import { ClubApi } from 'apis';
import { City, EventIdeaFilterOptions } from 'utils/types';
import { handleHTTPError } from 'store/error';
import SearchableSelect from 'components/form-controls/SearchableSelect';
import { putEventIdeaFilterOptions } from 'store/criteria';

const EventIdeaSearchBox = () => {
    const dispatch = useAppDispatch();
    const zipcodes = useAppSelector((state) => state.club.systemSetting.zipCodes);
    const user = useAppSelector((state) => state.auth.user);
    const filterOptions = useAppSelector((state) => state.criteria.eventIdeaFilterOptions);
    const clubs = useAppSelector((state) => state.club.clubs);
    const [cities, setCities] = useState<City[]>([]);
    const [isOpenMoreFilter, setOpenMoreFilter] = useState(false);

    useEffect(() => {
        if (user?.favoriteClubs && user?.favoriteClubs.length > 0 && !filterOptions.club)
            updateFilterOptions({ club: clubs.find((x) => x._id === user?.favoriteClubs?.[0]) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (filterOptions.zipCode) {
            ClubApi.retriveCities({ 'zipCode.in': filterOptions.zipCode })
                .then((data) => {
                    setCities(
                        (data.data as City[]).filter(
                            (city, index, self) => self.findIndex((c) => c.city === city.city) === index
                        )
                    );
                })
                .catch((error) => {
                    dispatch(handleHTTPError(error));
                });
        } else {
            setCities([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOptions.zipCode]);

    const toggleMoreFilterModal = () => {
        setOpenMoreFilter(!isOpenMoreFilter);
    };

    const updateFilterOptions = (options: EventIdeaFilterOptions) => {
        const newFilterOptions = { ...filterOptions, ...options };
        dispatch(putEventIdeaFilterOptions(newFilterOptions));
    };

    const getZipCodeOptions = () => {
        return zipcodes.map((code) => {
            return { value: code.value, label: `${code.name} (Zip Code: ${code.value})` };
        });
    };

    const getCityOptions = () => {
        return cities.map((city: City) => {
            return {
                value: city.city,
                label: `${city.city}, ${city.county}, ${city.state}`,
            };
        });
    };

    const getClubOptions = () => {
        return clubs.map((club) => {
            return {
                value: club._id,
                label: club.displayName,
            };
        });
    };

    return (
        <div className="flex justify-center w-full">
            <div className=" max-w-6xl w-full p-0 md:p-10 bg-white border-0 md:border border-gray-200 rounded-md block md:grid grid-cols-6 gap-3">
                <div className=" col-span-2 mb-2 md:mb-0">
                    <SearchableSelect
                        name="organization"
                        label="Specific Location"
                        labelClassName="text-xs mt-4"
                        placeholder="Park District or Club Name​"
                        clearable
                        value={filterOptions.club?._id}
                        options={getClubOptions()}
                        onChange={(value) => {
                            const club = clubs.find((cb) => cb._id === value);
                            updateFilterOptions({ club });
                        }}
                    />
                </div>
                <div className=" col-span-2 mb-2 md:mb-0">
                    <SearchableSelect
                        name="zipCode"
                        label="Enter first couple of letters of the state code, and then pull down and select the area"
                        labelClassName="text-xs"
                        clearable
                        value={filterOptions.zipCode}
                        placeholder="Geographic Area"
                        options={getZipCodeOptions()}
                        onChange={(value) => {
                            updateFilterOptions({ zipCode: value as string, city: undefined });
                        }}
                    />
                </div>
                <div className=" col-span-2 mb-2 md:mb-0">
                    <SearchableSelect
                        name="city"
                        label="If you entered a state, you can enter first couple letters of city and then select from the pull down​"
                        labelClassName="text-xs"
                        placeholder="City"
                        clearable
                        value={filterOptions.city?.city}
                        options={getCityOptions()}
                        onChange={(value) => {
                            const city = cities.find((ct) => ct.city === value);
                            updateFilterOptions({ city });
                        }}
                    />
                </div>
                <div className=" col-span-4 block sm:flex items-center">
                    <DatePicker
                        className=" flex-grow mb-2 mr-0 sm:mr-2 md:mr-0 md:mb-0"
                        name="filterStDate"
                        selected={moment(filterOptions.stDate).toDate()}
                        dateFormat="MMMM dd, yyyy"
                        onChange={(date) => {
                            updateFilterOptions({ stDate: moment(date).format('YYYY-MM-DD HH:mm') });
                        }}
                    />
                    <p className="px-3 text-sm text-gray-700 hidden md:block">To</p>
                    <DatePicker
                        className=" flex-grow mb-2 md:mb-0"
                        name="filterEtDate"
                        selected={moment(filterOptions.etDate).toDate()}
                        dateFormat="MMMM dd, yyyy"
                        onChange={(date) => {
                            updateFilterOptions({ etDate: moment(date).format('YYYY-MM-DD HH:mm') });
                        }}
                    />
                </div>
                <div className="col-span-2">
                    <Button className="w-full" onClick={toggleMoreFilterModal}>
                        <div className="flex justify-center">
                            <AdjustmentsIcon className="w-5 h-5 mr-5" />
                            More Filters
                        </div>
                    </Button>
                </div>
                <div className="col-span-6 text-center">
                    <FilterOptionBadges {...filterOptions} update={updateFilterOptions} />
                </div>
                <EventIdeaMoreFilterModal
                    isOpen={isOpenMoreFilter}
                    toggle={toggleMoreFilterModal}
                    filterOptions={filterOptions}
                    callback={updateFilterOptions}
                />
            </div>
        </div>
    );
};

export default EventIdeaSearchBox;

const FilterOptionBadges = (props: EventIdeaFilterOptions & { update: (option: EventIdeaFilterOptions) => void }) => {
    const removeOption = (key: string) => {
        const { update, ...options } = props;
        update({ ...options, [key]: undefined });
    };

    const mappingOptions = () => {
        const { eventType, feeType, venue, eligibleGender, eligibleLevel, ageRanges } = props;
        const mappedOptions = [];
        if (eventType) mappedOptions.push({ key: 'eventType', label: 'Event Type', value: eventType });
        if (feeType) mappedOptions.push({ key: 'feeType', label: 'Event Fee', value: feeType });
        if (venue) mappedOptions.push({ key: 'venue', label: 'Event Venue', value: venue });
        if (eligibleGender) mappedOptions.push({ key: 'eligibleGender', label: 'Gender', value: eligibleGender });
        if (eligibleLevel) mappedOptions.push({ key: 'eligibleLevel', label: 'Level', value: eligibleLevel });
        if (ageRanges) mappedOptions.push({ key: 'ageRanges', label: 'Age', value: ageRanges });
        return mappedOptions;
    };

    return (
        <div>
            {mappingOptions().map((option, index) => (
                <Badges
                    key={index}
                    option={option}
                    onClick={() => {
                        removeOption(option.key);
                    }}
                />
            ))}
        </div>
    );
};

const Badges = ({
    option,
    onClick,
}: {
    option: { key?: string; value?: string; label?: string };
    onClick: () => void;
}) => {
    return (
        <div className="px-2 py-1.5 text-sm bg-white border-2 border-emerald-600 rounded-full hover:bg-emerald-600 text-gray-600 hover:text-white inline-block mt-2 mx-1">
            <span className=" font-light text-xs capitalize">{option.label}: </span>
            <span className=" font-semibold capitalize">{option.value}</span>
            <XIcon className="w-4 h-4 ml-2 inline-block cursor-pointer " onClick={onClick} />
        </div>
    );
};
