import Loader from 'components/utils/Loader';
import { CmtyEvent } from 'utils/types';
import CmtyEventListItem from './ListItem';

type PropsType = {
    isAdvocate?: boolean;
    events: CmtyEvent[];
    loading?: boolean;
    onEdit?: (event: CmtyEvent) => void;
    onSelect?: (event: CmtyEvent) => void;
};

const CmtyEventList = ({ events, isAdvocate, onSelect, onEdit, loading = false }: PropsType) => {
    return (
        <div className=" relative min-h-96">
            {loading && (
                <div className=" absolute inset-0 bg-white opacity-70 z-10 flex justify-center pt-10">
                    <Loader size={8} />
                </div>
            )}
            {events.map((event) => (
                <CmtyEventListItem
                    key={event._id}
                    isAdvocator={isAdvocate}
                    event={event}
                    onSelect={() => {
                        if (onSelect) onSelect(event);
                    }}
                    onEdit={() => {
                        if (onEdit) onEdit(event);
                    }}
                />
            ))}
        </div>
    );
};

export default CmtyEventList;
