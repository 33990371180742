import { CmtyEventIdeaApi, CmtyEventLocationApi, IdeaQuoteApi } from 'apis';
import TextBox from 'components/form-controls/TextBox';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hook';
import constants from 'utils/constants';
import {
    Club,
    ClubSetting,
    CmtyEventIdea,
    CmtyEventIdeaModel,
    CmtyEventLocation,
    CmtyEventLocationModel,
    IdeaQuote,
    IdeaQuoteModel,
} from 'utils/types';
import ScrollTop from 'layouts/ScrollTop';
import RichEdit from 'components/form-controls/richedit';
import Button from 'components/form-controls/Button';
import Select from 'components/form-controls/Select';
import { capitalizeString, findTextFromHtml } from 'utils';
import DatePicker from 'components/form-controls/datepicker';
import moment from 'moment-timezone';
import { setCommonLoading, showAlert, showAlertModal } from 'store/ui';
import { handleHTTPError } from 'store/error';
import SearchableSelect from 'components/form-controls/SearchableSelect';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import CheckBox from 'components/form-controls/CheckBox';
import GoogleAutoComplete from 'components/form-controls/GoogleAutoComplete';

type FormValues = {
    eventType: string;
    isMatchEvent: boolean;
    venue: string;
    description: string;
    title: string;
    apxStart: string;
    apxEnd: string;
    location?: string;
    club?: string;
    url?: string;
    ageRanges: string[];
    eligibleGender: string;
    eligibleLevelFrom?: string | number;
    eligibleLevelTo?: string | number;
    isFree: boolean;
    price: number;
    maxRegCount: number;
    contactEmail?: string;
    contactNumber?: string;
    newLocationName?: string;
    newLocationAddress?: any;
};

const MyEventIdeaDetails = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const systemSetting = useAppSelector((state) => state.club.systemSetting);
    const clubs = useAppSelector((state) => state.club.clubs);
    const favoriteClubs = (clubs || []).filter((x) => (user?.favoriteClubs || []).includes(x._id));
    const navigate = useNavigate();
    const urlParams = useParams();
    const { register, formState, handleSubmit, reset, control, setValue, watch } = useForm<FormValues>({
        defaultValues: {},
    });
    const [idea, setIdea] = useState<CmtyEventIdea | null>(null);
    const [locations, setLocations] = useState<CmtyEventLocation[]>([]);
    const [quotes, setQuotes] = useState<IdeaQuote[]>([]);
    const [ideaClubs, setIdeaClubs] = useState<Club[]>(favoriteClubs);
    const [isMatchEvent, setIsMatchEvent] = useState<boolean>(false);
    const [isVirtual, setIsVirtual] = useState<boolean>(false);
    const [isSetClub, setIsSetClub] = useState<boolean>(false);
    const [isAllEligibleLevel, setIsAllEligibleLevel] = useState<boolean>(false);
    const [isSetNewLocation, setIsSetNewLocation] = useState<boolean>(false);
    const [isFree, setIsFree] = useState<boolean>(false);

    useEffect(() => {
        if (idea) {
            reset({
                eventType: idea.eventType,
                isMatchEvent: idea.isMatchEvent,
                venue: idea.venue,
                description: idea.description,
                title: idea.title,
                apxStart: idea.apxStart,
                apxEnd: idea.apxEnd,
                ageRanges: idea.ageRanges,
                eligibleGender: idea.eligibleGender,
                eligibleLevelFrom: idea.eligibleLevel?.from ?? '',
                eligibleLevelTo: idea.eligibleLevel?.to ?? '',
                isFree: idea.isFree ?? true,
                price: idea.price,
                maxRegCount: idea.maxRegCount,
                club: idea.getClubId() || 'other',
                location: idea.getLocationId(),
                url: idea.url || '',
                contactEmail: idea.contactEmail,
                contactNumber: idea.contactNumber,
            });
            if (idea.getClubId()) {
                fetchClubEventLocations(idea.getClubId());
                setIsSetClub(true);
            } else {
                fetchUserEventLocations(user?._id);
                setIsSetClub(false);
            }
            if (idea.isMatchEvent) {
                setIsMatchEvent(true);
                setIsAllEligibleLevel(idea.eligibleLevel?.from === 0 && idea.eligibleLevel?.to === 1000);
            } else {
                setIsMatchEvent(false);
                setIsAllEligibleLevel(false);
            }
            setIsFree(idea.isFree);
            setIsVirtual(idea.venue === constants.VIRTUAL_CMTYEVENT_VENUE);
            setIdeaClubs(favoriteClubs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idea, clubs.length]);

    useEffect(() => {
        const { id } = urlParams as { id: string };
        if (!id) return navigate('/myeventideas');
        if (id === 'new') {
            const ideaData: CmtyEventIdeaModel = {
                _id: 'new',
                user: '',
                eventType: '',
                venue: '',
                description: '',
                title: '',
                apxStart: moment().add(7, 'days').startOf('day').add(9, 'hours').format('YYYY-MM-DD HH:mm'),
                apxEnd: moment().add(7, 'days').startOf('day').add(10, 'hours').format('YYYY-MM-DD HH:mm'),
                ageRanges: ['Adult'],
                isFree: false,
                price: 10,
                maxRegCount: 100,
                eligibleGender: 'all',
                eligibleLevel: {
                    from: 5,
                    to: 10,
                },
                club: user?.favoriteClubs?.[0],
                location: '',
                url: '',
                likeMembers: [],
                contactEmail: user?.email,
                contactNumber: user?.phoneNumber,
                status: constants.CMTYEVENT_IDEA_STATUS.PUBLISHED,
            };
            const idea = new CmtyEventIdea(ideaData);
            setIdea(idea);
        } else {
            CmtyEventIdeaApi.read({ _id: id }).then((data) => {
                const idea = new CmtyEventIdea(data.data as CmtyEventIdeaModel);
                setIdea(idea);
            });
            IdeaQuoteApi.retrieve({ idea: id }).then((data) => {
                const quotes = (data.data as IdeaQuoteModel[]).map((quote) => new IdeaQuote(quote));
                setQuotes(quotes);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams?.id]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'isMatchEvent') {
                setIsMatchEvent(value.isMatchEvent ?? false);
            } else if (name === 'venue') {
                if (value.venue && value.venue === constants.VIRTUAL_CMTYEVENT_VENUE) {
                    setIsVirtual(true);
                    setValue('club', undefined);
                    setIdeaClubs(favoriteClubs.filter((club) => (club.setting as ClubSetting).allowVirtualCmtyEvent));
                } else {
                    setIsVirtual(false);
                    setIdeaClubs(favoriteClubs);
                }
            } else if (name === 'apxStart') {
                if (value.apxStart) setValue('apxEnd', value.apxStart);
            } else if (name === 'club') {
                setIsSetClub(!!value.club && value.club !== 'other');
                setValue('location', '');
                if (value.club) {
                    if (value.club === 'other') fetchUserEventLocations(user?._id);
                    else fetchClubEventLocations(value.club);
                }
                setLocations([]);
            } else if (name === 'location') {
                if (!value.location || value.location === 'new') setIsSetNewLocation(true);
                else setIsSetNewLocation(false);
            } else if (name === 'isFree') {
                setIsFree(!!value.isFree);
                if (value.isFree) setValue('price', 0);
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const fetchClubEventLocations = (club?: string) => {
        if (!club) setLocations([]);
        CmtyEventLocationApi.retrieve({ club }).then((data) => {
            setLocations((data.data as CmtyEventLocationModel[]).map((location) => new CmtyEventLocation(location)));
        });
    };

    const fetchUserEventLocations = (user?: string) => {
        if (!user) setLocations([]);
        CmtyEventLocationApi.retrieve({ user }).then((data) => {
            setLocations((data.data as CmtyEventLocationModel[]).map((location) => new CmtyEventLocation(location)));
        });
    };

    if (!idea) return null;

    const save = async (data: FormValues) => {
        let { eligibleLevelFrom, eligibleLevelTo, newLocationName, newLocationAddress, ...ideaData } = data;
        try {
            dispatch(setCommonLoading(true));
            if (
                ideaData.venue !== constants.VIRTUAL_CMTYEVENT_VENUE &&
                ideaData.club === 'other' &&
                (!ideaData.location || ideaData.location === 'new')
            ) {
                if (!newLocationName || !newLocationAddress?.location?.lat || !newLocationAddress.zipCode) {
                    dispatch(setCommonLoading(false));
                    return dispatch(showAlert({ type: 'warning', message: 'Please choose correct location.' }));
                }
                const { zipCode, address, state, city, location } = newLocationAddress;
                const { data: newLocation } = await CmtyEventLocationApi.create({
                    user: user?._id,
                    name: newLocationName,
                    zipCode: zipCode.slice(0, 2),
                    address,
                    city,
                    state,
                    location,
                });
                ideaData.location = newLocation._id;
            }
            if (idea._id !== 'new') {
                const params: any = {
                    _id: idea._id,
                    ...ideaData,
                    club: ideaData.club === 'other' ? null : ideaData.club || null,
                    location: ideaData.location || null,
                    url: ideaData.url || null,
                    apxStart: moment(ideaData.apxStart).format('YYYY-MM-DD HH:mm'),
                    apxEnd: moment(ideaData.apxEnd).format('YYYY-MM-DD HH:mm'),
                    eligibleLevel: isMatchEvent
                        ? isAllEligibleLevel
                            ? {
                                  from: 0,
                                  to: 1000,
                              }
                            : { from: Number(eligibleLevelFrom), to: Number(eligibleLevelTo) }
                        : null,
                };
                await CmtyEventIdeaApi.update(params);
            } else {
                const params: any = {
                    user: user?._id,
                    ...ideaData,
                    club: ideaData.club === 'other' ? undefined : ideaData.club || undefined,
                    location: ideaData.location || undefined,
                    url: ideaData.url || undefined,
                    apxStart: moment(ideaData.apxStart).format('YYYY-MM-DD HH:mm'),
                    apxEnd: moment(ideaData.apxEnd).format('YYYY-MM-DD HH:mm'),
                    eligibleLevel: isMatchEvent
                        ? isAllEligibleLevel
                            ? {
                                  from: 0,
                                  to: 1000,
                              }
                            : { from: Number(eligibleLevelFrom), to: Number(eligibleLevelTo) }
                        : undefined,
                };
                await CmtyEventIdeaApi.create(params);
            }
            dispatch(setCommonLoading(false));
            navigate('/myeventideas');
        } catch (error) {
            dispatch(setCommonLoading(false));
            dispatch(handleHTTPError(error));
        }
    };

    const cancel = () => {
        dispatch(
            showAlertModal({
                type: 'warning',
                title: 'Cancel Community Event Idea',
                message: 'Do you want to cancel this idea? \n Are you sure?',
                buttons: [
                    {
                        type: 'ok',
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        type: 'normal',
                        label: 'No',
                        value: 'no',
                    },
                ],
                handler: (value: string) => {
                    if (value === 'no') return;
                    const params = {
                        _id: idea._id,
                    };
                    dispatch(setCommonLoading(true));
                    CmtyEventIdeaApi.delete(params)
                        .then((data) => {
                            dispatch(setCommonLoading(false));
                            dispatch(showAlert({ type: 'success', message: data.message }));
                            navigate('/myeventideas');
                        })
                        .catch((error) => {
                            dispatch(setCommonLoading(false));
                            dispatch(handleHTTPError(error));
                        });
                },
            })
        );
    };

    const getEventTypeOptions = () => {
        return systemSetting.cmtyEventTypes.map((type) => {
            return { value: type, label: type };
        });
    };

    const getEventVenueOptions = () => {
        return systemSetting.cmtyVenues.map((venue) => {
            return { value: venue, label: venue };
        });
    };

    const getGenderOptions = () => {
        return Object.values(constants.CMTYEVENT_GENDERTYPES).map((value) => {
            return { value, label: capitalizeString(value) };
        });
    };

    const getAgeOptions = () => {
        return systemSetting.cmtyEventAgeRanges.map((age) => {
            return { value: age.name, label: capitalizeString(age.name) };
        });
    };

    const getClubOptions = () => {
        const clubOptions = ideaClubs.map((club) => {
            return { value: club._id, label: club.displayName };
        });
        if (!isVirtual) clubOptions.push({ value: 'other', label: 'Other' });
        return clubOptions;
    };

    const getLocationOptions = () => {
        const locationOptions = locations.map((location: CmtyEventLocation) => {
            return {
                value: location._id,
                label: location.name,
            };
        });
        if (!isVirtual && !isSetClub) locationOptions.push({ value: 'new', label: 'New' });
        return locationOptions;
    };

    return (
        <ScrollTop>
            <form onSubmit={handleSubmit(save)} className="grid grid-cols-3 gap-6 pb-6" autoComplete="off">
                <div className="col-span-3 md:col-span-1">
                    <h5 className="text-xl text-gray-800">Contact Information</h5>
                    <p className="text-gray-500 text-sm mb-4">
                        Input email and phone number that members and clubs who like this event can contact to you.
                    </p>
                    <TextBox
                        className="mb-3 w-full xl:w-2/3"
                        name="contactEmail"
                        required
                        label="Email"
                        placeholder={user?.email}
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                                pattern:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            },
                        }}
                    />
                    <TextBox
                        className="mb-3 w-full xl:w-2/3"
                        name="contactNumber"
                        label="Phone Number"
                        placeholder={user?.phoneNumber}
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    <SearchableSelect
                        name="club"
                        className="mb-3 w-full xl:w-2/3"
                        label='Which organization would sponsor the event'
                        required
                        placeholder="...select"
                        options={getClubOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    {isVirtual && (
                        <TextBox
                            className="mb-3 w-full xl:w-2/3"
                            name="url"
                            required
                            label="What is the reference url?"
                            placeholder="https://e-sports.com/social-event/signup"
                            defaultValue=""
                            type="text"
                            validation={{
                                register,
                                formState,
                                rules: {
                                    required: true,
                                },
                            }}
                        />
                    )}
                    {!isVirtual && (isSetClub || locations.length > 0) && (
                        <SearchableSelect
                            name="location"
                            className="mb-3 w-full xl:w-2/3"
                            label="Where would you suggest it to be?"
                            required={isSetClub}
                            placeholder="...select"
                            disabled={locations.length === 0}
                            options={getLocationOptions()}
                            defaultValue=""
                            validation={{
                                control,
                                formState,
                                rules: {
                                    required: isSetClub,
                                },
                            }}
                        />
                    )}
                    {!isVirtual && !isSetClub && isSetNewLocation && (
                        <div className="border md:border-0 bg-transparent md:bg-white w-full xl:w-2/3 p-4">
                            <h5 className="text-lg text-gray-800 mb-3">New Location</h5>
                            <TextBox
                                name="newLocationName"
                                required
                                className="mb-3"
                                label="Location Name"
                                placeholder="Mt. Tam Racquet Club"
                                defaultValue=""
                                type="text"
                                validation={{
                                    register,
                                    formState,
                                    rules: {
                                        required: true,
                                    },
                                }}
                            />
                            <GoogleAutoComplete
                                name="newLocationAddress"
                                defaultValue=""
                                required
                                label="Location Address"
                                validation={{
                                    control,
                                    formState,
                                    rules: {
                                        required: true,
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="col-span-3 md:col-span-2 bg-white rounded-md p-4 md:p-10 grid grid-cols-6 gap-6">
                    <TextBox
                        className="col-span-6 md:col-span-4"
                        name="title"
                        required
                        label="What would you call the event?"
                        placeholder="Social Tennis Together"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <CheckBox
                        name="isMatchEvent"
                        className="col-span-6 md:col-span-2 mt-0 md:mt-8"
                        label="Is Match Event?"
                        defaultChecked={false}
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    <RichEdit
                        className="col-span-6"
                        name="description"
                        required
                        label="How would you describe the event to others?"
                        placeholder="Description here..."
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                                validate: (value: string) => {
                                    if (findTextFromHtml(value)) return true;
                                    return false;
                                },
                            },
                        }}
                    />
                    <Select
                        name="eventType"
                        className="col-span-6 lg:col-span-3"
                        required
                        label="What kind of activity is this?"
                        placeholder="...select"
                        options={getEventTypeOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <Select
                        name="venue"
                        className="col-span-6 lg:col-span-3"
                        required
                        label="Is it indoor, outdoor, or virtual, etc?​"
                        placeholder="...select"
                        options={getEventVenueOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <Select
                        name="eligibleGender"
                        className="col-span-6 lg:col-span-3"
                        required
                        label="Is it for male, female, or both?"
                        placeholder="...select"
                        options={getGenderOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <Select
                        name="ageRanges"
                        required
                        label="What is the age grouping?"
                        placeholder="...select"
                        className="col-span-6 lg:col-span-3"
                        options={getAgeOptions()}
                        defaultValue={[]}
                        isMulti
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <div className="col-span-6 lg:col-span-3 grid grid-cols-2">
                        <CheckBox
                            name="isFree"
                            className="col-span-1 mt-6"
                            label="Is Free?"
                            defaultChecked={false}
                            validation={{
                                register,
                                formState,
                            }}
                        />
                        <TextBox
                            className="col-span-1"
                            name="price"
                            required
                            label="Price ($)"
                            placeholder="10"
                            defaultValue=""
                            disabled={isFree}
                            type="number"
                            validation={{
                                register,
                                formState,
                                rules: {
                                    required: true,
                                },
                            }}
                        />
                    </div>
                    {/* <Select
                        name="feeType"
                        className="col-span-6 lg:col-span-3"
                        required
                        label="What do you recommend as a participation fee?"
                        options={getEventFeeOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    /> */}
                    <TextBox
                        className="col-span-6 lg:col-span-3"
                        name="maxRegCount"
                        required
                        label="Maxium number of participants"
                        placeholder="100"
                        defaultValue=""
                        type="number"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    {isMatchEvent && (
                        <div className="col-span-6 lg:col-span-3">
                            <label
                                htmlFor="eligibleLevelFrom"
                                className="block text-sm font-medium text-gray-700 mb-1 text-left"
                            >
                                Eligible Player Level
                                {!isAllEligibleLevel && <span className=" text-red-500 ml-1">*</span>}
                            </label>
                            <div className=" flex items-center">
                                <TextBox
                                    name="eligibleLevelFrom"
                                    type="number"
                                    className=" w-full"
                                    disabled={isAllEligibleLevel}
                                    defaultValue=""
                                    placeholder="5"
                                    validation={{
                                        register,
                                        formState,
                                        rules: {
                                            required: !isAllEligibleLevel,
                                        },
                                    }}
                                />
                                <div className=" px-2">~</div>
                                <TextBox
                                    name="eligibleLevelTo"
                                    type="number"
                                    className=" w-full"
                                    disabled={isAllEligibleLevel}
                                    defaultValue=""
                                    placeholder="10"
                                    validation={{
                                        register,
                                        formState,
                                        rules: {
                                            required: !isAllEligibleLevel,
                                        },
                                    }}
                                />
                                <CheckBox
                                    name="isAllEligibleLevel"
                                    label="All Levels"
                                    className=" min-w-24 ml-4"
                                    checked={isAllEligibleLevel}
                                    onChange={(value) => setIsAllEligibleLevel(value)}
                                />
                            </div>
                        </div>
                    )}
                    <div className="col-span-6">
                        <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                            What time would you suggest? 
                            <span className=" text-red-500 ml-1">*</span>
                        </label>
                        <div className="grid grid-cols-2 gap-y-2 gap-x-6">
                            <DatePicker
                                className="col-span-2 lg:col-span-1"
                                name="apxStart"
                                required
                                showTimeSelect
                                dateFormat="MMMM dd, yyyy h:mm a"
                                validation={{
                                    control,
                                    formState,
                                    rules: {
                                        required: true,
                                        validate: (value: string) => {
                                            if (idea._id !== 'new') return true;
                                            return (
                                                moment(value).format('YYYY-MM-DD HH:mm') >
                                                moment().format('YYYY-MM-DD HH:mm')
                                            );
                                        },
                                    },
                                }}
                            />
                            <DatePicker
                                className="col-span-2 lg:col-span-1"
                                name="apxEnd"
                                required
                                showTimeSelect
                                dateFormat="MMMM dd, yyyy h:mm a"
                                validation={{
                                    control,
                                    formState,
                                    rules: {
                                        required: true,
                                        validate: (value: string) => {
                                            if (idea._id !== 'new') return true;
                                            return (
                                                moment(value).format('YYYY-MM-DD HH:mm') >
                                                moment().format('YYYY-MM-DD HH:mm')
                                            );
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-span-6 flex justify-end mt-3">
                        <Button
                            textColor="text-gray-700"
                            className="border border-gray-300 min-w-24 mr-3"
                            color="bg-white"
                            hoverColor="bg-gray-50"
                            onClick={() => {
                                navigate('/myeventideas');
                            }}
                        >
                            Back
                        </Button>
                        {idea._id !== 'new' && (
                            <Button className=" w-36 mr-3" color=" bg-red-500" hoverColor="bg-red-600" onClick={cancel}>
                                Cancel Idea
                            </Button>
                        )}
                        <Button className=" w-36" type="submit">
                            {idea._id !== 'new' ? 'Update' : 'Create'}
                        </Button>
                    </div>
                </div>
            </form>
            {quotes.length > 0 && (
                <div className="grid grid-cols-3 gap-6 pt-6 border-t">
                    <div className="col-span-3 md:col-span-1">
                        <h5 className="text-xl text-gray-800">Contacted Clubs</h5>
                        <p className="text-gray-500 text-sm mb-4">
                            Please contact to clubs and schedule community event for idea
                        </p>
                    </div>
                    <div className="col-span-3 md:col-span-2 bg-white rounded-md p-4 md:p-10 grid grid-cols-1 gap-6">
                        {quotes.map((quote, index) => (
                            <div className="col-span-1 border border-gray-300 rounded-md p-4 md:p-6" key={index}>
                                <p className="text-base text-gray-700 mb-3">{quote.club.displayName}</p>
                                <p className="text-sm text-gray-600 mb-1 font-medium">Message:</p>
                                <p className="text-sm text-gray-500 mb-3">{quote.message}</p>
                                <p className="text-sm text-gray-600 mb-1 font-medium">Contact Information:</p>
                                <div className="text-sm text-gray-500 flex items-center">
                                    <MailIcon className="w-4 h-4 mr-2" />
                                    <span>{quote.contactEmail}</span>
                                </div>
                                {quote.contactNumber && (
                                    <div className="text-sm text-gray-500 flex items-center">
                                        <PhoneIcon className="w-4 h-4 mr-2" />
                                        <span>{quote.contactNumber}</span>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </ScrollTop>
    );
};

export default MyEventIdeaDetails;
