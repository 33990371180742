import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
interface LayoutProps {
    children?: ReactNode;
}
const ContentWrapper = ({ children }: LayoutProps) => {
    return (
        <div className="ml-0 lg:ml-72 mt-16 px-4 md:px-6 py-6 transition-all ease-in-out duration-500">{children}</div>
    );
};

const SidebarLayout: FC<LayoutProps> = () => {
    return (
        <>
            <div className="bg-gray-50 min-h-screen">
                <Header />
                <ContentWrapper>{<Outlet />}</ContentWrapper>
            </div>
            <Sidebar />
        </>
    );
};

export default SidebarLayout;
