import { ChatIcon, EyeIcon, PencilAltIcon, ThumbUpIcon, TrashIcon } from '@heroicons/react/outline';
import * as SolidIcons from '@heroicons/react/solid';
import Button from 'components/form-controls/Button';
import LikeUserPhoto from 'components/utils/LikeUserPhoto';
import htmlParse from 'html-react-parser';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hook';
import { classNames } from 'utils';
import constants from 'utils/constants';
import { CmtyEventIdea, User } from 'utils/types';

type PropsType = {
    idea: CmtyEventIdea;
    onLike?: (event: CmtyEventIdea) => void;
    onEdit?: (event: CmtyEventIdea) => void;
    onCancel?: (event: CmtyEventIdea) => void;
};

const CmtyEventIdeaListItem = ({ idea, onLike, onEdit, onCancel }: PropsType) => {
    const systemSetting = useAppSelector((state) => state.club.systemSetting);
    const ideaDefaultClub = useAppSelector((state) => state.club.ideaDefaultClub);
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.user);

    const displayBadge = () => {
        if (idea.status === constants.CMTYEVENT_IDEA_STATUS.PUBLISHED) return '';
        return (
            <span
                className={classNames(
                    'p-2 py-1 ml-3 rounded-full border text-xs capitalize',
                    idea.status === constants.CMTYEVENT_IDEA_STATUS.EXPIRED
                        ? 'border-red-500 text-xs text-red-500'
                        : '',
                    idea.status === constants.CMTYEVENT_IDEA_STATUS.PENDING
                        ? 'border-amber-600 text-xs text-amber-600'
                        : '',
                    idea.status === constants.CMTYEVENT_IDEA_STATUS.AWARDED
                        ? 'border-emerald-600 text-xs text-emerald-600'
                        : ''
                )}
            >
                {idea.status}
            </span>
        );
    };

    const canEdit = () => {
        return [constants.CMTYEVENT_IDEA_STATUS.PUBLISHED, constants.CMTYEVENT_IDEA_STATUS.PENDING].includes(
            idea.status
        );
    };

    return (
        <div className="w-full border rounded-md p-6 mb-4 grid grid-cols-6 gap-4 text-gray-600">
            <div className="col-span-6 xl:col-span-5">
                <p className="text-sm font-medium">
                    {moment(idea.apxStart).format('ddd, MMM DD, YYYY h:mm A')} ~{' '}
                    {moment(idea.apxEnd).format('ddd, MMM DD, YYYY h:mm A')}
                </p>
                <p
                    onClick={() => {
                        if (!onEdit) navigate(`/eventideas/${idea._id}`);
                    }}
                    className=" cursor-pointer"
                >
                    <span className="text-lg text-emerald-600 leading-6 inline-block">{idea.title}</span>{' '}
                    {displayBadge()}
                </p>
                <p className="text-sm leading-6">
                    <span className=" font-medium capitalize">{idea.eventType}</span>
                    <span className="px-2">|</span>
                    <span className="font-medium capitalize">{idea.venue}</span>
                    <span className="px-2">|</span>
                    <span className="font-medium capitalize">
                        {idea.eligibleGender === constants.CMTYEVENT_GENDERTYPES.ALL
                            ? 'All Genders'
                            : idea.eligibleGender}
                    </span>
                    <span className="px-2">|</span>
                    {idea.isMatchEvent && (
                        <>
                            <span className="font-medium capitalize">
                                {idea.isAllEligibleLevel()
                                    ? 'All Levels'
                                    : `Level: (${idea.eligibleLevel?.from} ~ ${idea.eligibleLevel?.to})`}
                            </span>
                            <span className="px-2">|</span>
                        </>
                    )}
                    <span className="font-medium capitalize">
                        {idea.ageRanges.length === systemSetting.cmtyEventAgeRanges.length
                            ? 'All Ages'
                            : idea.ageRanges.join(', ')}
                        <span className="px-2">|</span>
                    </span>
                    <span className="font-medium capitalize">{idea.isFree ? 'Free' : `$${idea.price}`}</span>
                </p>
                <div className="text-xs my-1 line-clamp-3">{htmlParse(idea.description)}</div>
                <p className="text-sm leading-6 mb-3">
                    {idea.getClub() ? (
                        <span className=" font-medium capitalize">
                            {idea.getClub()?.displayName || ''}{' '}
                            {idea.getLocation() ? ` | ${idea.getLocation()?.name}` : ''}
                        </span>
                    ) : (
                        <>
                            {idea.venue === constants.VIRTUAL_CMTYEVENT_VENUE ? (
                                <span className=" font-medium capitalize">Any Club supports Virtual event</span>
                            ) : (
                                <span>
                                    {onEdit ? (
                                        <span className=" font-medium capitalize">
                                            {idea.getLocation() ? idea.getLocation()?.name : ''}
                                        </span>
                                    ) : (
                                        <span className=" font-medium capitalize">
                                            {ideaDefaultClub?.displayName}
                                            {idea.getLocation() ? ` | ${idea.getLocation()?.name}` : ''}
                                        </span>
                                    )}
                                </span>
                            )}
                        </>
                    )}
                </p>
                <div className="block sm:flex items-center">
                    <div className="text-sm flex items-center w-full sm:w-[10rem] mb-2 sm:mb-0">
                        {(idea.user as User).photoUrl ? (
                            <img
                                src={(idea.user as User).photoUrl}
                                className="h-8 w-8 rounded-full border-gray-300 border"
                                alt="club logo"
                            />
                        ) : (
                            <div className=" rounded-full w-8 h-8 bg-gray-300 flex justify-center items-center">
                                {(idea.user as User).fullName[0].toUpperCase()}
                            </div>
                        )}
                        <p className="ml-2 font-medium">{(idea.user as User).fullName}</p>
                    </div>
                    {idea.likeMembers.length > 0 && (
                        <div className="flex items-center justify-center sm:justify-start">
                            <div className="flex">
                                {idea.likeMembers.slice(0, 5).map((user, index) => (
                                    <LikeUserPhoto key={index} user={user as User} className=" -ml-2" />
                                ))}
                                <div className="ml-3 text-xs flex items-center">
                                    <ThumbUpIcon className="w-6 h-6 text-emerald-600 mr-1" />
                                    <p className="font-medium">{idea.likeMembers.length}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-span-6 xl:col-span-1 flex justify-end items-center">
                <div className="text-right">
                    {onEdit && (idea.quoteCount || 0) > 0 && (
                        <Button className="bg-amber-600 hover:bg-amber-700 mb-2 w-44" onClick={() => onEdit(idea)}>
                            <>
                                <ChatIcon className="h-4 w-5 mr-3 inline-block" />
                                <span>View Quotes ({idea.quoteCount})</span>
                            </>
                        </Button>
                    )}
                    {onEdit && (
                        <Button className="bg-emerald-600 hover:bg-emerald-700 mb-2 w-44" onClick={() => onEdit(idea)}>
                            {canEdit() ? (
                                <>
                                    <PencilAltIcon className="h-4 w-5 mr-3 inline-block" />
                                    <span>Edit</span>
                                </>
                            ) : (
                                <>
                                    <EyeIcon className="h-4 w-5 mr-3 inline-block" />
                                    <span>Details</span>
                                </>
                            )}
                        </Button>
                    )}
                    {onCancel && idea.canCanceled(user) && (
                        <Button className="bg-red-500 hover:bg-red-600 mb-2 w-44" onClick={() => onCancel(idea)}>
                            <>
                                <TrashIcon className="h-4 w-5 mr-3 inline-block" />
                                <span>Cancel</span>
                            </>
                        </Button>
                    )}
                </div>
                {onLike && (
                    <div className="cursor-pointer text-center mb-2 w-full" onClick={() => onLike(idea)}>
                        {idea.likeMembers.some((member) => member._id === user?._id) ? (
                            <SolidIcons.ThumbUpIcon className="w-8 h-8 text-emerald-600 hover:text-emerald-700 mx-auto" />
                        ) : (
                            <ThumbUpIcon className="w-8 h-8 text-emerald-600 hover:text-emerald-700 mx-auto" />
                        )}
                        <p className="text-xs text-gray-400 hover:text-gray-500">
                            If you are willing to attend this event, Plase click Like Button
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CmtyEventIdeaListItem;
