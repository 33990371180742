import Loader from 'components/utils/Loader';
import React from 'react';
import { classNames } from 'utils';

type PropsType = {
    className?: string;
    textColor?: string;
    color?: string;
    hoverColor?: string;
    outline?: boolean;
    disabled?: boolean;
    loading?: boolean;
    loadingColor?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    children: React.ReactChild;
    onClick?: () => void;
};

const Button = ({
    className,
    textColor,
    color,
    hoverColor,
    disabled,
    loading,
    loadingColor = 'text-white',
    type,
    children,
    onClick,
}: PropsType) => {
    return (
        <button
            className={classNames(
                className || '',
                textColor || 'text-white',
                color || 'bg-emerald-600',
                hoverColor ? `hover:${hoverColor}` : 'hover:bg-emerald-700',
                'justify-center py-2 px-4 border relative z-0 shadow-sm text-sm font-medium rounded-md items-center focus:outline-none focus:ring-1 focus:ring-offset-2'
            )}
            disabled={disabled || loading}
            onClick={() => {
                if (onClick) onClick();
            }}
            type={type || 'button'}
        >
            <div>
                <span>
                    {loading && (
                        <div className=" absolute top-2 left-3">
                            <Loader color={loadingColor} size={5} />
                        </div>
                    )}
                </span>
                {children}
            </div>
        </button>
    );
};

export default Button;
