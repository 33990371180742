import { CmtyEventApi } from 'apis';
import Modal from 'components/utils/Modal';
import Button from 'components/form-controls/Button';
import { useEffect, useState } from 'react';
import { CmtyEvent, CmtyEventRegUser, User } from 'utils/types';
import { handleHTTPError } from 'store/error';
import constants from 'utils/constants';
import MatchSetting from './MatchSetting';
import { downloadCSV } from 'utils';
import MatchResult from './MatchResult';

type PropsType = {
    event: CmtyEvent;
    regUsers: CmtyEventRegUser[];
    isOpen: boolean;
    toggle: () => void;
};

export enum AutoMatchGameTypes {
    Single = 'single',
    Double = 'double',
}

export enum AutoMatchGenderTypes {
    OneGender = 'onegender',
    Mixed = 'mixed',
    RandomGender = 'randomgender',
}

export enum AutoMatchLevelTypes {
    Ranked = 'ranked',
    Even = 'even',
    Random = 'random',
}

export enum AutoMatchSettingTypes {
    GameType = 'gameType',
    GenderType = 'genderType',
    LevelType = 'levelType',
}

const AutoMatchGenModal = ({ event, isOpen, regUsers, toggle }: PropsType) => {
    const [result, setResult] = useState<any[]>([]);
    const [step, setStep] = useState<number>(0);
    const [gameType, setGameType] = useState<AutoMatchGameTypes>(AutoMatchGameTypes.Single);
    const [genderType, setGenderType] = useState<AutoMatchGenderTypes>(AutoMatchGenderTypes.RandomGender);
    const [levelType, setLevelType] = useState<AutoMatchLevelTypes>(AutoMatchLevelTypes.Ranked);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const update = (type: string, value: string) => {
        if (type === AutoMatchSettingTypes.GameType) setGameType(value as AutoMatchGameTypes);
        else if (type === AutoMatchSettingTypes.GenderType) setGenderType(value as AutoMatchGenderTypes);
        else if (type === AutoMatchSettingTypes.LevelType) setLevelType(value as AutoMatchLevelTypes);
    };

    const matchGenerate = () => {
        const params = {
            _id: event._id,
            gameType,
            genderType,
            levelType,
        };
        setLoading(true);
        CmtyEventApi.generateMatchGroup(params)
            .then((data) => {
                setLoading(false);
                setResult(data.data);
                setStep(1);
                console.log(data.data);
            })
            .catch((error) => {
                setLoading(false);
                handleHTTPError(error);
            });
    };

    const getMemberInfo = (member: CmtyEventRegUser) => {
        if (!member) return '';
        const user = member.user as User;
        return `${user.fullName} (${user?.gender === constants.GENDER.MALE ? 'M' : 'F'} ${user?.age}) - ${
            member?.gameLevel
        }`;
    };

    const downloadAutoMatchCSV = () => {
        let csvData: any[] = [];
        if (gameType === 'single') {
            result.forEach((group) => {
                csvData.push({
                    'Member A': getMemberInfo(group[0]),
                    '': 'VS',
                    'Member B': getMemberInfo(group[1]),
                });
            });
        }
        if (gameType === 'double') {
            result.forEach((group) => {
                const team1 = group[0] || [];
                const team2 = group[1] || [];
                csvData.push({
                    'Member A': getMemberInfo(team1[0]),
                    'Member B': getMemberInfo(team1[1]),
                    '': 'VS',
                    'Member C': getMemberInfo(team2[0]),
                    'Member D': getMemberInfo(team2[1]),
                });
            });
        }
        downloadCSV(csvData, 'match_schedule');
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="sm:max-w-3xl"
            loading={loading}
            title="Auto Match Generate"
            headBorder
            disableOutClick
        >
            <div className="p-5">
                {step === 0 && (
                    <MatchSetting
                        event={event}
                        gameType={gameType}
                        genderType={genderType}
                        levelType={levelType}
                        regUsers={regUsers}
                        update={update}
                    />
                )}
                {step === 1 && <MatchResult result={result} gameType={gameType} />}
                <div className="flex justify-end mt-6">
                    <Button
                        textColor="text-gray-700"
                        className="border border-gray-300"
                        color="bg-white"
                        hoverColor="bg-gray-50"
                        onClick={toggle}
                    >
                        Close
                    </Button>
                    {step === 0 && regUsers?.length > 0 && (
                        <Button className="ml-2" onClick={matchGenerate}>
                            Generate
                        </Button>
                    )}
                    {step === 1 && (
                        <>
                            <Button className="ml-2" onClick={() => setStep(0)}>
                                Previous
                            </Button>
                            <Button className="ml-2" onClick={downloadAutoMatchCSV}>
                                Download CSV
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AutoMatchGenModal;
