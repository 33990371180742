import { useState } from 'react';
import { useAppSelector } from 'store/hook';
import { CmtyEvent } from 'utils/types';
import CmtyEventRegUsersModal from './regusers-modal';

type PropsType = {
    className?: string;
    event: CmtyEvent;
    enableShowRegUser?: boolean;
};

const CmtyEventRegCount = ({ className, event, enableShowRegUser = true }: PropsType) => {
    const user = useAppSelector((state) => state.auth.user);
    const [isOpenRegUserModal, setIsOpenRegUserModal] = useState<boolean>(false);
    return (
        <div className={className || ''}>
            <div className="mb-1 text-sm flex justify-around">
                <p className="text-xs">Max</p>
                <p className="text-xs">Registered</p>
                <p className="text-xs">Exempt</p>
            </div>
            <div className="border border-gray-300 rounded-full text-center grid grid-cols-3 text-sm font-medium py-2">
                <div className={`col-span-1 ${event.maxRegCount === event.getRegUserCount() ? 'text-red-500' : ''}`}>
                    {event.maxRegCount}
                </div>
                <div
                    className={`col-span-1 border-x border-gray-300 ${
                        event.maxRegCount === event.getRegUserCount() ? 'text-red-500' : ''
                    }`}
                >
                    {event.getRegUserCount()}
                </div>
                <div className="col-span-1 ">{event.getWaitListCount()}</div>
            </div>
            {user && event.getRegUserCount() > 0 && enableShowRegUser && (
                <>
                    <div
                        className="text-center text-emerald-600 mt-1 text-xs underline cursor-pointer"
                        onClick={() => setIsOpenRegUserModal(true)}
                    >
                        View Registered Users
                    </div>
                    <CmtyEventRegUsersModal
                        event={event}
                        isOpen={isOpenRegUserModal}
                        toggle={() => setIsOpenRegUserModal(false)}
                    />
                </>
            )}
        </div>
    );
};

export default CmtyEventRegCount;
