import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserModel } from 'utils/types';

export type AuthState = {
    user?: User;
    token?: string;
    refreshToken?: string;
};

const initialState: AuthState = {
    user: localStorage.getItem('user')
        ? new User(JSON.parse(localStorage.getItem('user') as string) as UserModel)
        : undefined,
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<AuthState>) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            localStorage.setItem('token', action.payload.token || '');
            localStorage.setItem('refreshToken', action.payload.refreshToken || '');
        },
        logout: (state) => {
            state.user = undefined;
            state.token = undefined;
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
        },
        putUser: (state, action: PayloadAction<User | undefined>) => {
            state.user = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
    },
});

export const { putUser, login, logout } = auth.actions;
export default auth.reducer;
