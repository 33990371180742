import { CmtyEventIdeaApi } from 'apis';
import Pagination from 'components/utils/Pagination';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { handleHTTPError } from 'store/error';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setCommonLoading, setHasMyIdeas, showAlert, showAlertModal } from 'store/ui';
import { CmtyEventIdea, CmtyEventIdeaModel } from 'utils/types';
import Button from 'components/form-controls/Button';
import { ShareIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router';
import CmtyEventIdeaList from 'components/cmtyeventideas/eventidealist';
import constants from 'utils/constants';
import PageContentLayout from 'layouts/PageContentLayout';

const MyEventIdeas = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.user);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [ideas, setIdeas] = useState<CmtyEventIdea[]>([]);

    useEffect(() => {
        search(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const search = (skip = 0) => {
        const params = {
            user: user?._id,
            'apxStart.gte': moment().startOf('day').format('YYYY-MM-DD HH:mm'),
            'status.or': [
                constants.CMTYEVENT_IDEA_STATUS.PUBLISHED,
                // constants.CMTYEVENT_IDEA_STATUS.AWARDED,
                // constants.CMTYEVENT_IDEA_STATUS.EXPIRED,
            ].join(','),
            skip,
            limit: 10,
            sort_by: 'apxStart',
            order_by: 'asc',
        };
        searchEvents(params);
    };

    const searchEvents = (params: any) => {
        dispatch(setCommonLoading(true));
        CmtyEventIdeaApi.retrieve(params)
            .then((data) => {
                dispatch(setCommonLoading(false));
                const ideas: CmtyEventIdea[] = (data.data as CmtyEventIdeaModel[]).map(
                    (idea) => new CmtyEventIdea(idea)
                );
                setIdeas(ideas);
                setTotalCount(data.totalCount || 0);
                if (data.totalCount > 0) dispatch(setHasMyIdeas(true));
                else dispatch(setHasMyIdeas(false));
                if ((data.totalCount || 0) <= currentPage * 10) setCurrentPage(Math.max(0, currentPage - 1));
            })
            .catch((error) => {
                dispatch(setCommonLoading(false));
                dispatch(handleHTTPError(error));
            });
    };

    const pageChange = (page: number) => {
        setCurrentPage(page);
        search(page * 10);
    };

    const onEdit = (idea: CmtyEventIdea) => {
        navigate(`/myeventideas/${idea._id}`);
    };

    const onCancel = (idea: CmtyEventIdea) => {
        dispatch(
            showAlertModal({
                type: 'warning',
                title: 'Cancel Community Event Idea',
                message: 'Do you want to cancel this idea? \n Are you sure?',
                buttons: [
                    {
                        type: 'ok',
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        type: 'normal',
                        label: 'No',
                        value: 'no',
                    },
                ],
                handler: (value: string) => {
                    if (value === 'no') return;
                    const params = {
                        _id: idea._id,
                    };
                    dispatch(setCommonLoading(true));
                    CmtyEventIdeaApi.delete(params)
                        .then((data) => {
                            dispatch(setCommonLoading(false));
                            dispatch(showAlert({ type: 'success', message: data.message }));
                            search();
                        })
                        .catch((error) => {
                            dispatch(setCommonLoading(false));
                            dispatch(handleHTTPError(error));
                        });
                },
            })
        );
    };

    return (
        <PageContentLayout scrollTop={false}>
            <div className="flex justify-end mb-4 md:mb-10">
                <Button
                    color="bg-amber-600"
                    hoverColor="bg-amber-700"
                    onClick={() => {
                        navigate('/myeventideas/new');
                    }}
                >
                    <>
                        <ShareIcon className="w-4 h-4 mr-3 inline-block" />
                        <span>Create New Event Idea</span>
                    </>
                </Button>
            </div>
            <CmtyEventIdeaList ideas={ideas} onEdit={onEdit} onCancel={onCancel} />
            <Pagination
                totalCount={totalCount}
                page={currentPage}
                pageRangeDisplayed={5}
                countPerPage={10}
                onChange={pageChange}
            />
        </PageContentLayout>
    );
};

export default MyEventIdeas;
