import { useAppDispatch, useAppSelector } from 'store/hook';
import { toggleCmtyRegResultModal } from 'store/ui';
import Modal from 'components/utils/Modal';
import Button from 'components/form-controls/Button';
import { CmtyEventRegResult } from 'utils/types';
import moment from 'moment-timezone';
import constants from 'utils/constants';

const CmtyEventRegResultModal = ({ result, callback }: { result: CmtyEventRegResult[]; callback?: () => void }) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.ui.isOpenCmtyRegResultModal);

    const toggle = () => {
        dispatch(toggleCmtyRegResultModal());
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="sm:max-w-md"
            title={`Registration Result (${result.length} weeks)`}
            headBorder
        >
            <div className="p-4 md:p-6 overflow-y-auto max-h-96">
                {result.map((data, index) => (
                    <div className="ml-4" key={index}>
                        <div className="text-gray-500 text-sm font-medium">
                            {moment(data.eventDate).format('ddd MMM DD, YYYY')}
                        </div>
                        <div
                            className={`ml-3 mb-2 text-xs ${
                                !data.regUser
                                    ? 'text-red-500'
                                    : data.regUser.isWaitList
                                    ? 'text-amber-600'
                                    : 'text-emerald-600'
                            }`}
                        >
                            {!data.regUser
                                ? data.message
                                : data.regUser?.isWaitList
                                ? 'Registered in Waitlist'
                                : `Registration Success${
                                      data.regUser.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED
                                          ? ' (Paid)'
                                          : ''
                                  }`}
                        </div>
                    </div>
                ))}
            </div>
            <div className="p-4 bg-gray-50 rounded-b-md flex justify-end">
                <Button
                    textColor="text-gray-700"
                    className="border border-gray-300"
                    color="bg-white"
                    hoverColor="bg-gray-50"
                    onClick={() => {
                        dispatch(toggleCmtyRegResultModal());
                        if (callback) callback();
                    }}
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
};

export default CmtyEventRegResultModal;
