const constants = {
    VERSION_NUMBER: 100,
    DEMOCLUB_ID: '00002',
    userType: {
        MEMBER: 0,
        SERVICE: 1,
    },

    SCHED_STATUS: {
        DISABLED: -1,
        PENDING: 0,
        CONFIRM: 1,
        CANCELED: 2,
        OPEN: 3,
        CHALLENGE: 4,
    },

    TEMPLATE_STATUS: {
        WAIT: 0,
        PUBLISHED: 1,
        EXPIRED: 2,
    },

    CLUBMSG_TYPE: {
        CLUB: 0,
        EVENT: 1,
    },

    VENUE_TYPE: {
        REAL: 0,
        VIRTUAL: 1,
    },

    VENUE_STATUS: {
        INACTIVATED: 0,
        ACTIVATED: 1,
    },

    errorMsg: {
        INVALID_TOKEN: 'Access Denied. Please login again',
        INTERNAL_ERROR: 'Sorry. Operation fail.',
    },

    DEFAULT_PHOTO: 'https://tennislives.s3.amazonaws.com/usericon.png',

    DEFAULT_CLUBLOGO: 'https://tennislives.s3.amazonaws.com/logo.png',

    DEFAULT_EVENETPHOTO: 'https://tennislives.s3.amazonaws.com/default_eventphoto.png',

    times: ['09:00:00 AM', '10:15:00 AM', '11:30:00 AM', '04:00:00 PM ', '05:45:00 PM', '07:00:00 PM'],

    facilityTypes: [
        { id: 0, name: 'FACILITY1' },
        { id: 1, name: 'FACILITY2' },
        { id: 2, name: 'FACILITY3' },
        { id: 3, name: 'FACILITY4' },
        { id: 4, name: 'FACILITY5' },
    ],
    venuesTypes: [
        { id: 0, name: 'TENNIS' },
        { id: 1, name: 'VENUE2' },
        { id: 2, name: 'VENUE3' },
        { id: 3, name: 'VENUE4' },
        { id: 4, name: 'VENUE5' },
    ],

    COURT_COLORS: ['#dc3545', '#3281ff', '#f9c10a', '#a332ff', '#a02b6e', '#2ba09e'],

    LEVEL_TYPE: {
        UTR: 0,
        USTA: 1,
        SELF: 2,
        PRO: 3,
    },

    GENDER: {
        MALE: 0,
        FEMALE: 1,
        MIX: 2,
        NOPREFER: 3,
    },

    CLUB_STATUS: {
        INACTIVATED: 0,
        ACTIVATED: 1,
        BLOCKED: 2,
    },

    REPORT_TYPE: {
        ISSUE: 'issue',
        CONTACTUS: 'contact us',
    },

    MEMBER_GROUP_TYPE: {
        SPONSOR: 0,
        CLUB: 1,
    },

    CLUBEVENT_EMAILOPTIONS: {
        ALL_INVITED_MEMBERS: 0,
        ALL_REG_MEMBERS: 1,
        SPECIAL_GROUP_MEMBERS: 2,
    },

    EMAILOPTIONS: {
        ALL_REG_MEMBERS: 'all_reg_members',
        ALL_MEMBERS: 'all_members',
    },

    LAYOUT_SCHEDULE: {
        ONEPAGE: 0,
        LISTDETAIL: 1,
    },

    POST_STATUS: {
        CREATED: 0,
        ACCEPTED: 1,
        PUBLISHED: 2,
    },

    COMMENT_STATUS: {
        DRAFT: 0,
        ADDED: 1,
    },

    COMMENT_USERTYPE: {
        ADVISOR: 0,
        CLUB: 1,
        MEMBER: 2,
    },

    GUIDE_TYPE: {
        VIDEO: 0,
        PDF: 1,
    },

    RACQMATCH_GAMETYPES: {
        SINGLE: 'single',
        DOUBLE: 'double',
    },

    CMTYEVENT_STATUS: {
        PUBLISHED: 'published',
        FINISHED: 'finished',
        COMPLETED: 'completed',
        CANCELED: 'canceled',
    },

    INVOICE_STATUS: {
        DRAFT: 'draft',
        CREATED: 'created',
        PENDING: 'pending',
        PAID: 'paid',
        CANCELED: 'canceled',
    },

    INVOICE_TYPE: {
        CMTY_EVENT: 'cmtyevent',
    },

    CMTYEVENT_GENDERTYPES: {
        ALL: 'all',
        MALE: 'male',
        FEMALE: 'female',
    },

    CMTYEVENT_FEETYPES: {
        ALL: 'all',
        FREE: 'free',
        LESS10: 'lesson than $10',
        GREATER10: 'more than $10',
    },

    CMTYEVENT_REGUSER_STATUS: {
        REGISTERED: 'registered',
        CONFIRMED: 'confirmed',
        CANCELED: 'canceled',
    },

    PAYMENT_TYPE: {
        CMTY_EVENT: 'cmtyevent',
        CMTY_EVENTIDEA_POST: 'cmtyeventidea_post',
    },

    PAYMENT_STATUS: {
        OPEN: 'open',
        PENDING: 'pending',
        CANCELED: 'canceled',
        EXPIRED: 'expired',
        FAILED: 'failed',
        PAID: 'paid',
        REFUNDED: 'refunded',
    },

    USERACCOUNT_VERIFY_TYPE: {
        CMTYEVENT: 'cmtyevent_account',
        CLUB: 'club_account',
    },

    CMTYEVENT_PAYTYPE: {
        ALL_WEEK: 'all_week',
        WEEK_BY_WEEK: 'week_by_week',
    },

    CMTYEVENT_IDEA_STATUS: {
        PENDING: 'pending',
        PUBLISHED: 'published',
        CANCELED: 'canceled',
        EXPIRED: 'expired',
        AWARDED: 'awarded',
    },

    VIRTUAL_CMTYEVENT_VENUE: 'Virtual',
};

export default constants;
