import { CmtyEvent } from './cmtyevent';

export type ClubModel = {
    _id: string;
    ID: string;
    stripeId?: string;
    displayName: string;
    email: string;
    websiteUrl?: string;
    mobilePhone?: string;
    company?: string;
    facility?: string;
    logoUrl?: string;
    plannedCourtsCount: number;
    activatedCourtsCount: number;
    status: number;
    allowReservations: boolean;
    allowCmtyEvent: boolean;
    setting?: ClubSettingModel | string;
    isDefault: boolean;
    isIdeaDefault: boolean;
    cmtyEventPercentageFee?: number;
    cmtyEventFixedFee?: number;
    preferPaymentService?: string;
    timezone: string;
};

export class Club {
    _id: string;
    ID: string;
    stripeId?: string;
    displayName: string;
    email: string;
    websiteUrl?: string;
    mobilePhone?: string;
    company?: string;
    facility?: string;
    logoUrl?: string;
    plannedCourtsCount: number;
    activatedCourtsCount: number;
    status: number;
    allowReservations: boolean;
    allowCmtyEvent: boolean;
    isDefault: boolean;
    isIdeaDefault: boolean;
    cmtyEventPercentageFee?: number;
    cmtyEventFixedFee?: number;
    preferPaymentService?: string;
    timezone: string;
    setting?: ClubSetting | string;

    constructor({
        _id,
        ID,
        stripeId,
        displayName,
        email,
        websiteUrl,
        mobilePhone,
        company,
        facility,
        logoUrl,
        plannedCourtsCount,
        activatedCourtsCount,
        status,
        allowReservations,
        allowCmtyEvent,
        isDefault,
        isIdeaDefault,
        cmtyEventPercentageFee,
        cmtyEventFixedFee,
        preferPaymentService,
        timezone,
        setting,
    }: ClubModel) {
        this._id = _id;
        this.ID = ID;
        this.stripeId = stripeId;
        this.displayName = displayName;
        this.email = email;
        this.websiteUrl = websiteUrl;
        this.mobilePhone = mobilePhone;
        this.company = company;
        this.facility = facility;
        this.logoUrl = logoUrl;
        this.plannedCourtsCount = plannedCourtsCount || 10;
        this.activatedCourtsCount = activatedCourtsCount || 0;
        this.status = status;
        this.allowReservations = allowReservations ?? false;
        this.allowCmtyEvent = allowCmtyEvent ?? true;
        this.isDefault = isDefault ?? false;
        this.isIdeaDefault = isIdeaDefault ?? false;
        this.cmtyEventPercentageFee = cmtyEventPercentageFee;
        this.cmtyEventFixedFee = cmtyEventFixedFee;
        this.preferPaymentService = preferPaymentService;
        this.timezone = timezone || 'America/Los_Angeles';
        this.setting = setting ? new ClubSetting(setting as ClubSettingModel) : setting;
    }

    public static getOrganizationName(clubs: Club[], organization: string): string {
        const club = clubs.find((c) => c._id === organization);
        if (club) return club.displayName;
        return organization;
    }
}

export type ClubSettingModel = {
    isClosed: boolean;
    closeMessage?: string;
    cmtyAdvocatorAuthorizations: {};
    geoZipCodes: string[];
    allowVirtualCmtyEvent: boolean;
    cmtyEventDiscountPercent: number;
};

export class ClubSetting {
    isClosed?: boolean;
    closeMessage?: string;
    cmtyAdvocatorAuthorizations?: {};
    geoZipCodes?: string[];
    allowVirtualCmtyEvent: boolean;
    cmtyEventDiscountPercent: number;

    constructor({
        isClosed,
        closeMessage,
        cmtyAdvocatorAuthorizations,
        geoZipCodes,
        allowVirtualCmtyEvent,
        cmtyEventDiscountPercent,
    }: ClubSettingModel) {
        this.isClosed = isClosed ?? false;
        this.closeMessage = closeMessage;
        this.cmtyAdvocatorAuthorizations = cmtyAdvocatorAuthorizations || {};
        this.geoZipCodes = geoZipCodes || [];
        this.allowVirtualCmtyEvent = allowVirtualCmtyEvent ?? false;
        this.cmtyEventDiscountPercent = cmtyEventDiscountPercent || 0;
    }
}

export type ClubMsg = {
    _id: string;
    club: ClubModel | string;
    startDate: string;
    endDate: string;
    content: string;
    type?: number;
    cmtyEvent: CmtyEvent | string;
};
