import { AuthApi } from 'apis';
import Button from 'components/form-controls/Button';
import { useState } from 'react';
import { putUser } from 'store/auth';
import { handleHTTPError } from 'store/error';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setCommonLoading, showAlert } from 'store/ui';
import { User, UserModel } from 'utils/types';
import CheckBox from 'components/form-controls/CheckBox';

const Notifications = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);

    const [isReceiveEmail, setIsReceiveEmail] = useState(user?.isReceiveEmail ?? true);
    const [isRecevieNotification, setIsReceiveNoti] = useState(user?.isRecevieNotification ?? true);

    const save = () => {
        const params = {
            _id: user?._id,
            isReceiveEmail,
            isRecevieNotification,
        };
        dispatch(setCommonLoading(true));
        AuthApi.update(params)
            .then((data) => {
                dispatch(setCommonLoading(false));
                dispatch(putUser(new User(data.data as UserModel)));
                dispatch(showAlert({ type: 'success', message: 'Noification setting has been saved.' }));
            })
            .catch((error) => {
                console.log(error);
                dispatch(setCommonLoading(false));
                dispatch(handleHTTPError(error));
            });
    };

    return (
        <div className="py-6 border-b ">
            <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 md:col-span-1">
                    <h5 className="text-xl text-gray-800">Notifications</h5>
                    <p className="text-gray-500 text-sm">Decide which communications you'd like to receive and how.</p>
                </div>
                <div className="bg-white col-span-3 md:col-span-2 rounded-md shadow p-4 md:p-10">
                    <CheckBox
                        id="noti-email"
                        name="notificatin"
                        label="Do you want to receive emails on updates and reminders?"
                        className="mb-6"
                        checked={isReceiveEmail}
                        onChange={(checked) => {
                            setIsReceiveEmail(checked);
                        }}
                    />
                    <CheckBox
                        id="noti-mobile"
                        name="notificatin"
                        label="Do you want to receive notifications on your mobile app?"
                        checked={isRecevieNotification}
                        onChange={(checked) => {
                            setIsReceiveNoti(checked);
                        }}
                    />
                    <div className="flex justify-end mt-6">
                        <Button className=" w-36" onClick={save}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
