import { Transition } from '@headlessui/react';
import { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { classNames } from 'utils';
import { Controller } from 'react-hook-form';
import { XIcon } from '@heroicons/react/outline';

type PropsType = {
    className?: string;
    label?: string | JSX.Element;
    labelClassName?: string;
    required?: boolean;
    inputClassName?: string;
    name: string;
    defaultValue?: string;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    onChange?: (value: any) => void;
    valid?: boolean;
    validation?: any;
};

const GoogleAutoComplete = ({
    label,
    className = '',
    labelClassName = '',
    required = false,
    name,
    value,
    disabled,
    onChange,
    defaultValue,
    placeholder,
    validation, // { control, rules, formState}
}: PropsType) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (buttonRef.current && validation) {
            const errorKeys = Object.keys(validation?.formState?.errors);
            if (errorKeys.length > 0 && errorKeys[0] === name) buttonRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation?.formState.submitCount]);

    return (
        <div className={className || ''}>
            {label && (
                <>
                    {typeof label === 'string' ? (
                        <label
                            htmlFor={name}
                            className={classNames(
                                'block text-sm font-medium text-gray-700 mb-1 text-left',
                                labelClassName || ''
                            )}
                        >
                            {label}
                            {required && <span className=" text-red-500 ml-1">*</span>}
                        </label>
                    ) : (
                        <>{label}</>
                    )}
                </>
            )}
            {validation?.control ? (
                <Controller
                    name={name}
                    control={validation.control}
                    rules={validation.rules}
                    render={({ field: { ref, ...rest } }) => (
                        <GoogleAutoCompleteSelect
                            defaultValue={defaultValue}
                            disabled={disabled}
                            placeholder={placeholder}
                            valid={!validation?.formState?.errors[name]}
                            ref={buttonRef}
                            {...rest}
                        />
                    )}
                />
            ) : (
                <GoogleAutoCompleteSelect
                    name={name}
                    className={className}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onChange}
                    ref={buttonRef}
                />
            )}
        </div>
    );
};

export default GoogleAutoComplete;

const GoogleAutoCompleteSelect = forwardRef<HTMLButtonElement, PropsType>(
    (
        { defaultValue, value, disabled, onChange, valid = true, placeholder = '1 Larkspur Plaza Dr' }: PropsType,
        buttonRef
    ) => {
        const [address, setAddress] = useState<string>('');
        const {
            ready,
            suggestions: { status, data },
            setValue,
            clearSuggestions,
        } = usePlacesAutocomplete({
            requestOptions: {
                componentRestrictions: {
                    country: ['us'],
                },
                // types: ['(cities)'],
            },
            debounce: 300,
        });
        const ref = useOnclickOutside(() => {
            clearSuggestions();
        });

        useEffect(() => {
            const defaultAddress: any = defaultValue || value || '';
            if (typeof defaultAddress === 'string') setAddress(defaultAddress);
            else setAddress(defaultAddress.address || '');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handleInput = (value: string) => {
            setAddress(value);
            setValue(value);
        };

        const handleSelect = (data: any) => {
            const { description, terms } = data;
            const state = terms[terms.length - 2]?.value;
            const city = terms[terms.length - 3]?.value;
            return () => {
                setValue(description, false);
                setAddress(description);
                clearSuggestions();
                getGeocode({ address: description })
                    .then((results) => {
                        const { lat, lng } = getLatLng(results[0]);
                        const zipCode = getZipCode(results[0], false);
                        onChange?.({
                            address: description,
                            state,
                            city,
                            zipCode,
                            location: { lat, lng },
                        });
                    })
                    .catch((error) => {});
            };
        };

        const renderSuggestions = () =>
            data.map((suggestion) => {
                const {
                    place_id,
                    structured_formatting: { main_text, secondary_text },
                } = suggestion;

                return (
                    <li
                        key={place_id}
                        onClick={handleSelect(suggestion)}
                        className="select-none relative py-2 pl-3 pr-9 cursor-pointer text-gray-900 hover:text-white hover:bg-emerald-600"
                    >
                        <div className="text-sm font-medium">
                            {main_text} {secondary_text}
                        </div>
                    </li>
                );
            });

        return (
            <div className="relative" ref={ref}>
                <button
                    type="button"
                    className={classNames(
                        'bg-white relative w-full min-h-10 border border-gray-300 rounded-md shadow-sm overflow-hidden flex items-center',
                        'pr-2 text-left focus:outline-none focus:ring-1 sm:text-sm cursor-pointer',
                        !valid
                            ? 'focus:border-red-500 focus:ring-red-500'
                            : 'focus:border-emerald-600 focus:ring-emerald-600'
                    )}
                    ref={buttonRef}
                >
                    <input
                        type="text"
                        value={address}
                        placeholder={placeholder}
                        autoComplete="off"
                        className="flex-grow border-none focus:ring-0 py-2 pr-5 min-w-24 text-sm leading-5 text-gray-900"
                        disabled={disabled || !ready}
                        onChange={(e) => {
                            handleInput(e.target.value);
                        }}
                    />
                    {value && (
                        <XIcon
                            className="w-4 h-4 text-gray-400 hover:text-gray-600 flex-shrink-0"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleInput('');
                                onChange?.(undefined);
                            }}
                        />
                    )}
                </button>
                {status === 'OK' && (
                    <Transition
                        as={Fragment}
                        show={data.length > 0}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ul className="w-full absolute mt-1 z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {renderSuggestions()}
                        </ul>
                    </Transition>
                )}
            </div>
        );
    }
);
