import moment from 'moment-timezone';
import { saveAs } from 'file-saver';

// type CSVDataType = [x: string];

export const validateEmail = (value: string): boolean => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
};

export const downloadCSV = (data: any[], file_name: string): void => {
    const csv = convertArrayOfObjectsToCSV(data);
    if (csv == null) return;
    const blob = new Blob([csv], { type: 'data:text/csv;charset=utf-8' });
    saveAs(blob, `${file_name}.csv`, { autoBom: true });
};

export const convertArrayOfObjectsToCSV = (data: any[]): string => {
    if ((data || []).length === 0) return '';
    const columnDelimiter: string = ',';
    const lineDelimiter: string = '\n';
    const keys: string[] = Object.keys(data[0]);
    const head: string = keys.join(columnDelimiter) + lineDelimiter;
    let result = data
        .map((item) => {
            if (typeof item !== 'object') return String(item);
            else {
                return keys
                    .map((key) => (typeof item[key] === 'string' ? `"${item[key]}"` : item[key]))
                    .join(columnDelimiter);
            }
        })
        .join(lineDelimiter);

    return head + result;
};

export const getTimeZoneMoment = (timezone: string): moment.Moment => {
    if (timezone) return moment(moment().tz(timezone).format('YYYY-MM-DD HH:mm'));
    return moment();
};

export const findTextFromHtml = (htmlString: string) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    const text = div.textContent || div.innerText || '';
    return text;
};

export const capitalizeString = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
};

export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
