import { ClubApi, CmtyEventApi, CmtyEventLocationApi } from 'apis';
import DatePicker from 'components/form-controls/datepicker';
import TextBox from 'components/form-controls/TextBox';
import TextArea from 'components/form-controls/TextArea';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { handleHTTPError } from 'store/error';
import {
    Club,
    ClubModel,
    ClubMsg,
    ClubSetting,
    CmtyEvent,
    CmtyEventLocation,
    CmtyEventLocationModel,
} from 'utils/types';
import Select from 'components/form-controls/Select';
import { useAppSelector } from 'store/hook';
import constants from 'utils/constants';
import { capitalizeString } from 'utils';
import CmtyEventPhotos from 'components/cmtyevents/cmtyevent-photos';
import CheckBox from 'components/form-controls/CheckBox';
import Button from 'components/form-controls/Button';
import EmailOptionModel from './EmailOptionModal';
import { setCommonLoading, showAlert } from 'store/ui';

type FormValues = {
    location: string;
    eventType: string;
    isMatchEvent: boolean;
    venue: string;
    url?: string;
    description: string;
    title: string;
    isRepeat: boolean;
    noExpired: boolean;
    repeatWeeks: number;
    start: string;
    end: string;
    ageRanges: string[];
    eligibleGender: string;
    eligibleLevelFrom?: string | number;
    eligibleLevelTo?: string | number;
    isAllEligibleLevel?: boolean;
    isReqRegister: boolean;
    canRegMultiWeeks: boolean;
    maxRegCount: number;
    isRequireEmail: boolean;
    isFree: boolean;
    price?: number;
    payBeforeDays?: number;
    emailOptions?: number;
    emailContent: string;
    isRequireMsg: boolean;
    clubMsgStDate?: string;
    clubMsgEtDate?: string;
    clubMsgContent?: string;
};

const CmtyEventContent = ({ event }: { event: CmtyEvent }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const systemSetting = useAppSelector((state) => state.club.systemSetting);
    const user = useAppSelector((state) => state.auth.user);
    const { register, formState, handleSubmit, reset, control, watch, setValue } = useForm<FormValues>({
        defaultValues: {},
    });
    const [cmtyLocations, setCmtyLocations] = useState<CmtyEventLocation[]>([]);
    const [club, setClub] = useState<Club | null>(null);
    const [authorization, setAuthorization] = useState<any>({});
    const [isOpenEmailOption, setIsOpenEmailOption] = useState(false);
    const [emailContent, setEmailContent] = useState<string | undefined>(undefined);
    const watchFields = [
        'isReqRegister',
        'isMatchEvent',
        'isRepeat',
        'noExpired',
        'start',
        'repeatWeeks',
        'location',
        'isRequireMsg',
        'isFree',
        'payBeforeDays',
        'start',
        'venue',
        'isAllEligibleLevel',
    ];
    const watchValues = watch([
        'isReqRegister',
        'isMatchEvent',
        'isRepeat',
        'noExpired',
        'start',
        'repeatWeeks',
        'location',
        'isRequireMsg',
        'isFree',
        'payBeforeDays',
        'start',
        'venue',
        'isAllEligibleLevel',
    ]);

    useEffect(() => {
        ClubApi.read({ _id: (event.club as Club)._id })
            .then((data) => {
                const club = new Club(data.data as ClubModel);
                setClub(club);
                setAuthorization((club.setting as ClubSetting).cmtyAdvocatorAuthorizations || {});
                retrieveCmtyEventLocations(club, event);
            })
            .catch((error) => {
                dispatch(handleHTTPError(error));
            });

        const subscription = watch((value, { name, type }) => {
            if (name === 'isRequireEmail' && value.isRequireEmail) {
                setIsOpenEmailOption(true);
            }
        });
        return () => subscription.unsubscribe();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveCmtyEventLocations = async (club: Club, event: CmtyEvent) => {
        try {
            let { data: clubLocations } = await CmtyEventLocationApi.retrieve({ club: club._id });
            let locations = (clubLocations as CmtyEventLocationModel[]).map(
                (location) => new CmtyEventLocation(location)
            );
            if (event.getReferenceIdeaUserId() === user?._id) {
                let { data: userLocations } = await CmtyEventLocationApi.retrieve({ user: user?._id });
                locations = [
                    ...locations,
                    ...(userLocations as CmtyEventLocationModel[]).map((location) => new CmtyEventLocation(location)),
                ];
            }
            setCmtyLocations(locations);
            reset({
                location: (event.location as CmtyEventLocation)._id,
                eventType: event.eventType,
                isMatchEvent: event.isMatchEvent,
                venue: event.venue,
                url: event.url,
                description: (event.description || '')?.replaceAll('&nbsp;', ''),
                title: event.title,
                noExpired: event.noExpired ?? true,
                isRepeat: event.isRepeat ?? true,
                repeatWeeks: event.repeatWeeks,
                start: event.start,
                end: event.end,
                ageRanges: event.ageRanges,
                eligibleGender: event.eligibleGender,
                eligibleLevelFrom: event.eligibleLevel?.from ?? '',
                eligibleLevelTo: event.eligibleLevel?.to ?? '',
                isAllEligibleLevel:
                    event.eligibleLevel && event.eligibleLevel?.from === 0 && event.eligibleLevel.to === 1000,
                isReqRegister: event.isReqRegister ?? true,
                canRegMultiWeeks: event.canRegMultiWeeks ?? true,
                maxRegCount: event.maxRegCount,
                isRequireEmail: false,
                isFree: event.isFree ?? true,
                price: event.price,
                payBeforeDays: event.payBeforeDays,
                isRequireMsg: event.isRequireMsg ?? true,
                clubMsgStDate: event.clubMsg ? (event.clubMsg as ClubMsg).startDate : undefined,
                clubMsgEtDate: event.clubMsg ? (event.clubMsg as ClubMsg).endDate : undefined,
                clubMsgContent: event.clubMsg ? (event.clubMsg as ClubMsg).content : undefined,
            });
        } catch (error) {
            dispatch(handleHTTPError(error));
        }
    };

    if (!event || !club) return null;

    const save = (data: FormValues) => {
        const {
            start,
            end,
            clubMsgContent,
            clubMsgStDate,
            clubMsgEtDate,
            eligibleLevelFrom,
            eligibleLevelTo,
            isAllEligibleLevel,
            price,
            payBeforeDays,
            ...eventData
        } = data;
        let params: any = {
            _id: event?._id,
            start: moment(start).format('YYYY-MM-DD HH:mm'),
            end: moment(end).format('YYYY-MM-DD HH:mm'),
            ...eventData,
        };
        if (params.isRequireMsg) {
            params = {
                ...params,
                clubMsg: {
                    startDate: moment(clubMsgStDate).format('YYYY-MM-DD'),
                    endDate: moment(clubMsgStDate).format('YYYY-MM-DD'),
                    content: clubMsgContent,
                },
            };
        }
        if (params.isMatchEvent) {
            params = {
                ...params,
                eligibleLevel: {
                    from: Number(eligibleLevelFrom),
                    to: Number(eligibleLevelTo),
                },
            };
            if (isAllEligibleLevel)
                params = {
                    ...params,
                    eligibleLevel: {
                        from: 0,
                        to: 1000,
                    },
                };
        }
        if (!params.isFree) params = { ...params, price, payBeforeDays };
        if (params.isRequireEmail && emailContent) params = { ...params, emailContent };

        dispatch(setCommonLoading(true));
        CmtyEventApi.update(params)
            .then((data) => {
                dispatch(setCommonLoading(false));
                dispatch(showAlert({ type: 'success', message: data.message }));
                navigate('/advocate-events');
            })
            .catch((error) => {
                dispatch(setCommonLoading(false));
                dispatch(handleHTTPError(error));
            });
    };

    const setEmailData = (value: { emailContent?: string }) => {
        setEmailContent(value.emailContent);
        if (!value.emailContent) setValue('isRequireEmail', false);
    };

    const getWatchValues = (): FormValues => {
        return watchFields.reduce((object, field, index) => {
            return { ...object, [field]: watchValues[index] };
        }, {}) as FormValues;
    };

    const getEventTypeOptions = () => {
        return systemSetting.cmtyEventTypes.map((type) => {
            return { value: type, label: type };
        });
    };

    const getEventVenueOptions = () => {
        return systemSetting.cmtyVenues.map((venue) => {
            return { value: venue, label: venue };
        });
    };

    const getGenderOptions = () => {
        return Object.values(constants.CMTYEVENT_GENDERTYPES).map((value) => {
            return { value, label: capitalizeString(value) };
        });
    };

    const getAgeOptions = () => {
        return systemSetting.cmtyEventAgeRanges.map((age) => {
            return { value: age.name, label: capitalizeString(age.name) };
        });
    };

    const getLocationOptions = () => {
        return cmtyLocations.map((location) => {
            return { value: location._id, label: location.name };
        });
    };

    const getLocationPhotos = () => {
        const location = cmtyLocations.find((l) => l._id === getWatchValues().location);
        return location?.photos || [];
    };

    const getPayBeforeDaysOption = () => {
        return new Array(6).fill(1).map((v, index) => {
            return { value: `${index + 1}`, label: `${index + 1} days` };
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(save)} className="grid grid-cols-6 gap-6  mt-10" autoComplete="off">
                <TextBox
                    className="col-span-6 lg:col-span-2"
                    name="title"
                    required
                    disabled={!(authorization.title ?? true)}
                    label="Title"
                    placeholder="Social Tennis Together"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <DatePicker
                    className="col-span-6 lg:col-span-2"
                    name="start"
                    required
                    disabled={!(authorization.start ?? true)}
                    label="Start"
                    dateFormat="MMMM dd, yyyy h:mm a"
                    showTimeSelect={true}
                    validation={{
                        control,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <DatePicker
                    className="col-span-6 lg:col-span-2"
                    name="end"
                    required
                    disabled={!(authorization.end ?? true)}
                    label="End"
                    dateFormat="MMMM dd, yyyy h:mm a"
                    showTimeSelect={true}
                    validation={{
                        control,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <TextArea
                    className="col-span-6 lg:col-span-2"
                    name="description"
                    required
                    disabled={!(authorization.description ?? true)}
                    label="Description"
                    placeholder="Community Event Description"
                    inputClassName="h-32"
                    defaultValue=""
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <div className="col-span-6 lg:col-span-2 gap-6 grid grid-cols-1">
                    <Select
                        name="eventType"
                        className="col-span-1"
                        required
                        disabled={!(authorization.eventType ?? true)}
                        label="Event Type"
                        placeholder="...select"
                        options={getEventTypeOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <Select
                        name="venue"
                        className="col-span-1"
                        required
                        disabled={!(authorization.venue ?? true)}
                        label="Event Venue"
                        placeholder="...select"
                        options={getEventVenueOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                </div>
                <div className="col-span-6 lg:col-span-2 gap-6 grid grid-cols-1">
                    <Select
                        name="eligibleGender"
                        className="col-span-1"
                        required
                        disabled={!(authorization.eligibleGender ?? true)}
                        label="Eligible Genders"
                        placeholder="...select"
                        options={getGenderOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <CheckBox
                        name="isMatchEvent"
                        label="Is Match Event?"
                        disabled={!(authorization.isMatchEvent ?? true)}
                        className="col-span-1"
                        defaultChecked={false}
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    {getWatchValues().isMatchEvent && (
                        <div className="col-span-1">
                            <label
                                htmlFor="eligibleLevelFrom"
                                className="block text-sm font-medium text-gray-700 mb-1 text-left"
                            >
                                Eligible Player Level
                                <span className=" text-red-500 ml-1">*</span>
                            </label>
                            <div className=" flex items-center">
                                <TextBox
                                    className="w-100"
                                    name="eligibleLevelFrom"
                                    type="text"
                                    disabled={
                                        (!(authorization.isAllEligibleLevel ?? true) ||
                                            getWatchValues().isAllEligibleLevel) ??
                                        false
                                    }
                                    defaultValue=""
                                    placeholder="5"
                                    validation={{
                                        register,
                                        formState,
                                        rules: {
                                            required: true,
                                        },
                                    }}
                                />
                                <div className=" px-2">~</div>
                                <TextBox
                                    className="w-100"
                                    name="eligibleLevelTo"
                                    type="text"
                                    disabled={
                                        (!(authorization.isAllEligibleLevel ?? true) ||
                                            getWatchValues().isAllEligibleLevel) ??
                                        false
                                    }
                                    defaultValue=""
                                    placeholder="10"
                                    validation={{
                                        register,
                                        formState,
                                        rules: {
                                            required: true,
                                        },
                                    }}
                                />
                                <CheckBox
                                    name="isAllEligibleLevel"
                                    label="All Levels"
                                    disabled={!(authorization.isAllEligibleLevel ?? true)}
                                    className=" min-w-24 ml-4"
                                    validation={{
                                        register,
                                        formState,
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-span-6 lg:col-span-3">
                    <CheckBox
                        name="isReqRegister"
                        label="Do you want members to sign up?"
                        disabled={!(authorization.isReqRegister ?? true)}
                        className="col-span-1 mb-6"
                        defaultChecked={true}
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    {getWatchValues().isReqRegister && (
                        <div className="ml-4 mb-6">
                            <CheckBox
                                name="canRegMultiWeeks"
                                label="The member can sign up for multiple weeks?"
                                disabled={!(authorization.canRegMultiWeeks ?? true)}
                                className="col-span-1 mb-6"
                                defaultChecked={true}
                                validation={{
                                    register,
                                    formState,
                                }}
                            />
                            <Select
                                name="ageRanges"
                                required
                                label="Age Range"
                                disabled={!(authorization.ageRanges ?? true)}
                                placeholder="...select"
                                className="mb-6"
                                options={getAgeOptions()}
                                defaultValue=""
                                isMulti
                                validation={{
                                    control,
                                    formState,
                                    rules: {
                                        required: true,
                                    },
                                }}
                            />
                            <TextBox
                                name="maxRegCount"
                                required
                                disabled={!(authorization.maxRegCount ?? true)}
                                label="Maximum Registration number?"
                                placeholder="100"
                                defaultValue=""
                                type="text"
                                validation={{
                                    register,
                                    formState,
                                    rules: {
                                        required: true,
                                        min: 1,
                                        valueAsNumber: true,
                                    },
                                }}
                            />
                        </div>
                    )}
                    <CheckBox
                        name="isRepeat"
                        label="Do you want to apply for all weekdays after this?"
                        disabled={!(authorization.isRepeat ?? true)}
                        className="mb-6"
                        defaultChecked={false}
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    {getWatchValues().isRepeat && (
                        <div className="ml-4 mb-6">
                            <CheckBox
                                name="noExpired"
                                label="Continue Event Every Week?"
                                disabled={!(authorization.noExpired ?? true)}
                                className="mb-6"
                                // defaultChecked={true}
                                validation={{
                                    register,
                                    formState,
                                }}
                            />
                            {!getWatchValues().noExpired && (
                                <>
                                    <TextBox
                                        name="repeatWeeks"
                                        required
                                        label="How many weeks do you want to repeat updating or canceling?"
                                        disabled={!(authorization.repeatWeeks ?? true)}
                                        labelClassName="text-xs"
                                        placeholder="52"
                                        defaultValue=""
                                        type="text"
                                        validation={{
                                            register,
                                            formState,
                                            rules: {
                                                required: true,
                                                min: 1,
                                                max: 52,
                                                valueAsNumber: true,
                                            },
                                        }}
                                    />
                                    <small className="text-emerald-600 mb-6">
                                        (Expiration:{' '}
                                        {moment(getWatchValues().start)
                                            .add(getWatchValues().repeatWeeks - 1, 'weeks')
                                            .format('MMM, DD YYYY')}
                                        ) (min: 1)
                                    </small>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="col-span-6 lg:col-span-3">
                    <CheckBox
                        name="isFree"
                        label="Free Event"
                        disabled={!(authorization.price ?? false)}
                        className="mb-4"
                        defaultChecked={false}
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    {!getWatchValues().isFree ? (
                        <div className="mb-6 grid grid-cols-2 gap-x-6">
                            <TextBox
                                name="price"
                                required
                                label="Price ($)"
                                disabled={!(authorization.price ?? false)}
                                className=" col-span-1"
                                placeholder="15.00"
                                defaultValue=""
                                type="text"
                                validation={{
                                    register,
                                    formState,
                                    rules: {
                                        required: true,
                                        min: 1,
                                        valueAsNumber: true,
                                    },
                                }}
                            />
                            <Select
                                name="payBeforeDays"
                                className="col-span-1"
                                required
                                disabled={!(authorization.payBeforeDays ?? false)}
                                label={`Pay by (
                                    ${moment(getWatchValues().start)
                                        .subtract(getWatchValues().payBeforeDays, 'days')
                                        .format('DD MMM YYYY')}
                                    )`}
                                placeholder="...select"
                                options={getPayBeforeDaysOption()}
                                defaultValue="3"
                                validation={{
                                    control,
                                    formState,
                                    rules: {
                                        required: true,
                                    },
                                }}
                            />
                        </div>
                    ) : (
                        <p className=" text-red-500 text-sm text-center mb-6">
                            An additional charge of 0.5 USD may be incurred for a free event. and this will be handled
                            by invoice every month.
                        </p>
                    )}
                    {getWatchValues().venue === constants.VIRTUAL_CMTYEVENT_VENUE ? (
                        <TextBox
                            className="mb-6"
                            name="url"
                            required
                            disabled={!(authorization.location ?? true)}
                            label="Reference URL"
                            placeholder="https://e-sports.com/social-event/signup"
                            defaultValue=""
                            type="text"
                            validation={{
                                register,
                                formState,
                                rules: {
                                    required: true,
                                },
                            }}
                        />
                    ) : (
                        <>
                            <Select
                                name="location"
                                className="mb-6"
                                required
                                disabled={!(authorization.location ?? true)}
                                label="Location"
                                placeholder="...select"
                                options={getLocationOptions()}
                                defaultValue=""
                                validation={{
                                    control,
                                    formState,
                                    rules: {
                                        required: true,
                                    },
                                }}
                            />
                            <CmtyEventPhotos photos={getLocationPhotos()} className="mb-6" />
                        </>
                    )}
                    <CheckBox
                        name="isRequireEmail"
                        label="Do you want to broadcast the event?"
                        disabled={!(authorization.isRequireEmail ?? true)}
                        className="mb-6"
                        defaultChecked={false}
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    <CheckBox
                        name="isRequireMsg"
                        label="Do you want to post a message about this event?"
                        disabled={!(authorization.isRequireMsg ?? true)}
                        className="mb-6"
                        defaultChecked={false}
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    {getWatchValues().isRequireMsg && (
                        <div className="border p-4">
                            <div className="grid grid-cols-2 gap-6">
                                <DatePicker
                                    className="col-span-1"
                                    name="clubMsgStDate"
                                    required
                                    disabled={!(authorization.isRequireMsg ?? true)}
                                    label="Display Date"
                                    dateFormat="MMMM dd, yyyy"
                                    validation={{
                                        control,
                                        formState,
                                        rules: {
                                            required: true,
                                        },
                                    }}
                                />
                                <DatePicker
                                    className="col-span-1"
                                    name="clubMsgEtDate"
                                    required
                                    disabled={!(authorization.isRequireMsg ?? true)}
                                    label="Expire Date"
                                    dateFormat="MMMM dd, yyyy"
                                    validation={{
                                        control,
                                        formState,
                                        rules: {
                                            required: true,
                                        },
                                    }}
                                />
                                <TextArea
                                    className="col-span-2"
                                    name="clubMsgContent"
                                    required
                                    disabled={!(authorization.isRequireMsg ?? true)}
                                    label="Message"
                                    placeholder="Please input clu message"
                                    inputClassName="h-32"
                                    defaultValue=""
                                    validation={{
                                        register,
                                        formState,
                                        rules: {
                                            required: true,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-span-6 flex justify-end">
                    <Button className=" w-36" type="submit">
                        Save
                    </Button>
                </div>
            </form>
            <EmailOptionModel
                event={event}
                isOpen={isOpenEmailOption}
                toggle={() => setIsOpenEmailOption(!isOpenEmailOption)}
                callback={setEmailData}
            />
        </>
    );
};

export default CmtyEventContent;
