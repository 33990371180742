import Modal from 'components/utils/Modal';
import { useCallback, useEffect, useState } from 'react';
import { CmtyEvent, CmtyEventRegUser, CmtyEventRegUserModel, User } from 'utils/types';
import { useAppDispatch } from 'store/hook';
import { CmtyEventApi } from 'apis';
import { handleHTTPError } from 'store/error';
import constants from 'utils/constants';

type PropsType = {
    event: CmtyEvent;
    isOpen: boolean;
    toggle: () => void;
};

const CmtyEventRegUsersModal = ({ event, isOpen, toggle }: PropsType) => {
    const dispatch = useAppDispatch();
    const [regUsers, setRegUsers] = useState<CmtyEventRegUser[]>([]);

    const fetchRegUsers = useCallback(() => {
        const params = {
            event: event._id,
            'status.ne': constants.CMTYEVENT_REGUSER_STATUS.CANCELED,
        };
        CmtyEventApi.retrieveRegUsers(params)
            .then((data) => {
                const regUsers = ((data.data as CmtyEventRegUserModel[]) || []).map(
                    (regUser) => new CmtyEventRegUser(regUser)
                );
                setRegUsers(regUsers);
            })
            .catch((error) => {
                dispatch(handleHTTPError(error));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    useEffect(() => {
        if (isOpen) fetchRegUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="sm:max-w-lg" title="Registered Users" headBorder disableOutClick>
            <div className="max-h-96 overflow-auto">
                <div className="p-5 grid grid-cols-1 md:grid-cols-2 gap-2">
                    {regUsers.map((regUser) => {
                        const rgUser = regUser.user as User;
                        return (
                            <div
                                className="flex items-center p-1 border border-gray-300 rounded-sm col-span-1"
                                key={regUser._id}
                            >
                                <img
                                    src={rgUser.photoUrl}
                                    alt="user profile"
                                    className=" w-10 h-10 border border-gray-300 rounded-full object-cover"
                                />
                                <div className="ml-2 text-sm">{rgUser.fullName}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
};

export default CmtyEventRegUsersModal;
