import { useLocation, useNavigate } from 'react-router';
import QueryString from 'query-string';
import { useForm } from 'react-hook-form';
// import QRCode from 'qrcode.react';
import QRCode from 'react-qr-code';
import { AuthApi, UploadApi } from 'apis';
import Button from 'components/form-controls/Button';
import Select from 'components/form-controls/Select';
import TextBox from 'components/form-controls/TextBox';
import { useRef } from 'react';
import { logout, putUser } from 'store/auth';
import { handleHTTPError } from 'store/error';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setCommonLoading, showAlert, showAlertModal } from 'store/ui';
import constants from 'utils/constants';
import { User, UserModel } from 'utils/types';
import { classNames } from 'utils';

type FormValues = {
    firstName: string;
    lastName: string;
    gender: string;
    email: string;
    phoneNumber?: string;
    zipCode: string;
    birthYear: string;
    // gameLevel?: number;
    // levelType: string;
    favoriteClubs: string[];
};

const PersionalInfo = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const clubs = useAppSelector((state) => state.club.clubs);
    const location = useLocation();
    const navigate = useNavigate();
    const photoRef = useRef<HTMLInputElement>(null);

    const { register, formState, handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            gender: String(user?.gender),
            email: user?.email || '',
            phoneNumber: user?.phoneNumber || '',
            zipCode: user?.zipCode || '',
            birthYear: user?.birthYear,
            // gameLevel: user?.gameLevel,
            // levelType: String(user?.levelType),
            favoriteClubs: user?.favoriteClubs,
        },
    });

    const changePhoto = async (e: any) => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            dispatch(setCommonLoading(true));
            UploadApi.uploadFile({ file, type: 'profile' })
                .then((data) => {
                    const photoUrl = data.data as string;
                    AuthApi.update({ _id: user?._id, photoUrl }).then((data) => {
                        dispatch(setCommonLoading(false));
                        dispatch(putUser(new User(data.data as UserModel)));
                    });
                })
                .catch((error) => {
                    dispatch(setCommonLoading(false));
                    dispatch(handleHTTPError(error));
                });
        }
    };

    const save = (data: FormValues) => {
        const { redirect } = QueryString.parse(location.search) as { redirect?: string };
        const params = {
            _id: user?._id,
            ...data,
            gender: Number(data.gender),
            // levelType: Number(data.levelType),
        };
        dispatch(setCommonLoading(true));
        AuthApi.update(params)
            .then((data) => {
                dispatch(setCommonLoading(false));
                dispatch(putUser(new User(data.data as UserModel)));
                dispatch(showAlert({ type: 'success', message: 'Profile has been saved.' }));
                if (redirect) navigate(redirect);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setCommonLoading(false));
                dispatch(handleHTTPError(error));
            });
    };

    const deleteAccount = () => {
        dispatch(
            showAlertModal({
                type: 'warning',
                title: 'Delete Account',
                message: 'Do you want to delete account? \n Are you sure?',
                buttons: [
                    {
                        type: 'ok',
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        type: 'normal',
                        label: 'No',
                        value: 'no',
                    },
                ],
                handler: (value: string) => {
                    if (value === 'no') return;
                    const params = {
                        _id: user?._id,
                    };
                    dispatch(setCommonLoading(true));
                    AuthApi.delete(params)
                        .then((data) => {
                            dispatch(setCommonLoading(false));
                            dispatch(showAlert({ type: 'success', message: data.message }));
                            dispatch(logout());
                        })
                        .catch((error) => {
                            dispatch(setCommonLoading(false));
                            dispatch(handleHTTPError(error));
                        });
                },
            })
        );
    };

    const qrCodeDownload = () => {
        const canvas = document.querySelector('.qrcode > canvas') as HTMLCanvasElement;
        require('downloadjs')(canvas?.toDataURL(), `${user?.fullName}_QR.png`, 'image/png');
    };

    // const getLevelTypeOptions = () => {
    //     return Object.entries(constants.LEVEL_TYPE).map(([key, value]) => {
    //         return { value: `${value}`, label: key };
    //     });
    // };

    const getGenderOptions = () => {
        return [
            {
                value: `${constants.GENDER.MALE}`,
                label: 'Male',
            },
            {
                value: `${constants.GENDER.FEMALE}`,
                label: 'Female',
            },
        ];
    };

    const getClubOptions = () => {
        return clubs.map((club) => {
            return { value: club._id, label: club.displayName };
        });
    };

    return (
        <form
            className={classNames('border-b pb-6', (user?.originClubs || []).length === 0 ? 'pt-6' : '')}
            onSubmit={handleSubmit(save)}
            autoComplete="off"
        >
            <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 md:col-span-1 flex flex-col">
                    <h5 className="text-xl text-gray-800">Persional Information</h5>
                    <p className="text-gray-500 text-sm mb-4">Use a permanent address where you can receive mail</p>
                    <div className="flex-grow flex justify-center items-center">
                        <div className="qrcode">
                            <QRCode
                                value={JSON.stringify({
                                    _id: user?._id,
                                    fullName: user?.fullName,
                                    email: user?.email,
                                    phoneNumber: user?.phoneNumber,
                                })}
                                level="L"
                                size={200}
                            />
                            {/* <QRCode
                                value={JSON.stringify({
                                    _id: user?._id,
                                    fullName: user?.fullName,
                                    email: user?.email,
                                    phoneNumber: user?.phoneNumber,
                                })}
                                level="L"
                                size={200}
                            /> */}
                            <p
                                className="mt-4 text-emerald-600 hover:text-emerald-700 underline cursor-pointer text-center"
                                onClick={qrCodeDownload}
                            >
                                Download
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-white col-span-3 md:col-span-2 rounded-md shadow grid grid-cols-6 gap-6 p-4 md:p-10">
                    <div className="col-span-6 grid grid-cols-2 gap-6">
                        <div className="col-span-2 md:col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Photo</label>
                            <div className="mt-1 flex items-center">
                                <span className="inline-block h-20 w-20 rounded-full overflow-hidden bg-gray-100 border mr-3">
                                    {user?.photoUrl ? (
                                        <img
                                            src={user?.photoUrl}
                                            alt="sidebar-logo"
                                            className=" w-20 h-20 mx-auto border border-gray-300 mb-2 rounded-full"
                                        />
                                    ) : (
                                        <svg
                                            className="h-full w-full text-gray-300"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    )}
                                </span>
                                <Button
                                    textColor="text-gray-700"
                                    className="border border-gray-300"
                                    color="bg-white"
                                    hoverColor="bg-gray-50"
                                    onClick={() => {
                                        if (photoRef.current) photoRef.current.click();
                                    }}
                                >
                                    Change
                                </Button>
                            </div>
                            <input type="file" ref={photoRef} className=" hidden" onChange={changePhoto} />
                        </div>
                        <Select
                            className="col-span-2 md:col-span-1"
                            name="favoriteClubs"
                            label="Favorite Organizations"
                            placeholder="Please select ..."
                            isMulti
                            options={getClubOptions()}
                            defaultValue={[]}
                            validation={{
                                control,
                                formState,
                            }}
                        />
                    </div>
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="firstName"
                        required
                        label="First Name"
                        placeholder="John"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="lastName"
                        required
                        label="Last Name"
                        placeholder="Michael"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="email"
                        required
                        label="Email Address"
                        placeholder="john@mail.com"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                                pattern:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            },
                        }}
                    />
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="phoneNumber"
                        label="Phone Number"
                        placeholder="4578963258"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    <Select
                        className="col-span-6 md:col-span-3"
                        name="gender"
                        required
                        label="Gender"
                        placeholder="...select"
                        options={getGenderOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="zipCode"
                        required
                        label="Zip Code"
                        placeholder="94568"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                                minLength: 5,
                                maxLength: 5,
                            },
                        }}
                    />
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="birthYear"
                        required
                        label="Year of Birth"
                        placeholder="1986"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                                minLength: 4,
                                maxLength: 4,
                            },
                        }}
                    />
                    {/* <Select
                        className="col-span-6 md:col-span-3"
                        name="levelType"
                        required
                        label="Level Type"
                        placeholder="...select"
                        options={getLevelTypeOptions()}
                        defaultValue=""
                        validation={{
                            control,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="gameLevel"
                        required
                        label="Game Level (For competitive level rating) (Max: 14.0)"
                        placeholder="4.5"
                        defaultValue=""
                        step={0.1}
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                                min: 1,
                                max: 14,
                                valueAsNumber: true,
                            },
                        }}
                    /> */}
                    <div className="col-span-6 flex justify-between mt-5 ">
                        <Button className=" w-36" color="bg-red-600 hover:bg-red-700" onClick={deleteAccount}>
                            Delete Account
                        </Button>
                        <Button className=" w-36" type="submit">
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default PersionalInfo;
