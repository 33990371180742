import { BellIcon, CogIcon, MenuIcon } from '@heroicons/react/outline';
import { useAppDispatch } from 'store/hook';
import { toggleSideBar } from 'store/ui';

const Header = () => {
    const dispatch = useAppDispatch();
    return (
        <div className="fixed top-0 left-0 w-full z-10 px-6 md:px-10 h-16 bg-white border-b flex justify-between items-center">
            <div
                className="cursor-pointer"
                onClick={() => {
                    dispatch(toggleSideBar());
                }}
            >
                <MenuIcon className="w-6 h-6 text-gray-600" />
            </div>
            <div className="flex">
                <BellIcon className="w-6 h-6 text-gray-600 mr-6" />
                <CogIcon className="w-6 h-6 text-gray-600" />
            </div>
        </div>
    );
};

export default Header;
