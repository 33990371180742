const apiUrl = {
    BASE_URL: process.env.REACT_APP_SERVER_URL,

    REFRESH_TOKEN_URL: '/api/auth/refresh-token',

    USER_URL: '/api/user',
    LOGIN_URL: '/api/user/login',
    REGISTER_URL: '/api/user/signup',
    VERIFY_URL: '/api/user/verify',
    UPDATEPROFILE_URL: '/api/user/update',
    SEARCH_SPONSORS_URL: '/api/user/search_sponsors',
    SEARCH_MEMBERS_URL: '/api/user/search',
    SUBACCOUNTS_URL: '/api/user/subaccounts',

    SEARCH_CLUB_URL: '/api/club/search',
    REGISTER_CLUB_URL: '/api/club/register',
    GET_CLUB_URL: '/api/club',
    GET_SERVICEDETAILS_URL: '/api/club/details',

    REPORT_URL: '/api/report/create',

    GET_SYSMANAGE_URL: '/api/sysmanage/get',

    CMTY_EVENT_URL: '/api/cmtyevent',
    CMTY_EVENT_IDEA_URL: '/api/cmtyevent-idea',
    IDEA_QUOTE_URL: '/api/idea-quote',
    CMTY_EVENT_REGUSER_URL: '/api/cmtyevent-reguser',
    CMTY_EVENT_LOCATION_URL: '/api/cmtyevent-location',

    INVOICE_URL: '/api/invoice',

    PAYMENT_URL: '/api/payment',
    STRIPE_URL: '/api/stripe',

    GEOLOCATION_URL: '/api/geolocation',

    UPLOAD_FILE_URL: '/api/upload/general',
};

export default apiUrl;
