import ReactTooltip from 'react-tooltip';
import { classNames } from 'utils';
import { User } from 'utils/types';

const LikeUserPhoto = ({ className, size, user }: { className?: string; size?: number; user: User }) => {
    return (
        <div className={classNames('flex-shrink-0 cursor-pointer', `h-${size || 6} w-${size || 6}`, className || '')}>
            {user.photoUrl ? (
                <img
                    src={user.photoUrl}
                    data-for={user._id}
                    data-tip
                    className="h-full w-full rounded-full border-gray-300 border relative"
                    alt="club logo"
                />
            ) : (
                <div
                    data-for={user._id}
                    data-tip
                    className="rounded-full w-full h-full border border-gray-400 bg-gray-300 flex justify-center items-center"
                >
                    {user.fullName[0].toUpperCase()}
                </div>
            )}
            <ReactTooltip id={user._id} effect="solid">
                <span className="text-xs text-right">{user.fullName}</span>
            </ReactTooltip>
        </div>
    );
};

export default LikeUserPhoto;
