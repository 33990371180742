import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
    children?: ReactNode;
}

const ContentWrapper = ({ children }: { children: ReactNode }) => {
    return <div className="max-w-7xl mx-auto pt-[90px] pb-20 px-4 xl:px-0 bg-white">{children}</div>;
};

const BaseLayout: FC<LayoutProps> = () => {
    return (
        <>
            <Header />
            <ContentWrapper>{<Outlet />}</ContentWrapper>
            <Footer />
        </>
    );
};

export default BaseLayout;
