import constants from 'utils/constants';
import { ClubModel } from './club';

export type UserModel = {
    _id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    gender?: number;
    email: string;
    phoneNumber?: string;
    zipCode?: string;
    photoUrl?: string;
    birthYear?: string;
    age?: number;
    club?: ClubModel | string;
    parent?: string;
    tempClub?: {
        name: string;
        phoneNumber?: string;
    };
    originClubs?: string[];
    isReceiveEmail?: boolean;
    isRecevieNotification?: boolean;
    gameLevel?: number;
    levelType?: number;
    isCmtyAdvocator?: boolean;
    isActivated?: boolean;
    isCmtyEventUser?: boolean;
    advocateClubs?: string[];
    favoriteClubs?: string[];
    stripeId?: string;
};

export class User {
    _id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    gender?: number;
    email: string;
    phoneNumber?: string;
    zipCode?: string;
    photoUrl: string;
    birthYear?: string;
    age?: number;
    club: ClubModel | string;
    parent?: string;
    tempClub?: {
        name: string;
        phoneNumber?: string;
    };
    originClubs: string[];
    isReceiveEmail: boolean;
    isRecevieNotification: boolean;
    gameLevel: number;
    levelType: number;
    isCmtyAdvocator: boolean;
    isActivated: boolean;
    isCmtyEventUser: boolean;
    advocateClubs: string[];
    favoriteClubs?: string[];
    stripeId?: string;

    constructor({
        _id,
        firstName,
        lastName,
        fullName,
        gender,
        email,
        phoneNumber,
        zipCode,
        photoUrl,
        birthYear,
        age,
        club,
        parent,
        tempClub,
        originClubs,
        isReceiveEmail,
        isRecevieNotification,
        gameLevel,
        levelType,
        isCmtyAdvocator,
        isActivated,
        isCmtyEventUser,
        advocateClubs,
        stripeId,
        favoriteClubs,
    }: UserModel) {
        this._id = _id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.fullName = fullName;
        this.gender = gender;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.zipCode = zipCode;
        this.photoUrl = photoUrl || constants.DEFAULT_PHOTO;
        this.birthYear = birthYear;
        this.age = age;
        this.club = club || '';
        this.parent = parent;
        this.tempClub = tempClub;
        this.originClubs = originClubs || [];
        this.isReceiveEmail = isReceiveEmail ?? true;
        this.isRecevieNotification = isRecevieNotification ?? true;
        this.gameLevel = gameLevel || 3.5;
        this.levelType = levelType ?? constants.LEVEL_TYPE.SELF;
        this.isCmtyAdvocator = isCmtyAdvocator ?? false;
        this.isActivated = isActivated ?? false;
        this.isCmtyEventUser = isCmtyEventUser ?? false;
        this.advocateClubs = advocateClubs || [];
        this.favoriteClubs = favoriteClubs || [];
        this.stripeId = stripeId;
    }

    getGender = (): string => {
        if (this.gender === constants.GENDER.MALE) return 'Male';
        if (this.gender === constants.GENDER.FEMALE) return 'Female';
        return 'No Prefer ';
    };

    getGameLevel = (): string => {
        if (!this.gameLevel) return '';
        const levelType = Object.entries(constants.LEVEL_TYPE).find(([_, value]) => value === this.levelType);
        if (!levelType) return '';
        return `${levelType[0]} ${this.gameLevel}`;
    };

    getZipCodePrefix = (): string => {
        if (!this.zipCode) return '';
        return this.zipCode.slice(0, 2);
    };

    isOriginClub = (clubId: string): boolean => {
        return this.originClubs.includes(clubId);
    };

    isAdvocateClub = (clubId: string): boolean => {
        return this.advocateClubs.includes(clubId);
    };
}
