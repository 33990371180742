import constants from 'utils/constants';
import { AutoMatchGameTypes } from '.';

type Props = {
    group: any[];
    gameType: AutoMatchGameTypes;
};

const MatchGroup = ({ group, gameType }: Props) => {
    return (
        <div className="border rounded-sm p-3 text-sm">
            {gameType === AutoMatchGameTypes.Single && (
                <div>
                    <Member member={group[0]} className="mb-2" />
                    {group[1] && <h2 className="text-lg text-center py-2 font-medium text-emerald-600">VS</h2>}
                    {group[1] && <Member member={group[1]} />}
                </div>
            )}
            {gameType === AutoMatchGameTypes.Double &&
                group.map((members, index) => (
                    <div key={Math.random()}>
                        <Member member={members[0]} className="mb-2" />
                        {members[1] && <Member member={members[1]} />}
                        {group.length > 1 && index === 0 && (
                            <h2 className="text-center py-2 font-medium text-emerald-600">VS</h2>
                        )}
                    </div>
                ))}
        </div>
    );
};

const Member = ({ member, className = '' }: { member: any; className?: string }) => {
    if (!member) return null;
    return (
        <div className={className || ''}>
            <p className="font-medium">{member.user?.fullName}</p>
            <div className="ml-2">
                <div>
                    Game Level: <span className="font-medium">{member?.gameLevel}</span>
                </div>
                <div>
                    Gender:{' '}
                    <span className=" font-medium">
                        {member.user?.gender === constants.GENDER.MALE ? 'Male' : 'Female'}
                    </span>
                </div>
                <div>
                    Age: <span className=" font-medium">{member.user?.age}</span>
                </div>
            </div>
        </div>
    );
};

export default MatchGroup;
