import { Fragment, useState, forwardRef, useRef, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { SelectOptionType } from 'utils/types';
import { classNames } from 'utils';

type PropsType = {
    className?: string;
    label?: string | JSX.Element;
    labelClassName?: string;
    required?: boolean;
    inputClassName?: string;
    name: string;
    defaultValue?: string | string[];
    value?: string | string[];
    options: SelectOptionType[];
    disabled?: boolean;
    placeholder?: string;
    isMulti?: boolean;
    onChange?: (value: string | string[]) => void;
    valid?: boolean;
    validation?: any;
};

const Select = ({
    label,
    className = '',
    labelClassName = '',
    required = false,
    name,
    options,
    value,
    disabled,
    onChange,
    defaultValue,
    placeholder,
    isMulti = false,
    validation, // { control, rules}
}: PropsType) => {
    const selectRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (selectRef.current && validation) {
            const errorKeys = Object.keys(validation?.formState?.errors);
            if (errorKeys.length > 0 && errorKeys[0] === name) selectRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation?.formState.submitCount]);

    return (
        <div className={className || ''}>
            {label && (
                <>
                    {typeof label === 'string' ? (
                        <label
                            htmlFor={name}
                            className={classNames(
                                'block text-sm font-medium text-gray-700 mb-1 text-left',
                                labelClassName || ''
                            )}
                        >
                            {label}
                            {required && <span className=" text-red-500 ml-1">*</span>}
                        </label>
                    ) : (
                        <>{label}</>
                    )}
                </>
            )}
            {!isMulti ? (
                <>
                    {validation?.control ? (
                        <Controller
                            name={name}
                            control={validation.control}
                            rules={validation.rules}
                            render={({ field: { ref, ...rest }, formState }) => {
                                return (
                                    <SingleSelect
                                        defaultValue={defaultValue}
                                        options={options}
                                        disabled={disabled}
                                        placeholder={placeholder}
                                        valid={!formState?.errors[name]}
                                        {...rest}
                                        ref={selectRef}
                                    />
                                );
                            }}
                        />
                    ) : (
                        <SingleSelect
                            name={name}
                            className={className}
                            value={value}
                            placeholder={placeholder}
                            options={options}
                            disabled={disabled}
                            onChange={onChange}
                            ref={selectRef}
                        />
                    )}
                </>
            ) : (
                <>
                    {validation?.control ? (
                        <Controller
                            name={name}
                            control={validation.control}
                            rules={validation.rules}
                            render={({ field: { ref, ...rest }, formState }) => (
                                <MultiSelect
                                    defaultValue={defaultValue}
                                    options={options}
                                    disabled={disabled}
                                    placeholder={placeholder}
                                    valid={!formState?.errors[name]}
                                    ref={selectRef}
                                    {...rest}
                                />
                            )}
                        />
                    ) : (
                        <MultiSelect
                            name={name}
                            className={className}
                            value={value}
                            placeholder={placeholder}
                            options={options}
                            disabled={disabled}
                            onChange={onChange}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Select;

const SingleSelect = forwardRef<HTMLButtonElement, PropsType>(
    (
        { name, defaultValue, value, options, disabled, onChange, valid = true, placeholder = 'Select...' }: PropsType,
        ref
    ) => {
        const [selectedValue, selectValue] = useState<string | undefined>(((defaultValue || value) as string) || '');
        useEffect(() => {
            selectValue(value as string);
        }, [value]);

        return (
            <div className="w-full relative">
                <Listbox
                    value={selectedValue || ''}
                    disabled={disabled}
                    onChange={(value) => {
                        selectValue(value);
                        if (onChange) onChange(value);
                    }}
                >
                    <Listbox.Button
                        className={classNames(
                            'bg-white relative w-full min-h-10 border border-gray-300 rounded-md shadow-sm pl-3',
                            'pr-10 py-2 text-left focus:outline-none focus:ring-1 sm:text-sm cursor-pointer',
                            !valid
                                ? 'focus:border-red-500 focus:ring-red-500'
                                : 'focus:border-emerald-600 focus:ring-emerald-600'
                        )}
                        ref={ref}
                    >
                        <span
                            className={`block truncate capitalize ${selectedValue ? 'text-gray-700' : 'text-gray-300'}`}
                        >
                            {options.find((option) => option.value === `${selectedValue}`)?.label || placeholder}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            static
                            className={`w-full absolute mt-1 z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
                        >
                            {options.map((option, index) => (
                                <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'text-white bg-emerald-600' : 'text-gray-900',
                                            'select-none relative py-2 pl-3 pr-9 cursor-pointer'
                                        )
                                    }
                                    value={option.value}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span
                                                className={`${
                                                    active || selected ? 'font-semibold' : 'font-normal'
                                                } block truncate capitalize`}
                                            >
                                                {option.label}
                                            </span>

                                            {selected ? (
                                                <span
                                                    className={`${
                                                        active ? 'text-white' : 'text-emerald-600'
                                                    } absolute inset-y-0 right-0 flex items-center pr-4`}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </Listbox>
            </div>
        );
    }
);

const MultiSelect = forwardRef<HTMLButtonElement, PropsType>(
    ({ defaultValue, value, options, disabled, onChange, valid = true, placeholder = 'Select...' }: PropsType, ref) => {
        const [values, selectValues] = useState<string[]>([]);
        useEffect(() => {
            selectValues((value || []) as string[]);
        }, [value]);

        return (
            <div className="w-full relative">
                <Listbox
                    value={values || []}
                    disabled={disabled}
                    onChange={(val: any) => {
                        if (val && !values.includes(val)) {
                            const newValues = [...values, val];
                            selectValues(newValues);
                            if (onChange) onChange(newValues);
                        }
                    }}
                >
                    <Listbox.Button
                        className={classNames(
                            'bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3',
                            'pr-10 text-left focus:outline-none focus:ring-1 sm:text-sm cursor-pointer min-h-10',
                            !valid
                                ? 'focus:border-red-500 focus:ring-red-500'
                                : 'focus:border-emerald-500 focus:ring-emerald-500'
                        )}
                        ref={ref}
                    >
                        {values.length === 0 ? (
                            <span className="pl-2 block truncate capitalize text-gray-300">{placeholder}</span>
                        ) : (
                            <>
                                {values.map((value) => (
                                    <div key={value + 'selected'} className="py-1 inline-block">
                                        <div className="bg-gray-300 rounded-md  text-gray-800 p-1 mr-1">
                                            {options.find((option) => option.value === value)?.label}
                                            <svg
                                                onClick={(e) => {
                                                    const newValues = values.filter((v) => v !== value);
                                                    selectValues(newValues);
                                                    if (onChange) onChange(newValues);
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                className=" ml-2 h-4 w-4 inline-block"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            static
                            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                            {options &&
                                options.map((option, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-emerald-600' : 'text-gray-900',
                                                'select-none relative py-2 pl-3 pr-9 cursor-pointer'
                                            )
                                        }
                                        value={option.value}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`${
                                                        selected || values.some((v) => option.value === v)
                                                            ? 'font-semibold'
                                                            : 'font-normal'
                                                    } block truncate`}
                                                >
                                                    {option.label}
                                                </span>

                                                {values.some((v) => option.value === v) ? (
                                                    <span className="text-emerald-600 absolute inset-y-0 right-0 flex items-center pr-4">
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                        </Listbox.Options>
                    </Transition>
                </Listbox>
            </div>
        );
    }
);
