import Modal from 'components/utils/Modal';
import { Invoice } from 'utils/types/models/invoice';
import { useForm } from 'react-hook-form';
import TextArea from 'components/form-controls/TextArea';
import Button from 'components/form-controls/Button';
import { InvoiceApi } from 'apis';
import { useAppDispatch } from 'store/hook';
import { handleHTTPError } from 'store/error';
import { useState } from 'react';
import { showAlert } from 'store/ui';

type PropsType = {
    invoice: Invoice;
    isOpen: boolean;
    toggle: () => void;
    callback: () => void;
};

type FormValues = {
    note: string;
};

const InvoiceSubmitModal = ({ invoice, isOpen, toggle, callback }: PropsType) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const { formState, handleSubmit, register } = useForm<FormValues>({
        defaultValues: { note: '' },
    });
    const sendInvoice = (data: FormValues) => {
        const params = { _id: invoice._id, note: data.note };
        setLoading(true);
        InvoiceApi.request(params)
            .then((data) => {
                setLoading(false);
                dispatch(showAlert({ type: 'success', message: data.message }));
                toggle();
                callback();
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="sm:max-w-lg" title="Send Invoice" headBorder disableOutClick>
            <div className="max-h-96 overflow-auto">
                <form className="p-5" onSubmit={handleSubmit(sendInvoice)}>
                    <div className=" text-red-600 text-xs mb-2 text-center">
                        Currently, We don't have an auto payment system. so Please describe your payment account and the
                        method you want to get paid in the message.
                    </div>
                    <TextArea
                        name="note"
                        required
                        label="Message"
                        placeholder="message here"
                        inputClassName="h-32"
                        defaultValue=""
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <div className="flex justify-end mt-5">
                        <Button type="submit" className="bg-emerald-600 hover:bg-emerald-700" loading={loading}>
                            Send Invoice
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default InvoiceSubmitModal;
