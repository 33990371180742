import { useAppDispatch, useAppSelector } from 'store/hook';
import { useForm } from 'react-hook-form';
import { showAlert, toggleLoginModal, toggleRegModal } from 'store/ui';
import { AuthApi } from 'apis';
import Modal from 'components/utils/Modal';
import TextBox from 'components/form-controls/TextBox';
import Button from 'components/form-controls/Button';
import { useEffect, useState } from 'react';
import { login } from 'store/auth';
import { User, UserModel } from 'utils/types';
import { handleHTTPError } from 'store/error';
import { useLocation, useNavigate } from 'react-router';

type FormValues = {
    user: string;
};

const LoginModal = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isOpen = useAppSelector((state) => state.ui.isOpenLoginModal);
    const cmtyClub = useAppSelector((state) => state.club.cmtyClub);
    const { register, formState, handleSubmit, reset } = useForm<FormValues>({
        defaultValues: { user: '' },
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isOpen && reset) reset({ user: '' });
    }, [isOpen, reset]);

    const toggle = () => {
        dispatch(toggleLoginModal());
    };

    const submit = (data: FormValues) => {
        if (loading) return;
        const params = { user: data.user, club: cmtyClub?._id };
        setLoading(true);
        AuthApi.login(params)
            .then((data) => {
                setLoading(false);
                const { user, token, refreshToken } = data;
                dispatch(login({ user: new User(user as UserModel), token, refreshToken }));
                dispatch(toggleLoginModal());
                dispatch(showAlert({ type: 'success', message: 'Login Success.' }));
                if (location.pathname === '/') navigate('/cmtyevents');
            })
            .catch((error) => {
                console.log('error', error);
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="sm:max-w-md">
            <div className="flex justify-center -mt-4">
                <img src="/logo256.png" className=" w-24 h-24" alt="logo256" />
            </div>
            <h1 className="text-2xl tracking-wider text-gray-700 text-center font-bold mb-10">LOGIN</h1>
            <form className="px-12 pb-16" onSubmit={handleSubmit(submit)} autoComplete="off">
                <TextBox
                    className="w-100 mb-3"
                    name="user"
                    label="Email or Phone Number"
                    labelClassName="mb-2"
                    placeholder="john@mail.com or 4578956212"
                    defaultValue=""
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <Button className="w-full" type="submit" loading={loading}>
                    Log in
                </Button>
                <p className="text-xs text-center font-light mt-8">Don't you have an account of MCTL?</p>
                <p
                    className="text-emerald-600 hover:text-emerald-700 text-sm text-center cursor-pointer"
                    onClick={() => {
                        dispatch(toggleLoginModal());
                        dispatch(toggleRegModal());
                    }}
                >
                    Register
                </p>
            </form>
        </Modal>
    );
};

export default LoginModal;
