import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Club, SysSetting } from 'utils/types';

type ClubState = {
    cmtyClub: Club | undefined | null;
    ideaDefaultClub: Club | undefined | null;
    clubs: Club[];
    systemSetting: SysSetting;
};

const initialState: ClubState = {
    cmtyClub: null,
    ideaDefaultClub: null,
    clubs: [],
    systemSetting: {
        cmtyEventPercentageFee: 0.15,
        cmtyEventFixedFee: 10,
        cmtyEventIdeaFee: 1,
        zipCodes: [],
        cmtyEventTypes: [],
        cmtyVenues: [],
        cmtyEventAgeRanges: [],
        orgTags: [],
    },
};

const club = createSlice({
    name: 'club',
    initialState,
    reducers: {
        putCmtyClub: (state, action: PayloadAction<Club>) => {
            state.cmtyClub = action.payload;
        },
        putIdeaDefaultClub: (state, action: PayloadAction<Club>) => {
            state.ideaDefaultClub = action.payload;
        },
        putClubs: (state, action: PayloadAction<Club[]>) => {
            state.clubs = action.payload;
        },
        putSysSetting: (state, action: PayloadAction<SysSetting>) => {
            state.systemSetting = action.payload;
        },
    },
});

export const { putCmtyClub, putClubs, putSysSetting, putIdeaDefaultClub } = club.actions;
export default club.reducer;
