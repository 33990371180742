import { classNames } from 'utils';

type PropsType = {
    className?: string;
    label?: string | JSX.Element;
    labelClassName?: string;
    inputClassName?: string;
    name: string;
    defaultValue?: string;
    value?: string | number;
    disabled?: boolean;
    placeholder?: string;
    onFocus?: string;
    onBlur?: string;
    required?: boolean;
    onChange?: (value: string) => void;
    validation?: any;
};

export const TextArea = ({
    className = '',
    label,
    labelClassName = '',
    inputClassName = '',
    name,
    required = false,
    defaultValue,
    value,
    disabled,
    placeholder,
    onFocus,
    onBlur,
    onChange,
    validation,
}: PropsType) => {
    const { register, rules, formState } = validation || {};

    return (
        <div className={className || ''}>
            {label && (
                <>
                    {typeof label === 'string' ? (
                        <label
                            htmlFor={name}
                            className={classNames(
                                'block text-sm font-medium text-gray-700 mb-1 text-left',
                                labelClassName || ''
                            )}
                        >
                            {label}
                            {required && <span className=" text-red-500 ml-1">*</span>}
                        </label>
                    ) : (
                        <>{label}</>
                    )}
                </>
            )}
            <textarea
                type="text"
                id={name}
                name={name}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
                className={classNames(
                    formState?.errors[name]
                        ? 'focus:border-red-500 focus:ring-red-500'
                        : 'focus:border-emerald-600 focus:ring-emerald-600',
                    'block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300 text-gray-700',
                    inputClassName || ''
                )}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={(e) => {
                    if (onChange) onChange(e.target.value);
                }}
                {...(register ? register(name, rules) : {})}
            />
        </div>
    );
};

export default TextArea;
