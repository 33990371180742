import { useAppDispatch, useAppSelector } from 'store/hook';
import { closeAlertModal } from 'store/ui';
import Modal from 'components/utils/Modal';
import Button from 'components/form-controls/Button';
import { AlertModalButton } from 'utils/types';
import { ExclamationIcon, QuestionMarkCircleIcon, ShieldExclamationIcon, ThumbUpIcon } from '@heroicons/react/outline';

const AlertModal = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.ui.isOpenAlertModal);
    const { type, title, message, buttons, data, handler } = useAppSelector((state) => state.ui.alertModal);

    const getButtons = (): AlertModalButton[] => {
        if (!buttons || buttons.length === 0) {
            return [
                {
                    label: 'Ok',
                    value: 'ok',
                    type: 'ok',
                },
            ];
        }
        return buttons;
    };

    // this will display alert always at top on page
    if (!isOpen) return null;

    return (
        <Modal
            isOpen={isOpen}
            header={false}
            toggle={() => {
                dispatch(closeAlertModal());
            }}
            size="sm:max-w-md"
            disableOutClick
        >
            <div className="pt-6 pb-4 px-6 flex items-start">
                {type === 'error' && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ShieldExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                )}
                {type === 'warning' && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-amber-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon className="h-6 w-6 text-amber-600" aria-hidden="true" />
                    </div>
                )}
                {type === 'alert' && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                        <QuestionMarkCircleIcon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                    </div>
                )}
                {(!type || type === 'success') && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ThumbUpIcon className="h-6 w-6 text-emerald-600" aria-hidden="true" />
                    </div>
                )}
                <div className="ml-4">
                    {title && <h3 className="text-lg font-medium text-gray-800 mb-2">{title}</h3>}
                    {typeof message === 'string' ? <p className="text-sm text-gray-500">{message}</p> : { message }}
                </div>
            </div>
            <div className="bg-gray-50 px-6 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-md">
                {getButtons().map((button, index) => (
                    <Button
                        key={index}
                        textColor={button.type === 'normal' ? 'text-gray-700' : ''}
                        color={
                            button.type === 'cancel'
                                ? 'bg-red-500'
                                : button.type === 'normal'
                                ? 'bg-white border border-gray-300'
                                : ''
                        }
                        hoverColor={
                            button.type === 'cancel' ? 'bg-red-600' : button.type === 'normal' ? 'bg-gray-50' : ''
                        }
                        className="min-w-24 ml-2"
                        onClick={() => {
                            if (handler) handler(button.value, data);
                            dispatch(closeAlertModal());
                        }}
                    >
                        {button.label}
                    </Button>
                ))}
            </div>
        </Modal>
    );
};

export default AlertModal;
