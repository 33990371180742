import { forwardRef, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { classNames } from 'utils';
import { EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import './module.scss';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';

type PropsType = {
    className?: string;
    label?: string | JSX.Element;
    labelClassName?: string;
    required?: boolean;
    inputClassName?: string;
    name: string;
    defaultValue?: string;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    onBlur?(e: SyntheticEvent): void;
    onFocus?(e: SyntheticEvent): void;
    onChange?: (value: string | string[]) => void;
    valid?: boolean;
    validation?: any;
};

const RichEdit = ({
    label,
    className = '',
    labelClassName = '',
    required = false,
    name,
    value,
    disabled,
    onChange,
    defaultValue,
    placeholder,
    validation, // { control, rules, formState}
}: PropsType) => {
    const { control, rules, formState } = validation || {};
    const selectRef = useRef<Editor>(null);
    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
        if (selectRef.current && validation) {
            const errorKeys = Object.keys(formState?.errors);
            if (errorKeys.length > 0 && errorKeys[0] === name) {
                selectRef.current.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.submitCount]);

    const onBlur = () => {
        setHasFocus(false);
    };
    const onFocus = () => {
        setHasFocus(true);
    };

    return (
        <div className={className || ''}>
            {label && (
                <>
                    {typeof label === 'string' ? (
                        <label
                            htmlFor={name}
                            className={classNames(
                                'block text-sm font-medium text-gray-700 mb-1 text-left',
                                labelClassName || ''
                            )}
                        >
                            {label}
                            {required && <span className=" text-red-500 ml-1">*</span>}
                        </label>
                    ) : (
                        <>{label}</>
                    )}
                </>
            )}

            {validation?.control ? (
                <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field }) => (
                        <DraftEdit
                            name={name}
                            onChange={field.onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            value={field.value || ''}
                            placeholder={placeholder}
                            disabled={disabled}
                            ref={selectRef}
                            className={classNames(
                                'block w-full border shadow-sm text-gray-700 border-gray-300 rounded-md box-border',
                                formState?.errors[name]
                                    ? hasFocus
                                        ? 'border-red-500 shadow-[0px_0px_0px_1px_#ef4444]'
                                        : 'border-gray-300'
                                    : hasFocus
                                    ? 'border-emerald-600 shadow-[0px_0px_0px_1px_#059669]'
                                    : 'border-gray-300'
                            )}
                        />
                    )}
                />
            ) : (
                <DraftEdit
                    name={name}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    className="w-full border shadow-sm text-gray-700 border-gray-300 rounded-md"
                    placeholder={placeholder}
                />
            )}
        </div>
    );
};

export default RichEdit;

const DraftEdit = forwardRef<Editor, PropsType>(
    ({ className = '', value, disabled, onChange, onBlur, onFocus, defaultValue, placeholder }, ref) => {
        const [isInit, setIsInist] = useState(true);
        const [editorState, setEditorState] = useState<EditorState>(
            EditorState.createWithContent(stateFromHTML(value || defaultValue || ''))
        );
        const [{ plugins, Toolbar }] = useState(() => {
            const toolbarPlugin = createToolbarPlugin();
            const { Toolbar } = toolbarPlugin;
            const plugins = [toolbarPlugin];
            return {
                plugins,
                Toolbar,
            };
        });

        useEffect(() => {
            if (value && isInit) {
                setEditorState(EditorState.createWithContent(stateFromHTML(value)));
                setIsInist(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        const onChangeEditor = (es: EditorState) => {
            setEditorState(es);
        };

        const onEditorBlur = (e: any) => {
            if (onChange) onChange(stateToHTML(editorState.getCurrentContent()));
            if (onBlur) onBlur(e);
        };
        return (
            <div className={classNames(className, 'p-2')}>
                <Editor
                    ref={ref}
                    readOnly={disabled}
                    onBlur={onEditorBlur}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    editorState={editorState}
                    onChange={onChangeEditor}
                    plugins={plugins}
                />
                <Toolbar />
            </div>
        );
    }
);
