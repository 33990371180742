import { classNames } from 'utils';

type PropsType = {
    className?: string;
    label?: string | JSX.Element;
    labelClassName?: string;
    inputClassName?: string;
    name: string;
    type?: string;
    step?: number | string;
    defaultValue?: string;
    value?: string | number;
    disabled?: boolean;
    placeholder?: string;
    onFocus?: string;
    onBlur?: string;
    required?: boolean;
    autoComplete?: string;
    onChange?: (value: string) => void;
    validation?: any;
};

export const TextBox = ({
    className = '',
    label,
    labelClassName = '',
    inputClassName = '',
    name,
    type,
    step = 'any',
    required = false,
    defaultValue,
    value,
    disabled,
    placeholder,
    onFocus,
    onBlur,
    onChange,
    autoComplete,
    validation,
}: PropsType) => {
    const { register, rules, formState } = validation || {};

    return (
        <div className={className || ''}>
            {label && (
                <>
                    {typeof label === 'string' ? (
                        <label
                            htmlFor={name}
                            className={classNames(
                                'block text-sm font-medium text-gray-700 mb-1 text-left',
                                labelClassName || ''
                            )}
                        >
                            {label}
                            {required && <span className=" text-red-500 ml-1">*</span>}
                        </label>
                    ) : (
                        <>{label}</>
                    )}
                </>
            )}
            <input
                type={type || 'text'}
                step={step}
                id={name}
                name={name}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
                className={classNames(
                    formState?.errors[name]
                        ? 'focus:border-red-500 focus:ring-red-500'
                        : 'focus:border-emerald-600 focus:ring-emerald-600',
                    'block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300 h-10 text-gray-700',
                    inputClassName || ''
                )}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete={autoComplete || 'off'}
                onChange={(e) => {
                    if (onChange) onChange(e.target.value);
                }}
                {...(register ? register(name, rules) : {})}
            />
        </div>
    );
};

// const PriceInputBox = forwardRef(
//     (
//         {
//             className = '',
//             label,
//             labelClassName = '',
//             name,
//             type,
//             defaultValue,
//             value,
//             disabled,
//             placeholder,
//             onChange,
//             validation,
//         },
//         inputRef
//     ) => {
//         const { register, rules, formState } = validation || {};
//         const [inputValue, setInputValue] = useState(value);

//         useEffect(() => {
//             setInputValue(Number(value).toFixed(2));
//         }, [value]);

//         return (
//             <div className={overrideTailwindClasses(`w-full mb-4 ${className}`)}>
//                 {label && (
//                     <label
//                         htmlFor={name}
//                         className={overrideTailwindClasses(
//                             `block text-sm font-medium text-gray-700 mb-1 text-left ${labelClassName}`
//                         )}
//                     >
//                         {label}
//                     </label>
//                 )}
//                 <div className="relative rounded-md">
//                     <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                         <span className="text-gray-500 sm:text-sm">$</span>
//                     </div>
//                     <input
//                         ref={inputRef}
//                         type={type || 'text'}
//                         id={name}
//                         name={name}
//                         defaultValue={defaultValue}
//                         value={inputValue}
//                         placeholder={placeholder}
//                         className={`pl-7 pr-12 focus:${
//                             formState?.errors[name] ? 'border-red-500' : 'border-indigo-500'
//                         }
//         focus:${formState?.errors[name] ? 'ring-red-500' : 'ring-indigo-500'}
//         block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300`}
//                         disabled={disabled}
//                         onFocus={(e) => {
//                             if (Number(e.target.value) === 0) setInputValue('');
//                         }}
//                         onBlur={(e) => {
//                             if (onChange) onChange(e.target.value);
//                             setInputValue(Number(e.target.value).toFixed(2));
//                         }}
//                         onChange={(e) => {
//                             if (/^(\d+)?(\.)?(\d{0,2})?$/.test(e.target.value)) return setInputValue(e.target.value);
//                         }}
//                     />
//                     <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
//                         <span className="text-gray-500 sm:text-sm" id="price-currency">
//                             USD
//                         </span>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// );

// const NumberInputBox = forwardRef(
//     (
//         {
//             className = '',
//             label,
//             labelClassName = '',
//             name,
//             type,
//             decimal = 0,
//             defaultValue,
//             value,
//             disabled,
//             placeholder,
//             onChange,
//             validation,
//         },
//         inputRef
//     ) => {
//         const { register, rules, formState } = validation || {};
//         const [inputValue, setInputValue] = useState(value);

//         useEffect(() => {
//             setInputValue(Number(value).toFixed(decimal));
//         }, [value]);

//         return (
//             <div className={overrideTailwindClasses(`w-full mb-4 ${className}`)}>
//                 {label && (
//                     <label
//                         htmlFor={name}
//                         className={overrideTailwindClasses(
//                             `block text-sm font-medium text-gray-700 mb-1 text-left ${labelClassName}`
//                         )}
//                     >
//                         {label}
//                     </label>
//                 )}
//                 <input
//                     ref={inputRef}
//                     type={type || 'text'}
//                     id={name}
//                     name={name}
//                     defaultValue={defaultValue}
//                     value={inputValue}
//                     placeholder={placeholder}
//                     className={`focus:${formState?.errors[name] ? 'border-red-500' : 'border-indigo-500'}
//         focus:${formState?.errors[name] ? 'ring-red-500' : 'ring-indigo-500'}
//         block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300`}
//                     disabled={disabled}
//                     onFocus={(e) => {
//                         if (Number(e.target.value) === 0) setInputValue('');
//                     }}
//                     onBlur={(e) => {
//                         if (onChange) onChange(e.target.value);
//                         setInputValue(Number(e.target.value).toFixed(decimal));
//                     }}
//                     onChange={(e) => {
//                         if (decimal == 2 && /^(\d+)?(\.)?(\d{0,2})?$/.test(e.target.value))
//                             return setInputValue(e.target.value);
//                         if (decimal == 1 && /^(\d+)?(\.)?(\d{0,1})?$/.test(e.target.value))
//                             return setInputValue(e.target.value);
//                         if (decimal == 0 && /^(\d+)?$/.test(e.target.value)) return setInputValue(e.target.value);
//                     }}
//                 />
//             </div>
//         );
//     }
// );

// export { TextBox as default, PriceInputBox, NumberInputBox };

export default TextBox;
