import axios from './axios';
import API_URL from './apiurl';
import { AxiosResponse } from 'axios';

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string, params: any) => axios.get(url, { params }).then(responseBody),
    post: (url: string, body: any, params?: any) => axios.post(url, body, params).then(responseBody),
    patch: (url: string, body: {}) => axios.patch(url, body).then(responseBody),
    delete: (url: string, params?: any) => axios.delete(url, { params }).then(responseBody),
};

export const AuthApi = {
    login: (params: any): Promise<any> => requests.post(`${API_URL.LOGIN_URL}`, params),
    register: (params: any): Promise<any> => requests.post(`${API_URL.REGISTER_URL}`, params),
    verify: (params: any): Promise<any> => requests.post(`${API_URL.VERIFY_URL}`, params),
    update: (params: any): Promise<any> => requests.post(`${API_URL.UPDATEPROFILE_URL}`, params),
    refreshAccessToken: (params: any): Promise<any> => requests.post(`${API_URL.REFRESH_TOKEN_URL}`, params),
    retrieveSubAccounts: (params: any): Promise<any> => requests.get(`${API_URL.SUBACCOUNTS_URL}`, {}),
    createSubAccount: (params: any): Promise<any> => requests.post(`${API_URL.SUBACCOUNTS_URL}`, params),
    delete: (params: any): Promise<any> => requests.delete(`${API_URL.USER_URL}`, params),
};

export const UserApi = {
    retrieveSponsors: (params: any): Promise<any> => requests.get(`${API_URL.SEARCH_SPONSORS_URL}`, params),
    retrieve: (params: any): Promise<any> => requests.get(`${API_URL.SEARCH_MEMBERS_URL}`, params),
};

export const ClubApi = {
    retrieve: (params: any): Promise<any> => requests.get(`${API_URL.SEARCH_CLUB_URL}`, params),
    read: (params: { _id: string }): Promise<any> => requests.get(`${API_URL.GET_CLUB_URL}/${params._id}`, params),
    sysSetting: (params: any): Promise<any> => requests.get(`${API_URL.GET_SYSMANAGE_URL}`, params),
    retriveCities: (params: any): Promise<any> => requests.get(`${API_URL.GEOLOCATION_URL}`, params),
};

export const CmtyEventApi = {
    retrieve: (params: any): Promise<any> => requests.get(`${API_URL.CMTY_EVENT_URL}`, params),
    read: (params: { _id: string }): Promise<any> => requests.get(`${API_URL.CMTY_EVENT_URL}/${params?._id}`, {}),
    update: (params: any): Promise<any> => requests.patch(`${API_URL.CMTY_EVENT_URL}`, params),
    generateMatchGroup: (params: any): Promise<any> =>
        requests.post(`${API_URL.CMTY_EVENT_URL}/generate-match`, params),
    regUser: (params: any): Promise<any> => requests.post(`${API_URL.CMTY_EVENT_REGUSER_URL}`, params),
    retrieveRegUsers: (params: any): Promise<any> => requests.get(`${API_URL.CMTY_EVENT_REGUSER_URL}`, params),
    updateRegUser: (params: any): Promise<any> => requests.patch(`${API_URL.CMTY_EVENT_REGUSER_URL}`, params),
    unRegUser: (params: any): Promise<any> => requests.delete(`${API_URL.CMTY_EVENT_REGUSER_URL}`, params),
    getRegUserByToken: (params: any): Promise<any> => requests.get(`${API_URL.CMTY_EVENT_REGUSER_URL}/bytoken`, params),
    canPay: (params: any): Promise<any> => requests.post(`${API_URL.CMTY_EVENT_REGUSER_URL}/can-pay`, params),
};

export const CmtyEventIdeaApi = {
    create: (params: any): Promise<any> => requests.post(`${API_URL.CMTY_EVENT_IDEA_URL}`, params),
    retrieve: (params: any): Promise<any> => requests.get(`${API_URL.CMTY_EVENT_IDEA_URL}`, params),
    read: (params: { _id: string }): Promise<any> => requests.get(`${API_URL.CMTY_EVENT_IDEA_URL}/${params?._id}`, {}),
    delete: (params: any): Promise<any> => requests.delete(`${API_URL.CMTY_EVENT_IDEA_URL}`, params),
    update: (params: any): Promise<any> => requests.patch(`${API_URL.CMTY_EVENT_IDEA_URL}`, params),
    like: (params: any): Promise<any> => requests.post(`${API_URL.CMTY_EVENT_IDEA_URL}/like`, params),
    unlike: (params: any): Promise<any> => requests.post(`${API_URL.CMTY_EVENT_IDEA_URL}/unlike`, params),
};

export const InvoiceApi = {
    retrieve: (params: any): Promise<any> => requests.get(`${API_URL.INVOICE_URL}`, params),
    request: (params: any): Promise<any> => requests.post(`${API_URL.INVOICE_URL}/request`, params),
};

export const IdeaQuoteApi = {
    retrieve: (params: any): Promise<any> => requests.get(`${API_URL.IDEA_QUOTE_URL}`, params),
    read: (params: { _id: string }): Promise<any> => requests.get(`${API_URL.IDEA_QUOTE_URL}/${params?._id}`, {}),
};

export const StripeApi = {
    createCheckoutSession: (params: any): Promise<any> =>
        requests.post(`${API_URL.STRIPE_URL}/checkout-sessions`, params),
    getCheckoutSession: (params: { session_id: string }): Promise<any> =>
        requests.get(`${API_URL.STRIPE_URL}/checkout-sessions/${params.session_id}`, params),
};

export const PaymentApi = {
    cancelByToken: (params: any): Promise<any> => requests.post(`${API_URL.PAYMENT_URL}/cancel-bytoken`, params),
};

export const CmtyEventLocationApi = {
    create: (params: any): Promise<any> => requests.post(`${API_URL.CMTY_EVENT_LOCATION_URL}`, params),
    retrieve: (params: any): Promise<any> => requests.get(`${API_URL.CMTY_EVENT_LOCATION_URL}`, params),
};

export const UploadApi = {
    uploadFile: (params: any): Promise<any> => {
        const formData = new FormData();
        formData.append('file', params.file);
        formData.append('type', params.type);
        return requests.post(`${API_URL.UPLOAD_FILE_URL}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};
