import { classNames } from 'utils';

type PropsType = {
    id: string;
    className?: string;
    name: string;
    label?: string;
    defaultChecked?: boolean;
    value?: boolean;
    checked?: boolean;
    validation?: any;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
};

const RadioBox = ({
    id,
    name,
    label,
    checked,
    defaultChecked,
    className,
    validation,
    disabled,
    onChange,
}: PropsType) => {
    const { register, rules, formState } = validation || {};
    return (
        <div className={classNames(className || '')}>
            <input
                id={id}
                name={name}
                value={id}
                type="radio"
                defaultChecked={defaultChecked}
                checked={checked}
                className={classNames(
                    formState?.errors[name]
                        ? 'focus:border-red-500 focus:ring-red-500'
                        : 'focus:border-emerald-600 focus:ring-emerald-600',
                    'focus:ring-emerald-500 h-4 w-4 border-gray-300 cursor-pointer',
                    disabled ? 'text-gray-400' : 'text-emerald-600'
                )}
                disabled={disabled}
                onChange={() => {
                    if (onChange) onChange(!checked);
                }}
                {...(register ? register(name, rules) : {})}
            />
            <label
                htmlFor={id || name}
                className={classNames(
                    'cursor-pointer ml-3 text-sm font-medium leading-4 inline-block',
                    disabled ? 'text-gray-400' : 'text-gray-700'
                )}
            >
                {label}
            </label>
        </div>
    );
};

export default RadioBox;
