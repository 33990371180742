import RadioBox from 'components/form-controls/RadioBox';
import Pagination from 'components/utils/Pagination';
import { useEffect, useState } from 'react';
import { classNames } from 'utils';
import constants from 'utils/constants';
import { CmtyEvent, CmtyEventRegUser, User } from 'utils/types';
import { AutoMatchGameTypes, AutoMatchGenderTypes, AutoMatchLevelTypes, AutoMatchSettingTypes } from '.';

type Props = {
    event: CmtyEvent;
    gameType: AutoMatchGameTypes;
    genderType: AutoMatchGenderTypes;
    levelType: AutoMatchLevelTypes;
    regUsers: CmtyEventRegUser[];
    update: (type: string, value: string) => void;
};

const countPerPage = 10;

const MatchSetting = ({ event, gameType, genderType, levelType, regUsers = [], update }: Props) => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        setTotalCount(regUsers.length);
    }, [regUsers]);

    const getStatus = (regUser: CmtyEventRegUser) => {
        if (regUser.isWaitList) return 'Wait';
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.REGISTERED) {
            if (event.isMatchEvent && regUser.isSelected) return 'Waiting for Payment';
            return 'Registered';
        }
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED) return 'Confirmed';
        return '';
    };

    const getStatusClass = (regUser: CmtyEventRegUser) => {
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.REGISTERED) {
            if (event.isMatchEvent && regUser.isSelected) return 'text-emerald-500';
            return 'text-amber-600';
        }
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED) return 'text-emerald-600';
        return '';
    };

    return (
        <div>
            <h3 className=" text-lg font-medium">Match Setting</h3>
            <div className="grid grid-cols-3 gap-3">
                <div className=" col-span-3 md:col-span-1">
                    <div className="border px-4 py-3 h-full border-gray-200 rounded-sm">
                        <h5 className=" font-medium mb-4">Game Type</h5>
                        <RadioBox
                            id="single"
                            name="gameType"
                            label="Single Match Game"
                            className="mb-4"
                            checked={gameType === AutoMatchGameTypes.Single}
                            onChange={() => {
                                update(AutoMatchSettingTypes.GameType, AutoMatchGameTypes.Single);
                            }}
                        />
                        <RadioBox
                            id="double"
                            name="gameType"
                            label="Double Match Game"
                            className="mb-2"
                            checked={gameType === AutoMatchGameTypes.Double}
                            onChange={() => {
                                update(AutoMatchSettingTypes.GameType, AutoMatchGameTypes.Double);
                            }}
                        />
                    </div>
                </div>
                <div className=" col-span-3 md:col-span-1">
                    <div className="border px-4 py-3 h-full border-gray-200 rounded-sm">
                        <h5 className=" font-medium mb-3">Gender Type</h5>
                        <RadioBox
                            id="onegender"
                            name="genderType"
                            label="Only One Gender"
                            className="mb-2"
                            checked={genderType === AutoMatchGenderTypes.OneGender}
                            onChange={() => {
                                update(AutoMatchSettingTypes.GenderType, AutoMatchGenderTypes.OneGender);
                            }}
                        />
                        <RadioBox
                            id="mixed"
                            name="genderType"
                            label="Mixed Gender"
                            className="mb-2"
                            checked={genderType === AutoMatchGenderTypes.Mixed}
                            onChange={() => {
                                update(AutoMatchSettingTypes.GenderType, AutoMatchGenderTypes.Mixed);
                            }}
                        />
                        <RadioBox
                            id="randomgender"
                            name="genderType"
                            label="Random"
                            className="mb-2"
                            checked={genderType === AutoMatchGenderTypes.RandomGender}
                            onChange={() => {
                                update(AutoMatchSettingTypes.GenderType, AutoMatchGenderTypes.RandomGender);
                            }}
                        />
                    </div>
                </div>
                <div className=" col-span-3 md:col-span-1">
                    <div className="border px-4 py-3 h-full border-gray-200 rounded-sm">
                        <h5 className=" font-medium mb-3">Level Type</h5>
                        <RadioBox
                            id="ranked"
                            name="levelType"
                            label="Ranked"
                            className="mb-2"
                            checked={levelType === AutoMatchLevelTypes.Ranked}
                            onChange={() => {
                                update(AutoMatchSettingTypes.LevelType, AutoMatchLevelTypes.Ranked);
                            }}
                        />
                        <RadioBox
                            id="even"
                            name="levelType"
                            label="Even"
                            className="mb-2"
                            checked={levelType === AutoMatchLevelTypes.Even}
                            onChange={() => {
                                update(AutoMatchSettingTypes.LevelType, AutoMatchLevelTypes.Even);
                            }}
                        />
                        <RadioBox
                            id="random"
                            name="levelType"
                            label="Random"
                            className="mb-2"
                            checked={levelType === AutoMatchLevelTypes.Random}
                            onChange={() => {
                                update(AutoMatchSettingTypes.LevelType, AutoMatchLevelTypes.Random);
                            }}
                        />
                    </div>
                </div>
            </div>
            <h3 className="text-lg font-medium mt-6">Registered Members ({regUsers.length})</h3>
            <div className="border rounded-md overflow-auto">
                <table className="w-full min-w-103">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">No</th>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Name</th>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Is Member</th>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Register By</th>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Status</th>
                        </tr>
                    </thead>
                    {regUsers.length === 0 ? (
                        <tbody>
                            <tr>
                                <td colSpan={4} className="text-red-600 text-center py-4">
                                    No Data
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {regUsers
                                .slice(currentPage * countPerPage, (currentPage + 1) * countPerPage)
                                .map((regUser, index) => {
                                    const member = regUser.user as User;
                                    return (
                                        <tr
                                            key={index}
                                            className={classNames(
                                                index % 2 ? 'bg-gray-50' : 'bg-white',
                                                'border-t text-sm text-gray-600'
                                            )}
                                        >
                                            <td className=" px-4 py-3">{index + 1}</td>
                                            <td className=" px-4 py-3">
                                                {member.fullName} ({member.getGender()}
                                                {event.isMatchEvent && regUser.gameLevel
                                                    ? `: ${regUser.gameLevel}`
                                                    : ''}
                                                )
                                            </td>
                                            <td className=" px-4 py-3">
                                                {member.isOriginClub(
                                                    typeof event.club === 'string' ? event.club : event.club._id
                                                )
                                                    ? 'Yes'
                                                    : 'No'}
                                            </td>
                                            <td className=" px-4 py-3 capitalize">{regUser.regBy}</td>
                                            <td
                                                className={classNames(' px-4 py-3 capitalize', getStatusClass(regUser))}
                                            >
                                                {getStatus(regUser)}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    )}
                </table>
            </div>
            {regUsers.length > countPerPage && (
                <Pagination
                    totalCount={totalCount}
                    page={currentPage}
                    pageRangeDisplayed={3}
                    countPerPage={countPerPage}
                    onChange={(page) => setCurrentPage(page)}
                />
            )}
        </div>
    );
};

export default MatchSetting;
