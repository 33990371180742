import Modal from 'components/utils/Modal';
import TextBox from 'components/form-controls/TextBox';
import Button from 'components/form-controls/Button';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { CmtyEvent, Guest, User } from 'utils/types';
import { PlusIcon, TrashIcon, UserIcon } from '@heroicons/react/outline';
import { classNames } from 'utils';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { CmtyEventApi, UserApi } from 'apis';
import { handleHTTPError } from 'store/error';

// type FormValues = {
//     name: string;
//     email: string;
//     phoneNumber: string;
// };

type PropsType = {
    title?: string;
    guests: Guest[];
    event: CmtyEvent;
    maxCount: number;
    isOpen: boolean;
    callback: (guests: Guest[]) => void;
    toggle: () => void;
};

const CmtyEventGuestsModal = ({ title, event, guests, maxCount, callback, isOpen, toggle }: PropsType) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const [nGuests, setGuests] = useState<Guest[]>([]);
    const [maxGuestsCount, setMaxGuestsCount] = useState<number>(maxCount);
    const [searchLastName, setSearchLastName] = useState<string>('');
    const [candidates, setCandidates] = useState<User[]>([]);
    const [guestName, setGuestName] = useState<string>('');
    const [guestEmail, setGuestEmail] = useState<string>('');

    useEffect(() => {
        if (isOpen) {
            setGuests(guests);
            if (canAddorDelete()) setMaxGuestsCount(maxCount);
            else setMaxGuestsCount(guests.length);
        }
        return () => {
            setCandidates([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const ok = () => {
        if (event.canReg(user)) {
            callback(nGuests);
            toggle();
        } else {
            const userRegistration = event.getUserRegistration(user);
            const params = {
                _id: userRegistration?._id,
                guests: nGuests,
            };
            CmtyEventApi.updateRegUser(params)
                .then(() => {
                    callback(nGuests);
                    toggle();
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(handleHTTPError(error));
                });
        }
    };

    const deleteGuest = (index: number) => {
        const newGuests = [...nGuests];
        newGuests.splice(index, 1);
        setGuests(newGuests);
    };

    const canAddorDelete = () => {
        if (event.isFree) return true;
        return event.canReg(user) || event.canPay(user);
    };

    const addNewGuest = (guest: { name: string; email?: string; phoneNumber?: string; isMember?: boolean }) => {
        setGuests((v) => [...v, guest]);
    };

    const searchMenber = (lastName: string) => {
        const params = { lastName, skip: 0, isCmtyEventUser: true };
        UserApi.retrieve(params).then((data) => {
            setCandidates(data.data as User[]);
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="sm:max-w-2xl"
            title={
                <div className="block sm:flex items-center">
                    <p>{title || 'Add Guests'}</p>
                    <div className="ml-3">
                        {!canAddorDelete() && (
                            <p className="text-xs text-amber-600 whitespace-pre-wrap">
                                You do not add or remove guests because you paid for this event already.'
                            </p>
                        )}
                        <p className="text-xs text-emerald-600 whitespace-pre-wrap">
                            Once you put email address of guest, you can get MCToken for that guest.
                        </p>
                    </div>
                </div>
            }
            headBorder
            disableOutClick
        >
            <div className="p-4 md:p-6 grid grid-cols-5 gap-3">
                <div className=" col-span-5 md:col-span-2">
                    <label className="text-xs text-gray-500">Please search member to add guest</label>
                    <DebounceInput
                        minLength={2}
                        className="focus:border-emerald-600 focus:ring-emerald-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300 h-10 text-gray-700"
                        debounceTimeout={300}
                        placeholder="Search with last name"
                        value={searchLastName}
                        disabled={nGuests.length >= maxGuestsCount}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            searchMenber(event.target.value);
                            setSearchLastName(event.target.value);
                        }}
                    />
                    {candidates.length > 0 && (
                        <div className=" relative w-full">
                            <div className=" absolute top-1 left-0 w-full max-h-40 overflow-auto border border-gray-100 rounded-md shadow-sm z-50 bg-white py-2">
                                {candidates.map((candidate) => (
                                    <div
                                        key={candidate._id}
                                        className="py-1.5 px-2 text-sm text-gray-600 bg-white hover:bg-gray-50 cursor-pointer"
                                        onClick={() => {
                                            addNewGuest({
                                                name: candidate.fullName,
                                                email: candidate.email,
                                                phoneNumber: candidate.phoneNumber,
                                                isMember: true,
                                            });
                                            setSearchLastName('');
                                            setCandidates([]);
                                        }}
                                    >
                                        {candidate.fullName}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className=" col-span-5 md:col-span-3">
                    <label className="text-sm text-gray-600">Guests</label>
                    {nGuests.length > 0 && (
                        <div className="px-2 border rounded-md mb-2">
                            {nGuests.map((guest, index) => (
                                <div
                                    key={index}
                                    className={classNames(
                                        'flex justify-between items-center border-gray-50',
                                        index > 0 ? 'border-t' : ''
                                    )}
                                >
                                    <div className="py-1.5">
                                        <div className="text-sm font-medium text-gray-600">{guest.name}</div>
                                        {!guest.isMember && (
                                            <>
                                                {guest.email && (
                                                    <div className="text-xs text-gray-500">{guest.email}</div>
                                                )}
                                                {guest.phoneNumber && (
                                                    <div className="text-xs text-gray-500">{guest.phoneNumber}</div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="flex items-center">
                                        {guest.isMember && <UserIcon className="w-5 h-5 mr-4 text-emerald-600" />}
                                        <div
                                            className="h-8 w-8 border border-red-600 hover:bg-red-700 rounded-full cursor-pointer flex justify-center items-center text-red-600 hover:text-white my-1.5"
                                            onClick={() => deleteGuest(index)}
                                        >
                                            <TrashIcon className="w-5 h-5 " />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {nGuests.length < maxGuestsCount && (
                        <div className="flex items-center">
                            <TextBox
                                className=" grow mr-1"
                                name="guestName"
                                placeholder="Guest Name"
                                value={guestName}
                                type="text"
                                onChange={(value) => setGuestName(value)}
                            />
                            <TextBox
                                className=" grow"
                                name="guestEmail"
                                placeholder="Guest Email (optional)"
                                value={guestEmail}
                                type="text"
                                onChange={(value) => setGuestEmail(value)}
                            />
                            <div
                                className="h-8 w-8 border border-emerald-600 hover:bg-emerald-700 rounded-full cursor-pointer flex justify-center items-center text-emerald-600 hover:text-white ml-2"
                                onClick={() => {
                                    if (!guestName) return;
                                    addNewGuest({
                                        name: guestName,
                                        email: guestEmail,
                                        isMember: false,
                                    });
                                    setGuestName('');
                                    setGuestEmail('');
                                }}
                            >
                                <PlusIcon className="w-5 h-5 " />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="p-4 md:p-6 bg-gray-50 rounded-b-md flex justify-end">
                <Button
                    textColor="text-gray-700"
                    className="border border-gray-300 mr-4"
                    color="bg-white"
                    hoverColor="bg-gray-50"
                    onClick={toggle}
                >
                    Close
                </Button>
                <Button onClick={ok} className="px-6">
                    Save
                </Button>
            </div>
        </Modal>
    );
};

export default CmtyEventGuestsModal;
