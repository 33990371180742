import { CmtyEventApi } from 'apis';
import CmtyEventDetailModal from 'components/cmtyevents/detailsdlg';
import CmtyEventList from 'components/cmtyevents/eventlist';
import Pagination from 'components/utils/Pagination';
import { isMobile } from 'react-device-detect';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { handleHTTPError } from 'store/error';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setCommonLoading, toggleCmtyEventDetailModal } from 'store/ui';
import constants from 'utils/constants';
import { CmtyEvent, CmtyEventModel, CmtyEventRegUser } from 'utils/types';
import RadioBox from 'components/form-controls/RadioBox';
import PageContentLayout from 'layouts/PageContentLayout';
import './index.scss';
const localizer = momentLocalizer(moment);

enum EventDisplayType {
    Calendar = 'calendar',
    List = 'list',
}

const MyCmtyEvents = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const [displayType, setDisplayType] = useState('list');
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [events, setEvents] = useState<CmtyEvent[]>([]);
    const [event, setEvent] = useState<CmtyEvent | null>(null);

    useEffect(() => {
        searchListData(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchListData = (skip = 0) => {
        const params = {
            'start.gte': moment().startOf('day').format('YYYY-MM-DD HH:mm'),
            'status.ne': constants.CMTYEVENT_STATUS.CANCELED,
            user: user?._id,
            skip,
            limit: 10,
            sort_by: 'start',
            order_by: 'asc',
        };
        searchEvents(params);
    };

    const searchCalendarData = (start: string, end: string) => {
        const params = {
            user: user?._id,
            'start.gte': start,
            'start.lte': end,
            'status.ne': constants.CMTYEVENT_STATUS.CANCELED,
            sort_by: 'start',
            order_by: 'asc',
        };
        searchEvents(params);
    };

    const searchEvents = (params: any) => {
        dispatch(setCommonLoading(true));
        CmtyEventApi.retrieve(params)
            .then((data) => {
                dispatch(setCommonLoading(false));
                let events: CmtyEvent[] = (data.data as CmtyEventModel[]).map((evt) => new CmtyEvent(evt));
                events = events.map((evt) => {
                    const regUsers = evt.regUsers as CmtyEventRegUser[];
                    evt.regUsers = (regUsers || []).filter((v, i, self) => self.indexOf(v) === i);
                    return evt;
                });
                setEvents(events);
                setTotalCount(data.totalCount || 0);
                setCurrentPage(Math.floor(params.skip / 10));
            })
            .catch((error) => {
                dispatch(setCommonLoading(false));
                dispatch(handleHTTPError(error));
            });
    };

    const pageChange = (page: number) => {
        searchListData(page * 10);
    };

    const selectEvent = (event: CmtyEvent) => {
        setEvent(event);
        dispatch(toggleCmtyEventDetailModal());
    };

    const eventStyleGetter = (event: CmtyEvent) => {
        const newEvent = new CmtyEvent({ ...event } as CmtyEventModel);
        const regStatus = newEvent.getUserRegStatus(user);
        const isFinished =
            event.status === constants.CMTYEVENT_STATUS.FINISHED ||
            event.status === constants.CMTYEVENT_STATUS.COMPLETED;
        const backgroundColor = isFinished
            ? '#6b7280'
            : regStatus === 'confirmed'
            ? '#059669'
            : regStatus === 'waitlist'
            ? '#d97706'
            : '#6ee7b7';
        const style = {
            backgroundColor,
            border: 'none',
            borderRadius: '5px',
            fontSize: '13px',
            color: 'white',
            display: 'block',
        };
        return { style };
    };

    const onCalendarNavigate = (date: Date, view: View) => {
        let params: moment.unitOfTime.StartOf = 'week';
        if (view === 'day') params = 'day';
        const start = moment(date).startOf(params).format('YYYY-MM-DD HH:mm');
        const end = moment(date).endOf(params).format('YYYY-MM-DD HH:mm');
        searchCalendarData(start, end);
    };

    const getCalendarData = (): any => {
        return events.map((event) => {
            return { ...event, start: moment(event.start).toDate(), end: moment(event.end).toDate() };
        });
    };

    return (
        <PageContentLayout scrollTop={false}>
            <div className="flex mb-10">
                <RadioBox
                    id="list"
                    name="displayType"
                    className="mr-10"
                    checked={displayType === EventDisplayType.List}
                    label="List View"
                    onChange={() => {
                        setDisplayType(EventDisplayType.List);
                        searchListData(0);
                    }}
                />
                <RadioBox
                    id="calendar"
                    name="displayType"
                    checked={displayType === EventDisplayType.Calendar}
                    label="Calendar View"
                    onChange={() => {
                        setDisplayType(EventDisplayType.Calendar);
                        searchCalendarData(
                            moment().startOf('week').format('YYYY-MM-DD HH:mm'),
                            moment().endOf('week').format('YYYY-MM-DD HH:mm')
                        );
                    }}
                />
            </div>
            {displayType === EventDisplayType.Calendar && (
                <Calendar
                    localizer={localizer}
                    selectable
                    step={30}
                    timeslots={2}
                    views={isMobile ? ['day'] : ['week', 'day']}
                    events={getCalendarData()}
                    min={moment().startOf('day').add(5, 'hours').toDate()}
                    max={moment().startOf('day').add(23, 'hours').toDate()}
                    defaultView={isMobile ? 'day' : 'week'}
                    defaultDate={new Date()}
                    onSelectEvent={selectEvent}
                    eventPropGetter={eventStyleGetter}
                    components={{
                        event: CalendarEventBox,
                    }}
                    onNavigate={onCalendarNavigate}
                />
            )}
            {displayType === 'list' && (
                <>
                    <CmtyEventList events={events} onSelect={selectEvent} />
                    <Pagination
                        totalCount={totalCount}
                        page={currentPage}
                        pageRangeDisplayed={5}
                        countPerPage={10}
                        onChange={pageChange}
                    />
                </>
            )}
            {event && (
                <CmtyEventDetailModal
                    eventId={event._id}
                    callback={() => {
                        if (displayType === 'list') searchListData(0);
                    }}
                />
            )}
        </PageContentLayout>
    );
};

export default MyCmtyEvents;

const CalendarEventBox = ({ event }: { event: CmtyEvent }) => {
    return (
        <div>
            <p className="text-sm">{event.title}</p>
            <p className="text-capitalize text-xs">{event.eventType}</p>
        </div>
    );
};
