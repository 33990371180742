import { CmtyEventApi } from 'apis';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { handleHTTPError } from 'store/error';
import { useAppDispatch } from 'store/hook';
import { classNames } from 'utils';
import { CmtyEvent, CmtyEventModel } from 'utils/types';
import EventContent from './content';
import EventRegUsers from './regusers';
import Button from 'components/form-controls/Button';
import { ArrowLeftIcon } from '@heroicons/react/outline';

const AdvocateCmtyEventEdit = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState<CmtyEvent | null>(null);
    const [tab, setTab] = useState<string>('details');

    useEffect(() => {
        const { id } = params as { id: string };
        if (!id) navigate('/advocate-events');

        CmtyEventApi.read({ _id: id })
            .then((data) => {
                setEvent(new CmtyEvent(data.data as CmtyEventModel));
            })
            .catch((error) => {
                dispatch(handleHTTPError(error));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!event) return null;
    return (
        <div className="bg-white rounded-md mx-auto p-4 md:p-10 md:max-w-5xl w-full">
            <div className="flex mb-3 border-b text-base justify-between">
                <div className="flex">
                    <div
                        className={classNames(
                            'border py-2 px-4 mr-1 rounded-t-md cursor-pointer',
                            tab === 'details' ? 'bg-emerald-600 text-white border-emerald-600' : ''
                        )}
                        onClick={() => setTab('details')}
                    >
                        Event Details
                    </div>
                    <div
                        className={classNames(
                            'border py-2 px-4 mr-1 rounded-t-md cursor-pointer',
                            tab === 'reg_members' ? 'bg-emerald-600 text-white border-emerald-600' : ''
                        )}
                        onClick={() => setTab('reg_members')}
                    >
                        Registered Members
                    </div>
                </div>
                <Button
                    color="bg-white"
                    textColor="text-gray-700"
                    hoverColor="bg-gray-100"
                    className="w-36 mb-2"
                    onClick={() => navigate('/advocate-events')}
                >
                    <div className="flex justify-center">
                        <ArrowLeftIcon className="w-5 h-5 mr-3" /> Back
                    </div>
                </Button>
            </div>
            {tab === 'details' ? <EventContent event={event} /> : <EventRegUsers event={event} />}
        </div>
    );
};

export default AdvocateCmtyEventEdit;
