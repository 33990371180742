import Notifications from './Notification';
import PersionalInfo from './PersionalInfo';
import SubAccounts from './sub-accounts';
import TempClub from './TempClub';

const Profile = () => {
    return (
        <>
            <TempClub />
            <PersionalInfo />
            <Notifications />
            <SubAccounts />
        </>
    );
};

export default Profile;
