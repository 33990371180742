import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import uiReducer from './ui';
import clubReducer from './club';
import errorReducer from './error';
import criteriaReducer from './criteria';

const store = configureStore({
    reducer: {
        auth: authReducer,
        ui: uiReducer,
        club: clubReducer,
        error: errorReducer,
        criteria: criteriaReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
