import {
    BookmarkIcon,
    HomeIcon,
    PencilAltIcon,
    SearchIcon,
    SpeakerphoneIcon,
    UserCircleIcon,
    UserGroupIcon,
    WifiIcon,
} from '@heroicons/react/outline';
import { NavLink } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { logout } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { toggleSideBar } from 'store/ui';
import { classNames } from 'utils';

const Sidebar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const isOpen = useAppSelector((state) => state.ui.isOpenSideBar);
    const user = useAppSelector((state) => state.auth.user);
    const hasMyIdeas = useAppSelector((state) => state.ui.hasMyIdeas);
    const hadAdvocateOnlyEvents = user?.isCmtyAdvocator && user?.advocateClubs && user?.advocateClubs?.length > 0;

    return (
        <>
            <div
                className={classNames(
                    isOpen ? 'block lg:hidden' : 'hidden',
                    'fixed top-0 left-0 inset-0 bg-black opacity-50 z-10'
                )}
                onClick={() => {
                    dispatch(toggleSideBar());
                }}
            />
            <div
                className={classNames(
                    'flex flex-col',
                    isOpen ? 'left-0' : ' -left-72 lg:left-0',
                    'fixed z-10 top-0 w-72 h-screen border-r bg-white py-10 transition-all ease-in-out duration-500'
                )}
            >
                <div className="py-10 flex-shrink-0">
                    <NavLink to="/profile" onClick={() => dispatch(toggleSideBar())}>
                        <img
                            src={user?.photoUrl}
                            alt="sidebar-logo"
                            className=" w-20 h-20 mx-auto border border-gray-300 mb-2 rounded-full"
                        />
                    </NavLink>
                    <p className="text-center text-base text-light font-medium text-gray-800">{user?.fullName}</p>
                    <p className="text-center text-xs text-light text-gray-500">{user?.email}</p>
                </div>
                <div className=" flex-grow overflow-auto px-6">
                    <NavLink
                        className={({ isActive }) => {
                            return classNames(
                                'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                isActive ? 'bg-emerald-600 text-white' : 'text-gray-500'
                            );
                        }}
                        to="/profile"
                        onClick={() => dispatch(toggleSideBar())}
                    >
                        <div className="flex items-center">
                            <UserCircleIcon className="w-6 h-6 mr-4 " />
                            <span>Profile</span>
                        </div>
                    </NavLink>
                    <NavLink
                        className={({ isActive }) => {
                            return classNames(
                                'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                isActive ? 'bg-emerald-600 text-white' : 'text-gray-500'
                            );
                        }}
                        to="/invoices"
                        onClick={() => dispatch(toggleSideBar())}
                    >
                        <div className="flex items-center">
                            <BookmarkIcon className="w-6 h-6 mr-4 " />
                            <span>Invoices</span>
                        </div>
                    </NavLink>
                    <NavLink
                        className={({ isActive }) => {
                            return classNames(
                                'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                isActive ? 'bg-emerald-600 text-white' : 'text-gray-500'
                            );
                        }}
                        to="/myevents"
                        onClick={() => dispatch(toggleSideBar())}
                    >
                        <div className="flex items-center">
                            <BookmarkIcon className="w-6 h-6 mr-4 " />
                            <span>My Events</span>
                        </div>
                    </NavLink>
                    <NavLink
                        className={({ isActive }) => {
                            return classNames(
                                'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                isActive ? 'bg-emerald-600 text-white' : 'text-gray-500'
                            );
                        }}
                        to="/advocate-events"
                        onClick={() => dispatch(toggleSideBar())}
                    >
                        <div className="flex items-center">
                            <PencilAltIcon className="w-6 h-6 mr-4 " />
                            <span>Advocate Events</span>
                        </div>
                    </NavLink>
                    {hadAdvocateOnlyEvents && (
                        <NavLink
                            className={({ isActive }) => {
                                return classNames(
                                    'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                    isActive ? 'bg-emerald-600 text-white' : 'text-gray-500'
                                );
                            }}
                            to="/advocate-only-events"
                            onClick={() => dispatch(toggleSideBar())}
                        >
                            <div className="flex items-center">
                                <UserGroupIcon className="w-6 h-6 mr-4 " />
                                <span>Advocate Only Events</span>
                            </div>
                        </NavLink>
                    )}

                    {hasMyIdeas && (
                        <NavLink
                            className={({ isActive }) => {
                                return classNames(
                                    'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                    isActive && params.id !== 'new' ? 'bg-emerald-600 text-white' : 'text-gray-500'
                                );
                            }}
                            to="/myeventideas"
                            onClick={() => dispatch(toggleSideBar())}
                        >
                            <div className="flex items-center">
                                <WifiIcon className="w-6 h-6 mr-4 " />
                                <span>My Event Ideas</span>
                            </div>
                        </NavLink>
                    )}
                    <NavLink
                        className={({ isActive }) => {
                            return classNames(
                                'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                isActive && params.id === 'new' ? 'bg-emerald-600 text-white' : 'text-gray-500'
                            );
                        }}
                        to="/myeventideas/new"
                        onClick={() => dispatch(toggleSideBar())}
                    >
                        <div className="flex items-center">
                            <WifiIcon className="w-6 h-6 mr-4 " />
                            <span>Create a New Idea</span>
                        </div>
                    </NavLink>
                    <NavLink
                        className={({ isActive }) => {
                            return classNames(
                                'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                isActive ? 'bg-emerald-600 text-white' : 'text-gray-500'
                            );
                        }}
                        to="/cmtyevents"
                        onClick={() => dispatch(toggleSideBar())}
                    >
                        <div className="flex items-center">
                            <SearchIcon className="w-6 h-6 mr-4 " />
                            <span>Search Events</span>
                        </div>
                    </NavLink>
                    <NavLink
                        className={({ isActive }) => {
                            return classNames(
                                'block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4',
                                isActive ? 'bg-emerald-600 text-white' : 'text-gray-500'
                            );
                        }}
                        to="/eventideas"
                        onClick={() => dispatch(toggleSideBar())}
                    >
                        <div className="flex items-center">
                            <SpeakerphoneIcon className="w-6 h-6 mr-4 " />
                            <span>Search Ideas</span>
                        </div>
                    </NavLink>
                    <div
                        onClick={() => {
                            dispatch(logout());
                            dispatch(toggleSideBar());
                            navigate('/cmtyevents');
                        }}
                        className="block py-3 px-6 hover:text-white hover:bg-emerald-600 rounded-md text-base my-4 text-gray-500"
                    >
                        <div className="flex items-center">
                            <HomeIcon className="w-6 h-6 mr-4 " />
                            <span>Logout</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
