import { CalendarIcon } from '@heroicons/react/outline';
import { forwardRef, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { classNames } from 'utils';
import './module.scss';

interface PropsType {
    className?: string;
    name: string;
    required?: boolean;
    disabled?: boolean;
    showTimeSelect?: boolean;
    selected?: Date;
    value?: string;
    label?: string | JSX.Element;
    onChange?: (date: Date | null) => void;
    dateFormat?: string;
    valid?: boolean;
    validation?: any;
}

const DatePicker = ({
    className,
    label,
    name,
    required = false,
    value,
    showTimeSelect = false,
    disabled,
    selected,
    onChange,
    dateFormat = 'MMMM dd, yyyy',
    validation,
}: PropsType) => {
    const selectRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (selectRef.current && validation) {
            const errorKeys = Object.keys(validation?.formState?.errors);
            if (errorKeys.length > 0 && errorKeys[0] === name) (selectRef.current as any).setFocus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation?.formState.submitCount]);
    return (
        <div className={className || ''}>
            {label && (
                <>
                    {typeof label === 'string' ? (
                        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1 text-left">
                            {label}
                            {required && <span className=" text-red-500 ml-1">*</span>}
                        </label>
                    ) : (
                        <>{label}</>
                    )}
                </>
            )}
            {validation ? (
                <Controller
                    control={validation.control}
                    name={name}
                    rules={validation.rules}
                    render={({ field, formState }) => (
                        <ForwardRefDatePicker
                            name={name}
                            valid={!formState?.errors[name]}
                            selected={moment(field.value || undefined).toDate()}
                            disabled={disabled}
                            dateFormat={dateFormat}
                            showTimeSelect={showTimeSelect}
                            onChange={field.onChange}
                            ref={selectRef}
                        />
                    )}
                />
            ) : (
                <ReactDatePicker
                    selected={selected}
                    name={name}
                    disabled={disabled}
                    showTimeSelect={showTimeSelect}
                    className={classNames(
                        'bg-white relative w-full min-h-10 border border-gray-300 rounded-md shadow-sm flex px-3 justify-between',
                        'py-2 text-left focus:outline-none focus:ring-1 sm:text-sm cursor-pointer',
                        'focus:border-emerald-600 focus:ring-emerald-600'
                    )}
                    onChange={(date) => {
                        if (onChange) onChange(date);
                    }}
                    dateFormat={dateFormat}
                    customInput={<CustomInput />}
                />
            )}
        </div>
    );
};

export default DatePicker;

const ForwardRefDatePicker = forwardRef<HTMLButtonElement, PropsType>(
    ({ name, valid, selected, disabled, dateFormat, onChange, showTimeSelect }: PropsType, ref: any) => {
        return (
            <ReactDatePicker
                name={name}
                selected={selected}
                dateFormat={dateFormat}
                disabled={disabled}
                showTimeSelect={showTimeSelect}
                className={classNames(
                    'bg-white relative w-full min-h-10 border border-gray-300 rounded-md shadow-sm flex px-3 justify-between',
                    'py-2 text-left focus:outline-none focus:ring-1 sm:text-sm cursor-pointer',
                    !valid
                        ? 'focus:border-red-500 focus:ring-red-500'
                        : 'focus:border-emerald-600 focus:ring-emerald-600'
                )}
                onChange={(date) => {
                    if (onChange) onChange(date);
                }}
                ref={ref}
                customInput={<CustomInput />}
            />
        );
    }
);

interface CustomInputTypes extends React.HTMLProps<HTMLButtonElement> {
    valid?: boolean;
}

const CustomInput = forwardRef((props: CustomInputTypes, ref: React.Ref<HTMLButtonElement>) => {
    return (
        <button {...props} type="button" ref={ref}>
            {props?.value}
            <CalendarIcon className="h-5 w-5" />
        </button>
    );
});
