// import { useEffect, useState } from 'react';

type PropsType = {
    currentIndex: number;
    isOpen: boolean;
    toggle: () => void;
    images: string[];
};
const ImageCarouselDlg = ({ currentIndex, isOpen, toggle, images = [] }: PropsType) => {
    // const [views, setViews] = useState<{ source: string }[]>([]);

    // useEffect(() => {
    //     if (isOpen) {
    //         setViews(
    //             images.map((source) => {
    //                 return { source };
    //             })
    //         );
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isOpen]);

    return (
        // <ModalGateway>
        //     {isOpen ? (
        //         <Modal onClose={toggle} closeOnBackdropClick={false}>
        //             <Carousel views={views} currentIndex={currentIndex} />
        //         </Modal>
        //     ) : null}
        // </ModalGateway>
        <div></div>
    );
};

export default ImageCarouselDlg;
