import CommonLoader from 'components/utils/CommonLoader';
import AlertModal from './AlertModal';
import LoginModal from './LoginModal';
import Notifications from './Notifications';
import RegisterModal from './register-dlg';

const GlobalComponent = () => {
    return (
        <>
            <LoginModal />
            <RegisterModal />
            <Notifications />
            <AlertModal />
            <CommonLoader />
        </>
    );
};

export default GlobalComponent;
