import { AutoMatchGameTypes } from '.';
import MatchGroup from './MatchGroup';

// result={this.state.result} gameType={this.state.gameType}

type Props = {
    result: any[];
    gameType: AutoMatchGameTypes;
};

const MatchResult = ({ result, gameType }: Props) => {
    return (
        <div className="grid grid-cols-3 gap-3">
            {result.map((group, index) => (
                <div className=" col-span-3 md:col-span-1">
                    <MatchGroup group={group} gameType={gameType} />
                </div>
            ))}
        </div>
    );
};

export default MatchResult;
