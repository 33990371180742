import Button from 'components/form-controls/Button';
import TextBox from 'components/form-controls/TextBox';
import Modal from 'components/utils/Modal';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = {
    gameLevel: string;
};

type PropsType = {
    isOpen: boolean;
    toggle: () => void;
    callback: (gameLevel: number) => void;
};
const GameLevelModal = ({ isOpen, toggle, callback }: PropsType) => {
    const { register, formState, handleSubmit, reset } = useForm<FormValues>({
        defaultValues: { gameLevel: '' },
    });

    useEffect(() => {
        reset({ gameLevel: '' });
    }, [isOpen, reset]);

    const ok = (data: FormValues) => {
        if (isNaN(Number(data.gameLevel))) return;
        callback(Number(data.gameLevel));
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="sm:max-w-xs" title="Game Level Of Member" headBorder>
            <form onSubmit={handleSubmit(ok)} autoComplete="off">
                <div className="p-4 md:p-6 ">
                    <TextBox
                        className="w-100"
                        name="gameLevel"
                        label="Game Level"
                        type="number"
                        defaultValue=""
                        placeholder="5"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                                min: 1,
                            },
                        }}
                    />
                </div>
                <div className="flex justify-end  rounded-b-md px-6 py-4 bg-gray-50">
                    <Button
                        textColor="text-gray-700"
                        className="border border-gray-300"
                        color="bg-white"
                        hoverColor="bg-gray-50"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                    <Button className="ml-3 min-w-24" type="submit">
                        Register
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default GameLevelModal;
