import Loader from './Loader';

const SuspenseLoader = () => {
    return (
        <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-white">
            <Loader />
        </div>
    );
};

export default SuspenseLoader;
