import { loadStripe } from '@stripe/stripe-js';

let stripePromise: any = null;

const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(`${process.env.REACT_APP_STRIP_PUBLICKEY}`);
    }
    return stripePromise;
};

export default getStripe;
