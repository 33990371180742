import { useForm } from 'react-hook-form';
import Modal from 'components/utils/Modal';
import TextArea from 'components/form-controls/TextArea';
import Button from 'components/form-controls/Button';
import { useEffect } from 'react';
import { Club, CmtyEvent } from 'utils/types';
import moment from 'moment';

type PropsType = {
    isOpen: boolean;
    toggle: () => void;
    event: CmtyEvent;
    callback: (value: FormValues) => void;
};

type FormValues = {
    emailContent: string;
};

const EmailOptionModel = ({ isOpen, toggle, event, callback }: PropsType) => {
    const { register, formState, handleSubmit, reset } = useForm<FormValues>({
        defaultValues: {},
    });

    useEffect(() => {
        if (isOpen && reset)
            reset({
                emailContent: `"${(event.club as Club).displayName}" has scheduled "${
                    event.title
                }" on Friday, starting on ${moment(event.start).format('MMMM DD, h:mm A')} and continuing for ${
                    event.repeatWeeks
                } weeks. You can view the "${
                    event.title
                }" on your app by going to "Community Events". You will only receive additional notification on "${
                    event.title
                }" if you register.`,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, reset]);

    const ok = (data: FormValues) => {
        callback(data);
        toggle();
    };

    const cancel = () => {
        callback({ emailContent: '' });
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={cancel} size="sm:max-w-lg" title="Broadcast Email" headBorder disableOutClick>
            <form onSubmit={handleSubmit(ok)} autoComplete="off">
                <div className="p-6">
                    <TextArea
                        className="mb-4"
                        name="emailContent"
                        label="Email Content"
                        inputClassName=" h-28"
                        labelClassName="mb-2"
                        placeholder="Message Herea"
                        defaultValue=""
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <p className="text-red-500 text-xs text-center mb-4">
                        This email will be sent to only registered users
                    </p>
                </div>
                <div className="flex justify-end  rounded-b-md px-6 py-4 bg-gray-50">
                    <Button
                        textColor="text-gray-700"
                        className="border border-gray-300"
                        color="bg-white"
                        hoverColor="bg-gray-50"
                        onClick={cancel}
                    >
                        Close
                    </Button>
                    <Button className="ml-3 min-w-24" type="submit">
                        Ok
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default EmailOptionModel;
