import { useAppDispatch, useAppSelector } from 'store/hook';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import {
    showAlert,
    showAlertModal,
    toggleCmtyEventDetailModal,
    toggleCmtyRegResultModal,
    toggleLoginModal,
} from 'store/ui';
import { CmtyEventApi, StripeApi } from 'apis';
import getStripe from 'utils/stripe';
import Modal from 'components/utils/Modal';
import TextBox from 'components/form-controls/TextBox';
import Button from 'components/form-controls/Button';
import { useEffect, useState } from 'react';
import {
    Club,
    CmtyEvent,
    CmtyEventLocation,
    CmtyEventModel,
    CmtyEventRegResult,
    CmtyEventRegUser,
    Guest,
} from 'utils/types';
import { handleHTTPError } from 'store/error';
import CmtyEventInfo from '../cmtyevent-info';
import CmtyEventRegCount from '../CmtyEventRegCount';
import moment from 'moment-timezone';
import Select from 'components/form-controls/Select';
import constants from 'utils/constants';
import CmtyEventPlaceMap from '../CmtyEventPlaceMap';
import CmtyEventRegResultModal from '../reg-result';
import { GiftIcon } from '@heroicons/react/outline';
import CmtyEventGuestsModal from '../guestsdlg';

type FormValues = {
    repeatWeeks: number;
    gameLevel: number;
    payType: string;
    donatedAmount: number;
};

type PropsType = {
    eventId: string;
    callback: () => void;
};

const CmtyEventDetailModal = ({ eventId, callback }: PropsType) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isOpen = useAppSelector((state) => state.ui.isOpenCmtyDetailModal);
    const user = useAppSelector((state) => state.auth.user);
    const { register, formState, handleSubmit, reset, watch, control } = useForm<FormValues>({
        defaultValues: {},
    });
    const [loading, setLoading] = useState(false);
    const [isOpenGuests, setIsOpenGuets] = useState(false);
    const [event, setEvent] = useState<CmtyEvent>();
    const [regResult, setRegResult] = useState<CmtyEventRegResult[]>([]);
    const [guests, setGuests] = useState<Guest[]>([]);
    const watchFields = watch(['repeatWeeks']);

    useEffect(() => {
        if (isOpen) {
            readEvent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const readEvent = () => {
        setLoading(true);
        CmtyEventApi.read({ _id: eventId })
            .then((data) => {
                const event = new CmtyEvent(data.data as CmtyEventModel);
                reset({
                    repeatWeeks: 1,
                    payType: event.isWeeklyPay
                        ? constants.CMTYEVENT_PAYTYPE.WEEK_BY_WEEK
                        : constants.CMTYEVENT_PAYTYPE.ALL_WEEK,
                });
                setGuests(event.getUserRegGuests(user));
                setEvent(event);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    if (!event) return null;

    const toggle = () => {
        dispatch(toggleCmtyEventDetailModal());
    };

    const payAndConfirm = (data: FormValues) => {
        if (!user) {
            toggle();
            return dispatch(toggleLoginModal());
        }
        if (loading) return;
        const regUsers = event?.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((rgUser) => rgUser.user === user?._id);
        if (!regUser) return;

        setLoading(true);
        const params = {
            type: constants.PAYMENT_TYPE.CMTY_EVENT,
            cmtyEventRegUser: regUser._id,
            cancelUrl: location.pathname,
        };
        StripeApi.createCheckoutSession(params)
            .then(async (data) => {
                setLoading(false);
                if (data.sessionId) {
                    const stripe = await getStripe();
                    return stripe.redirectToCheckout({ sessionId: data.sessionId });
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    const unRegisterEvent = (data: FormValues) => {
        if (!user) {
            toggle();
            return dispatch(toggleLoginModal());
        }
        if (loading) return;
        const regUsers = event?.regUsers as CmtyEventRegUser[];
        const regUser = regUsers.find((rgUser) => rgUser.user === user?._id);
        if (!regUser) return;
        dispatch(
            showAlertModal({
                type: 'warning',
                title: 'Cancel Registration For Event',
                message: 'Do you want to cancel registration for event? \n Are you sure?',
                buttons: [
                    {
                        type: 'ok',
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        type: 'normal',
                        label: 'No',
                        value: 'no',
                    },
                ],
                handler: (value: string) => {
                    if (value === 'no') return;
                    const params = {
                        _id: regUser._id,
                    };
                    setLoading(true);
                    CmtyEventApi.unRegUser(params)
                        .then((data) => {
                            setLoading(false);
                            dispatch(showAlert({ type: 'success', message: data.message }));
                            dispatch(toggleCmtyEventDetailModal());
                            callback();
                        })
                        .catch((error) => {
                            setLoading(false);
                            dispatch(handleHTTPError(error));
                        });
                },
            })
        );
    };

    const registerEvent = (data: FormValues) => {
        if (!user) {
            toggle();
            return dispatch(toggleLoginModal());
        }
        if (!event.isReqRegister) return;

        if (loading) return;
        const { repeatWeeks, payType, gameLevel, donatedAmount } = data;

        const params = {
            event: event?._id,
            user: user?._id,
            payType,
            repeatWeeks: Number(repeatWeeks),
            gameLevel: event.isMatchEvent && gameLevel ? Number(gameLevel) : undefined,
            donatedAmount: event.isFree && donatedAmount ? Number(donatedAmount) : undefined,
            guests: event.allowGuests ? guests : [],
        };

        // if event is full, user will add to waitlist and don't need to pay immediatelly.
        if (event.getRegUserCount() >= event.maxRegCount) return regEvent(params);
        if (event.isFree) {
            if ((params.donatedAmount || 0) > 0) return regEvent({ ...params, agreePayment: true });
            else return regEvent({ ...params, agreePayment: false });
        } else {
            if (event.shouldPay()) return regEvent({ ...params, agreePayment: true });
            // if event is match event, user should pay after selected by admin
            else if (event.isMatchEvent) return regEvent({ ...params, agreePayment: false });
            // if today is before payBeBeforeDays of event, user can or can't pay immediately
            else
                dispatch(
                    showAlertModal({
                        type: 'alert',
                        title: 'Registration And Payment',
                        message: `Are you willing to pay now for this event? \n\n Payment should be completed untill ${moment(
                            event.payByDate
                        ).format('DD MMM YYYY')}.`,
                        buttons: [
                            {
                                type: 'ok',
                                label: 'Register and Pay',
                                value: 'reg_pay',
                            },
                            {
                                type: 'ok',
                                label: 'Just Register',
                                value: 'just_reg',
                            },
                            {
                                type: 'normal',
                                label: 'Cancel',
                                value: 'cancel',
                            },
                        ],
                        data: params,
                        handler: regiserAndPayAlertResult,
                    })
                );
        }
    };

    const regiserAndPayAlertResult = (value: string, data: any) => {
        if (value === 'reg_pay') {
            const params = { ...data, agreePayment: true };
            regEvent(params);
        } else if (value === 'just_reg') regEvent(data);
    };

    const regEvent = (params: any) => {
        if (
            !event.isFree &&
            params.agreePayment &&
            params.payType === constants.CMTYEVENT_PAYTYPE.ALL_WEEK &&
            params.repeatWeeks > 1
        ) {
            let url = `/payments/checkout/?id=${event._id}&repeatWeeks=${params.repeatWeeks}&type=${constants.PAYMENT_TYPE.CMTY_EVENT}`;
            if (params.gameLevel) url += `&gameLevel=${params.gameLevel}`;
            if (params.guests.length > 0) url += `&guests=${JSON.stringify(params.guests)}`;
            return navigate(url);
        }

        setLoading(true);
        CmtyEventApi.regUser(params)
            .then(async (data) => {
                setLoading(false);
                // if you want payment, will be redirected to stripe checkout page
                if (data.sessionId) {
                    const stripe = await getStripe();
                    return stripe.redirectToCheckout({ sessionId: data.sessionId });
                }
                if (params.repeatWeeks > 1) {
                    setRegResult(data.result as CmtyEventRegResult[]);
                    dispatch(toggleCmtyRegResultModal());
                } else {
                    dispatch(showAlert({ type: 'success', message: data.message }));
                }
                dispatch(toggleCmtyEventDetailModal());
                callback();
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };

    const generatePayTypeOptions = () => {
        return [
            { value: constants.CMTYEVENT_PAYTYPE.ALL_WEEK, label: 'Pay for all weeks' },
            { value: constants.CMTYEVENT_PAYTYPE.WEEK_BY_WEEK, label: 'Pay week by week' },
        ];
    };

    const displayBadge = () => {
        if (
            event.status === constants.CMTYEVENT_STATUS.FINISHED ||
            event.status === constants.CMTYEVENT_STATUS.COMPLETED
        ) {
            return (
                <div className="p-2 py-1 ml-3 rounded-full border border-amber-600 text-xs text-amber-600">
                    FINISHED
                </div>
            );
        }
        return '';
    };

    const displayAdvocateEventBadge = () => {
        if (!event.isAdvocateOnly) return null;
        return (
            <div className="p-2 py-1 ml-3 rounded-full border border-red-400 text-xs text-red-400">
                For Only Advocates
            </div>
        );
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                size="sm:max-w-3xl"
                loading={loading}
                title={
                    <div className="flex items-center">
                        <div className="mr-5">
                            Event Details{' '}
                            {event.getUserRegStatus(user) === 'registered' ? (
                                <span className="text-emerald-400 text-base">(Registered)</span>
                            ) : event.getUserRegStatus(user) === 'confirmed' ? (
                                <span className="text-emerald-600 text-base">
                                    (Confirmed {!event.isFree ? ':Paid' : ''})
                                </span>
                            ) : event.getUserRegStatus(user) === 'waitlist' ? (
                                <span className="text-amber-600 text-base">(Waitlist)</span>
                            ) : (
                                ''
                            )}
                            {event.didDonate(user) && (
                                <>
                                    <GiftIcon className="ml-4 text-red-600 w-5 h-5 inline-block" />{' '}
                                    <span className="text-sm text-red-600">Donation</span>
                                </>
                            )}
                        </div>
                        <div>
                            {displayAdvocateEventBadge()} {displayBadge()}
                        </div>
                    </div>
                }
                headBorder
                disableOutClick
            >
                <form autoComplete="off">
                    <div className="block md:grid grid-cols-2 gap-4 p-4 md:p-6">
                        <div className="col-span-1">
                            <CmtyEventInfo event={event} />
                        </div>
                        <div className="col-span-1 flex flex-col">
                            <div className="py-3">
                                <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                                    {event.eventType}
                                </span>
                                <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                                    {event.venue}
                                </span>
                                <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                                    {event.eligibleGender === 'all' ? 'All Genders' : event.eligibleGender}
                                </span>
                                <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                                    {event.ageRanges.join(', ')}
                                </span>
                                {event.isMatchEvent && (
                                    <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                                        {event.isAllEligibleLevel()
                                            ? 'All Levels'
                                            : `Level: (${event.eligibleLevel?.from} ~ ${event.eligibleLevel?.to})`}
                                    </span>
                                )}
                            </div>
                            <CmtyEventRegCount event={event} className="mb-3" />
                            {event.canDisplayMap() && (
                                <CmtyEventPlaceMap
                                    className="border flex-grow mb-3"
                                    location={(event.location as CmtyEventLocation).location}
                                />
                            )}
                            {event.isRequireLevel() && event.canReg(user) && (
                                <TextBox
                                    className="w-100 mb-3"
                                    name="gameLevel"
                                    label="Game Level"
                                    placeholder="3.5"
                                    defaultValue=""
                                    type="number"
                                    validation={{
                                        register,
                                        formState,
                                        rules: {
                                            required: true,
                                            min: 1,
                                        },
                                    }}
                                />
                            )}
                            {event.isCanRegMultiWeeks(user) && (
                                <>
                                    <div className="mb-1">
                                        <TextBox
                                            className="w-100"
                                            name="repeatWeeks"
                                            label={
                                                <label
                                                    htmlFor="repeatWeeks"
                                                    className="block text-xs font-medium text-gray-700 mb-1 text-left"
                                                >
                                                    How many weeks will you attend in the future?
                                                    <span className="text-red-500">(Max: {event.repeatWeeks})</span>
                                                </label>
                                            }
                                            placeholder="1"
                                            defaultValue=""
                                            type="number"
                                            validation={{
                                                register,
                                                formState,
                                                rules: {
                                                    required: true,
                                                    max: event.repeatWeeks,
                                                    min: 1,
                                                },
                                            }}
                                        />
                                        {Number(watchFields[0]) < 2 ? (
                                            <small className="text-xs text-emerald-600">
                                                You can attent at only this event
                                            </small>
                                        ) : (
                                            <small className="text-xs text-emerald-600">
                                                You can attend at this event from{' '}
                                                {moment(event.start).format('MMM DD, YYYY')} to{' '}
                                                {moment(event.start)
                                                    .add(Number(watchFields[0]) - 1, 'weeks')
                                                    .format('MMM DD, YYYY')}
                                            </small>
                                        )}
                                    </div>
                                    {Number(watchFields[0]) > 1 && !event.isFree && event.isWeeklyPay && (
                                        <Select
                                            className=" col-span-1"
                                            name="payType"
                                            placeholder="Pay week by week"
                                            options={generatePayTypeOptions()}
                                            defaultValue=""
                                            validation={{
                                                control,
                                                formState,
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {event.isFree && event.canReg(user) && (
                                <>
                                    <label
                                        htmlFor="donatedAmount"
                                        className="block text-xs font-medium text-gray-700 mb-1 text-left"
                                    >
                                        Would you like to donate to {(event.club as Club).displayName} to support its
                                        work?
                                    </label>
                                    <div className="flex items-center">
                                        <div className="text-sm mr-2">Donate ($)</div>
                                        <TextBox
                                            className=" flex-grow"
                                            name="donatedAmount"
                                            placeholder="9"
                                            defaultValue=""
                                            type="number"
                                            validation={{
                                                register,
                                                formState,
                                                rules: {
                                                    required: false,
                                                    min: 1,
                                                },
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </form>
                <div className="p-4 md:p-6 bg-gray-50 rounded-b-md flex justify-between">
                    {event.canEditGuests(user) ? (
                        <Button onClick={() => setIsOpenGuets(true)}>
                            {guests.length > 0 ? `Edit Guests (${guests.length})` : 'Add Guests'}
                        </Button>
                    ) : (
                        <div />
                    )}
                    <div className="flex justify-end">
                        <Button
                            textColor="text-gray-700"
                            className="border border-gray-300"
                            color="bg-white"
                            hoverColor="bg-gray-50"
                            onClick={() => {
                                dispatch(toggleCmtyEventDetailModal());
                            }}
                        >
                            Close
                        </Button>
                        {event.canPay(user) && (
                            <Button className="ml-2" onClick={handleSubmit(payAndConfirm)}>
                                Pay and Confirm
                            </Button>
                        )}
                        {event.canUnReg(user) && (
                            <Button
                                className="ml-2"
                                color="bg-amber-600"
                                hoverColor="bg-amber-700"
                                onClick={handleSubmit(unRegisterEvent)}
                            >
                                UnRegister for Event
                            </Button>
                        )}
                        {event.canReg(user) && (
                            <Button className="ml-2" onClick={handleSubmit(registerEvent)}>
                                {!event.isFull()
                                    ? event.shouldPay()
                                        ? 'Register for Event and Pay'
                                        : 'Register for Event'
                                    : 'Register to Waitlist'}
                            </Button>
                        )}
                    </div>
                </div>
            </Modal>
            <CmtyEventRegResultModal result={regResult} />
            <CmtyEventGuestsModal
                title={guests.length > 0 ? `Edit Guests (${guests.length})` : 'Add Guests'}
                isOpen={isOpenGuests}
                event={event}
                toggle={() => setIsOpenGuets(false)}
                guests={guests}
                maxCount={event.maxGuestsCount}
                callback={event.canReg(user) ? setGuests : readEvent}
            />
        </>
    );
};

export default CmtyEventDetailModal;
