import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './index.scss';

const HomeOrganizations: React.FC = () => {
    return (
        <div className="w-full h-auto white bg-gray-50 px-4 lg:px-32 py-20" id="participating-organizations">
            <p className="text-center text-emerald-600 font-bold mb-5 text-xl">Customers</p>
            <h2 className="text-gray-800 text-4xl lg:text-5xl text-center font-bold pl-4 lg:pl-10 mb-20">
                Participating Organizations
            </h2>
            <Swiper
                slidesPerView={1}
                breakpoints={{
                    640: {
                        width: 640,
                        slidesPerView: 3,
                    },
                    768: {
                        width: 768,
                        slidesPerView: 4,
                    },
                    1024: {
                        width: 1024,
                        slidesPerView: 5,
                    },
                    1229: {
                        width: 1229,
                        slidesPerView: 5,
                    },
                }}
                spaceBetween={30}
                loop={true}
                className="organizations-carousel w-full h-60"
            >
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/1.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/2.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/3.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/4.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/5.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/6.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/7.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/8.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/9.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
                <SwiperSlide className="pb-4 flex justify-center items-center">
                    <img
                        src="/images/logos/10.png"
                        alt="about us carousel"
                        className="border shadow-lg rounded-xl w-full h-full object-cover"
                    />
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default HomeOrganizations;
