import constants from 'utils/constants';
import { Club, ClubModel } from './club';
import { CmtyEventEligibleLevel, CmtyEventLocation, CmtyEventLocationModel } from './cmtyevent';
import { City } from './sys-setting';
import { User, UserModel } from './user';

export type CmtyEventIdeaModel = {
    _id: string;
    user: UserModel | string;
    title: string;
    description: string;
    apxStart: string;
    apxEnd: string;
    eventType: string;
    isMatchEvent?: boolean;
    venue: string;
    ageRanges: string[];
    isFree: boolean;
    price: number;
    eligibleGender: string;
    eligibleLevel?: CmtyEventEligibleLevel;
    expireDate?: string;
    club?: ClubModel | string;
    location?: CmtyEventLocationModel | string;
    url?: string;
    maxRegCount: number;
    likeMembers: UserModel[];
    createdAt?: string;
    contactEmail?: string;
    contactNumber?: string;
    quoteCount?: number;
    status: string;
};

export class CmtyEventIdea {
    _id: string;
    user: User | string;
    title: string;
    description: string;
    apxStart: string;
    apxEnd: string;
    eventType: string;
    isMatchEvent: boolean;
    venue: string;
    ageRanges: string[];
    isFree: boolean;
    price: number;
    eligibleGender: string;
    eligibleLevel?: CmtyEventEligibleLevel;
    expireDate?: string;
    zipCode?: string;
    maxRegCount: number;
    city?: string;
    club?: Club | string;
    location?: CmtyEventLocation | string;
    url?: string;
    likeMembers: User[];
    createdAt?: string;
    contactEmail?: string;
    contactNumber?: string;
    quoteCount?: number;
    status: string;

    constructor(data: CmtyEventIdeaModel) {
        this._id = data._id;
        this.user = typeof data.user === 'string' ? data.user : new User(data.user);
        this.apxStart = data.apxStart;
        this.apxEnd = data.apxEnd;
        this.eventType = data.eventType;
        this.isMatchEvent = data.isMatchEvent ?? false;
        this.venue = data.venue;
        this.description = data.description;
        this.title = data.title;
        this.ageRanges = data.ageRanges;
        this.isFree = data.isFree ?? true;
        this.price = data.price || 0;
        this.eligibleGender = data.eligibleGender;
        this.eligibleLevel = data.eligibleLevel;
        this.expireDate = data.expireDate;
        this.maxRegCount = data.maxRegCount || 100;
        this.club = data.club ? (typeof data.club === 'string' ? data.club : new Club(data.club)) : undefined;
        this.location = data.location
            ? typeof data.location === 'string'
                ? data.location
                : new CmtyEventLocation(data.location)
            : undefined;
        this.url = data.url;
        this.likeMembers = (data.likeMembers || []).map((member) => new User(member));
        this.status = data.status || constants.CMTYEVENT_IDEA_STATUS.PUBLISHED;
        this.contactEmail = data.contactEmail;
        this.contactNumber = data.contactNumber;
        this.quoteCount = data.quoteCount || 0;
        this.createdAt = data.createdAt;
    }

    getUser(): User | null {
        if (typeof this.user !== 'string') return this.user;
        return null;
    }

    getUserId(): string | undefined {
        return typeof this.user === 'string' ? this.user : this.getUser()?._id;
    }

    getClub(): Club | null {
        if (this.club && typeof this.club !== 'string') return this.club;
        return null;
    }

    getClubId(): string | undefined {
        return typeof this.club === 'string' ? this.club : this.getClub()?._id;
    }

    getLocation(): CmtyEventLocation | null {
        if (this.location && typeof this.location !== 'string') return this.location;
        return null;
    }

    getLocationId(): string | undefined {
        return typeof this.location === 'string' ? this.location : this.getLocation()?._id;
    }

    isMyLikeIdea(user?: User | null): boolean {
        if (!user) return false;
        return this.likeMembers.some((member) => member._id === user._id);
    }

    isMyIdea(user?: User | null): boolean {
        if (!user) return false;
        if (typeof this.user === 'string') return this.user === user._id;
        else return (this.user as User)._id === user._id;
    }

    canCanceled(user?: User | null): boolean {
        if (!this.isMyIdea(user)) return false;
        return [constants.CMTYEVENT_IDEA_STATUS.PENDING, constants.CMTYEVENT_IDEA_STATUS.PUBLISHED].includes(
            this.status
        );
    }
    isAllEligibleLevel = () => {
        return this.eligibleLevel?.from === 0 && this.eligibleLevel?.to === 1000;
    };
}

export type IdeaQuoteModel = {
    _id: string;
    club: ClubModel;
    idea: string;
    message: string;
    contactEmail: string;
    contactNumber?: string;
    createdAt: string;
};

export class IdeaQuote {
    _id: string;
    club: Club;
    idea: string;
    message: string;
    contactEmail: string;
    contactNumber?: string;
    createdAt: string;

    constructor(data: IdeaQuoteModel) {
        this._id = data._id;
        this.club = new Club(data.club);
        this.idea = data.idea;
        this.message = data.message;
        this.contactEmail = data.contactEmail;
        this.contactNumber = data.contactNumber;
        this.createdAt = data.createdAt;
    }
}

export type EventIdeaFilterOptions = {
    title?: string;
    zipCode?: string;
    city?: City;
    club?: Club;
    stDate?: string;
    etDate?: string;
    eventType?: string;
    ageRanges?: string;
    feeType?: string;
    venue?: string;
    eligibleGender?: string;
    eligibleLevel?: string;
};
