import Loader from 'components/utils/Loader';
import { CmtyEventIdea } from 'utils/types';
import CmtyEventIdeaListItem from './ListItem';

type PropsType = {
    ideas: CmtyEventIdea[];
    loading?: boolean;
    onLike?: (event: CmtyEventIdea) => void;
    onEdit?: (event: CmtyEventIdea) => void;
    onCancel?: (event: CmtyEventIdea) => void;
};

const CmtyEventIdeaList = ({ ideas, onLike, onEdit, onCancel, loading = false }: PropsType) => {
    return (
        <div className=" relative min-h-96">
            {loading && (
                <div className=" absolute inset-0 bg-white opacity-70 z-10 flex justify-center pt-10">
                    <Loader size={8} />
                </div>
            )}
            {ideas.map((idea, index) => (
                <CmtyEventIdeaListItem key={index} idea={idea} onEdit={onEdit} onLike={onLike} onCancel={onCancel} />
            ))}
        </div>
    );
};

export default CmtyEventIdeaList;
