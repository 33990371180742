import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';

const error = createSlice({
    name: 'error',
    initialState: {},
    reducers: {
        handleHTTPError: (state, action: PayloadAction<any>) => {
            const error = action.payload;
            if (error.response?.status === 401) {
                // invalid or expired access token without refresh token
                clearStorageAndGoHome();
            } else if (error.response?.status === 403 && !error.response?.data?.message) {
                // invalid or expired refresh token
                clearStorageAndGoHome();
            } else if (error.response?.status === 410) {
                window.location.href = '/maintenance';
            } else if (error.response?.status === 350) {
                window.location.href = '/';
            } else {
                const message = error.response?.data ? error.response?.data?.message || 'Unknow Error' : String(error);
                const type = error.response?.status < 400 ? 'warning' : 'error';
                if (type === 'error') toast.error(message, { duration: 3000 });
                else toast.error(message, { duration: 3000, icon: 'icon' });
            }
        },
    },
});

const clearStorageAndGoHome = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = '/';
};

export const { handleHTTPError } = error.actions;
export default error.reducer;
