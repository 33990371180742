import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import './index.scss';
import Button from 'components/form-controls/Button';
import HomeSearchPanel from '../search-panel';

const HomeCarousel: React.FC = () => {
    const SlideOne = () => {
        return (
            <div className="w-full h-full overflow-hidden flex items-center justify-center">
                <img src="/images/carousel/1.jpg" className="w-full h-screen min-w-fit opacity-70" alt="carousel one" />
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="max-w-7xl text-center">
                        <h2 className="text-white text-3xl lg:text-6xl font-bold lg:leading-20 text-center mb-10">
                            Platform that Allows Community Residents to Belong and Be Engaged
                        </h2>
                        <p className=" text-white text-xl lg:text-4xl text-center mb-20">
                            When a member joins one community, they become part of all communities.
                        </p>
                        <Button
                            className=" bg-black bg-opacity-50 border-2 rounded-md border-white text-white text-xl lg:text-2xl px-10"
                            onClick={goToRegOrganization}
                        >
                            Register Organization
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const SlideTwo = () => {
        return (
            <div className="w-full h-full overflow-hidden flex items-center">
                <img src="/images/carousel/2.jpg" className="w-full h-screen min-w-fit opacity-70" alt="carousel two" />
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="max-w-7xl text-center">
                        <h2 className="text-white text-3xl lg:text-6xl font-bold lg:leading-20 text-center mb-10">
                            Empowers Community Connection and Makes Your Community Stronger
                        </h2>
                        <p className=" text-white text-xl lg:text-4xl text-center mb-20">
                            Solicits and enables your community members to propose ideas for activities that can then be
                            converted automatically into events
                        </p>
                        <Button
                            className=" bg-black bg-opacity-50 border-2 rounded-md border-white text-white text-xl lg:text-2xl px-10"
                            onClick={goToRegOrganization}
                        >
                            Register Organization
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const SlideThree = () => {
        return (
            <div className="w-full h-full overflow-hidden flex items-center">
                <img
                    src="/images/carousel/3.jpg"
                    className="w-full h-screen min-w-fit opacity-70"
                    alt="carousel three"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="max-w-7xl text-center">
                        <h2 className="text-white text-3xl lg:text-6xl font-bold lg:leading-20 text-center mb-10">
                            Allow Social and Competitive Events to Grow
                        </h2>
                        <p className=" text-white text-xl lg:text-4xl text-center mb-20">
                            Enables community residents to view, register, and pay for activities in neighboring
                            communities and far away places. ​
                        </p>
                        <Button
                            className=" bg-black bg-opacity-50 border-2 rounded-md border-white text-white text-xl lg:text-2xl px-10"
                            onClick={goToRegOrganization}
                        >
                            Register Organization
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const SlideFour = () => {
        return (
            <div className="w-full h-full overflow-hidden flex items-center">
                <img
                    src="/images/carousel/4.jpg"
                    className="w-full h-screen min-w-fit opacity-70"
                    alt="carousel four"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="max-w-7xl text-center">
                        <h2 className="text-white text-3xl lg:text-6xl font-bold lg:leading-20 text-center mb-10">
                            Engage local businesses to sponsor events​
                        </h2>
                        <p className=" text-white text-xl lg:text-4xl text-center mb-20">
                            Elicits engagement by volunteers to promote and manage on behalf of organizations, such as
                            parks and recreations​
                        </p>
                        <Button
                            className=" bg-black bg-opacity-50 border-2 rounded-md border-white text-white text-xl lg:text-2xl px-10"
                            onClick={goToRegOrganization}
                        >
                            Register Organization
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const goToRegOrganization = () => {
        window.open(`${process.env.REACT_APP_ADMIN_URL}/signup`);
    };

    return (
        <div className="w-full h-screen bg-black">
            <Swiper
                navigation={true}
                modules={[Navigation, Autoplay]}
                loop={true}
                allowTouchMove={false}
                autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                }}
                className="home-carousel"
            >
                <SwiperSlide>
                    <SlideOne />
                </SwiperSlide>
                <SwiperSlide>
                    <SlideTwo />
                </SwiperSlide>
                <SwiperSlide>
                    <SlideThree />
                </SwiperSlide>
                <SwiperSlide>
                    <SlideFour />
                </SwiperSlide>
            </Swiper>
            <HomeSearchPanel className="hidden lg:flex" />
        </div>
    );
};

export default HomeCarousel;
