import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

interface LayoutProps {
    children?: ReactNode;
}

const ScrollTop: FC<LayoutProps> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return <>{children}</>;
};
export default ScrollTop;
