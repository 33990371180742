import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import API_URL from './apiurl';
import constants from 'utils/constants';
import { isJsonString } from 'utils';
import { AuthApi } from 'apis';

declare module 'axios' {
    export interface AxiosRequestConfig {
        _retry?: boolean;
    }
}

axios.interceptors.request.use<AxiosRequestConfig>((config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token') || '';
    return {
        ...config,
        baseURL: API_URL.BASE_URL,
        timeout: 30000,
        headers: {
            authorization: `Bearer ${token}`,
            versionNumber: constants.VERSION_NUMBER,
        },
    };
});

axios.interceptors.response.use<AxiosResponse>(
    (response: AxiosResponse) => {
        if (response?.data?.code && response?.data?.code !== 200) {
            return Promise.reject({
                response: {
                    status: response?.data?.code,
                    data: {
                        message: response?.data?.message || '',
                    },
                },
            });
        }

        return response;
    },
    async (error: AxiosError) => {
        const originalRequest = error.config;
        if (!error.response)
            return Promise.reject({
                response: {
                    status: 408,
                    data: {
                        message: 'Request Timeout',
                    },
                },
            });
        else if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            if (originalRequest.data && typeof originalRequest.data === 'string' && isJsonString(originalRequest.data))
                originalRequest.data = JSON.parse(originalRequest.data);
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) return Promise.reject(error);
            const { token } = await AuthApi.refreshAccessToken({ refreshToken });
            localStorage.setItem('token', token || '');
            axios.defaults.headers.common.token = token || '';
            return axios(originalRequest);
        }

        return Promise.reject(error);
    }
);

export default axios;
