import { AuthApi } from 'apis';
import Button from 'components/form-controls/Button';
import { handleHTTPError } from 'store/error';
import { useAppDispatch } from 'store/hook';
import { User, UserModel } from 'utils/types';
import { PencilIcon, PlusIcon, TrashIcon, UserCircleIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import EditSubAccountModal from './EditSubAccountModal';
import { setCommonLoading, showAlert, showAlertModal } from 'store/ui';

const SubAccounts = () => {
    const dispatch = useAppDispatch();
    const [subAccounts, setSubAccounts] = useState<User[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<User | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchSubAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSubAccounts = () => {
        AuthApi.retrieveSubAccounts({})
            .then((data) => {
                setSubAccounts(data.data.map((account: UserModel) => new User(account)));
            })
            .catch((error) => {
                dispatch(handleHTTPError(error));
            });
    };

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const addSubAccount = () => {
        setSelectedAccount(null);
        toggle();
    };

    const editSubAccount = (account: User) => {
        setSelectedAccount(account);
        toggle();
    };

    const deleteSubAccount = (account: User) => {
        dispatch(
            showAlertModal({
                type: 'warning',
                title: 'Delete Sub Account',
                message: 'Do you want to delete this sub account? \n Are you sure?',
                buttons: [
                    {
                        type: 'ok',
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        type: 'normal',
                        label: 'No',
                        value: 'no',
                    },
                ],
                handler: (value: string) => {
                    if (value === 'no') return;
                    const params = {
                        _id: account._id,
                    };
                    dispatch(setCommonLoading(true));
                    AuthApi.delete(params)
                        .then((data) => {
                            dispatch(setCommonLoading(false));
                            dispatch(showAlert({ type: 'success', message: data.message }));
                            fetchSubAccounts();
                        })
                        .catch((error) => {
                            dispatch(setCommonLoading(false));
                            dispatch(handleHTTPError(error));
                        });
                },
            })
        );
    };

    return (
        <div className="py-6">
            <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 md:col-span-1 flex flex-col">
                    <h5 className="text-xl text-gray-800">Sub Accounts</h5>
                    <p className="text-gray-500 text-sm mb-4">Manage sub accounts</p>
                </div>
                <div className="bg-white col-span-3 md:col-span-2 rounded-md shadow p-4 md:p-10">
                    <div className="flex justify-end">
                        <Button
                            textColor="text-gray-700"
                            className="border border-gray-300"
                            color="bg-white"
                            hoverColor="bg-gray-50"
                            onClick={addSubAccount}
                        >
                            <>
                                <PlusIcon className="w-4 h-4 inline-block" /> Sub Account
                            </>
                        </Button>
                    </div>
                    {subAccounts.map((account, index) => (
                        <div className="flex justify-between items-center py-4 border-b" key={index}>
                            <div className="flex items-center">
                                {account?.photoUrl ? (
                                    <img
                                        src={account?.photoUrl}
                                        alt="sidebar-logo"
                                        className=" w-14 h-14 mx-auto border border-gray-300 rounded-full"
                                    />
                                ) : (
                                    <UserCircleIcon className="w-14 h-14 text-gray-500" />
                                )}
                                <div className="ml-3">
                                    <p className="text-gray-700 text-base mb-0">
                                        {account.firstName} {account.lastName}
                                    </p>
                                    <p className="text-gray-600 text-sm">{account.email}</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <TrashIcon
                                    className="w-6 h-6 text-red-600 block mr-4 cursor-pointer"
                                    onClick={() => deleteSubAccount(account)}
                                />
                                <PencilIcon
                                    className="w-6 h-6 text-amber-600 block cursor-pointer"
                                    onClick={() => editSubAccount(account)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <EditSubAccountModal
                account={selectedAccount}
                toggle={toggle}
                isOpen={isOpen}
                callback={fetchSubAccounts}
            />
        </div>
    );
};

export default SubAccounts;
