import { InvoiceApi } from 'apis';
import Pagination from 'components/utils/Pagination';
import { useCallback, useEffect, useState } from 'react';
import { handleHTTPError } from 'store/error';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setCommonLoading } from 'store/ui';
import PageContentLayout from 'layouts/PageContentLayout';
import { Invoice } from 'utils/types/models/invoice';
import moment from 'moment';
import { classNames } from 'utils';
import constants from 'utils/constants';
import Button from 'components/form-controls/Button';
import InvoiceNoteModal from 'components/invoices/invoice-submit-modal';

const Invoices = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);

    const fetchInvoices = useCallback(async (skip = 0) => {
        try {
            const params = {
                user: user?._id,
                limit: 10,
                skip,
                sort_by: 'createdAt',
                order_by: 'desc',
            };
            dispatch(setCommonLoading(true));
            const { data } = await InvoiceApi.retrieve(params);
            dispatch(setCommonLoading(false));
            setInvoices(data.map((x: any) => x as Invoice));
            setTotalCount(data.totalCount || 0);
            setCurrentPage(Math.floor(params.skip / 10));
        } catch (error) {
            dispatch(setCommonLoading(false));
            dispatch(handleHTTPError(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchInvoices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const modalCallback = () => {
        fetchInvoices(currentPage);
    };

    return (
        <PageContentLayout scrollTop={false}>
            <table className="w-full">
                <thead className=" bg-gray-50 border-b">
                    <tr>
                        <th className="font-medium p-4 text-left">Invoice Number</th>
                        <th className="font-medium p-4 text-left">Event Date</th>
                        <th className="font-medium p-4 text-left">Event Name</th>
                        <th className="font-medium p-4 text-left">Attendees</th>
                        <th className="font-medium p-4 text-left">subTotal</th>
                        <th className="font-medium p-4 text-left">Fee</th>
                        <th className="font-medium p-4 text-left">Amount</th>
                        <th className="font-medium p-4 text-left">Status</th>
                        <th className="font-medium p-4 text-left">Due / Paid Date</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((invoice, index) => (
                        <tr
                            key={invoice._id}
                            className={classNames(index % 2 === 0 ? 'bg-white' : 'bg-gray-50', 'border-b')}
                        >
                            <td className="text-sm font-medium p-4">{invoice.invoiceNumber}</td>
                            <td className="text-sm font-medium p-4">
                                {moment(invoice.referenceEvent.start).format('ddd, MMM DD, YYYY')}{' '}
                                {moment(invoice.referenceEvent.start).format('hh:mm A')} ~{' '}
                                {moment(invoice.referenceEvent.end).format('hh:mm A')}
                            </td>
                            <td className="text-sm font-medium p-4">{invoice.referenceEvent.title}</td>
                            <td className="text-sm font-medium p-4">{invoice.invoiceItems[0]?.attendees}</td>
                            <td className="text-sm font-medium p-4">$ {invoice.subTotal}</td>
                            <td className="text-sm font-medium p-4">$ {invoice.applicationFee + invoice.stripeFee}</td>
                            <td className="text-sm font-medium p-4">$ {invoice.total}</td>
                            <td
                                className={classNames(
                                    'text-sm font-medium p-4 uppercase',
                                    invoice.status === constants.INVOICE_STATUS.CREATED
                                        ? 'text-indigo-600'
                                        : invoice.status === constants.INVOICE_STATUS.PENDING
                                        ? 'text-emerald-400'
                                        : invoice.status === constants.INVOICE_STATUS.PAID
                                        ? 'text-emerald-600'
                                        : ''
                                )}
                            >
                                {invoice.status}
                            </td>
                            <td>
                                {invoice.status === constants.INVOICE_STATUS.DRAFT && (
                                    <Button
                                        className="bg-emerald-600 hover:bg-emerald-700 h-10"
                                        onClick={() => {
                                            setSelectedInvoice(invoice);
                                            toggle();
                                        }}
                                    >
                                        Send Invoice
                                    </Button>
                                )}
                                {(invoice.status === constants.INVOICE_STATUS.CREATED ||
                                    invoice.status === constants.INVOICE_STATUS.PENDING) && (
                                    <div className="text-sm font-medium p-4">
                                        {moment(invoice.dueDate).format('MMM, DD YYYY')}
                                    </div>
                                )}
                                {invoice.status === constants.INVOICE_STATUS.PAID && (
                                    <div className="text-sm font-medium p-4">
                                        {moment(invoice.paidDate).format('MMM, DD YYYY')}
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                totalCount={totalCount}
                page={currentPage}
                pageRangeDisplayed={5}
                countPerPage={10}
                onChange={fetchInvoices}
            />
            {selectedInvoice && (
                <InvoiceNoteModal isOpen={isOpen} toggle={toggle} invoice={selectedInvoice} callback={modalCallback} />
            )}
        </PageContentLayout>
    );
};

export default Invoices;
