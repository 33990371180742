import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { CmtyEventFilterOptions, EventIdeaFilterOptions } from 'utils/types';
import { AuthState, login, logout } from './auth';

type CriteriaState = {
    cmtyEventFilterOptions: CmtyEventFilterOptions;
    eventIdeaFilterOptions: EventIdeaFilterOptions;
};

const initialState: CriteriaState = {
    cmtyEventFilterOptions: {
        stDate: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
        etDate: moment().add(1, 'months').endOf('day').format('YYYY-MM-DD HH:mm'),
    },
    eventIdeaFilterOptions: {
        stDate: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
        etDate: moment().add(1, 'months').endOf('day').format('YYYY-MM-DD HH:mm'),
    },
};

const criteria = createSlice({
    name: 'criteria',
    initialState,
    reducers: {
        putCmtyEventFilterOptions: (state, action: PayloadAction<CmtyEventFilterOptions>) => {
            state.cmtyEventFilterOptions = action.payload;
        },
        putEventIdeaFilterOptions: (state, action: PayloadAction<EventIdeaFilterOptions>) => {
            state.eventIdeaFilterOptions = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login, (state, action: PayloadAction<AuthState>) => {
            state.cmtyEventFilterOptions = {
                ...state.cmtyEventFilterOptions,
                zipCode: action.payload?.user?.getZipCodePrefix() || '',
            };
            state.eventIdeaFilterOptions = {
                ...state.cmtyEventFilterOptions,
                zipCode: action.payload?.user?.getZipCodePrefix() || '',
            };
        });
        builder.addCase(logout, (state) => {
            state.cmtyEventFilterOptions = {
                stDate: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                etDate: moment().add(1, 'months').endOf('day').format('YYYY-MM-DD HH:mm'),
            };
            state.eventIdeaFilterOptions = {
                stDate: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                etDate: moment().add(1, 'months').endOf('day').format('YYYY-MM-DD HH:mm'),
            };
        });
    },
});

export const { putCmtyEventFilterOptions, putEventIdeaFilterOptions } = criteria.actions;
export default criteria.reducer;
