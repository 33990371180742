import { DownloadIcon, UserAddIcon } from '@heroicons/react/outline';
import { CmtyEventApi } from 'apis';
import AutoMatchGenModal from 'components/cmtyevents/automatch-generate-modal';
import Button from 'components/form-controls/Button';
import RadioBox from 'components/form-controls/RadioBox';
import Pagination from 'components/utils/Pagination';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { handleHTTPError } from 'store/error';
import { useAppDispatch } from 'store/hook';
import { setCommonLoading, showAlertModal } from 'store/ui';
import { classNames, downloadCSV } from 'utils';
import constants from 'utils/constants';
import { CmtyEvent, CmtyEventRegUser, CmtyEventRegUserModel, User } from 'utils/types';
import EventRegModal from './EventRegModal';

const countPerPage = 20;

const EventRegUsers = ({ event }: { event: CmtyEvent }) => {
    const dispatch = useAppDispatch();
    const [totalRegUsers, setTotalRegUsers] = useState<CmtyEventRegUser[]>([]);
    const [regUsers, setRegusers] = useState<CmtyEventRegUser[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isWaitList, setIsWaitList] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAutoMatch, setIsOpenAutoMatch] = useState(false);

    const search = useCallback(() => {
        const params = {
            event: event._id,
            'status.ne': constants.CMTYEVENT_REGUSER_STATUS.CANCELED,
        };
        CmtyEventApi.retrieveRegUsers(params)
            .then((data) => {
                const totalRegUsers = ((data.data as CmtyEventRegUserModel[]) || []).map(
                    (regUser) => new CmtyEventRegUser(regUser)
                );
                setTotalRegUsers(totalRegUsers);
            })
            .catch((error) => {
                dispatch(handleHTTPError(error));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event._id]);

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPageData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWaitList, totalRegUsers]);

    const sortScore = (regUser: CmtyEventRegUser) => {
        let score = 0;
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.REGISTERED) {
            score = 100;
            if (regUser.isSelected) score = 200;
        }
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED) score = 300;
        return score;
    };

    const setPageData = (page?: number) => {
        if (!page) page = currentPage;
        const subRegUsers = totalRegUsers
            .filter((regUser) => regUser.isWaitList === isWaitList)
            .sort((a, b) => (sortScore(a) > sortScore(b) ? -1 : sortScore(a) < sortScore(b) ? 1 : 0));
        const totalCount = subRegUsers.length;
        const regUsers = subRegUsers.splice(page * 10, 10);
        setTotalRegUsers(totalRegUsers);
        setRegusers(regUsers);
        setTotalCount(totalCount);
    };

    const pageChange = (page: number) => {
        setCurrentPage(page);
        setPageData(page);
    };

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const toggleAutoMatch = () => {
        setIsOpenAutoMatch(!isOpenAutoMatch);
    };

    const getStatus = (regUser: CmtyEventRegUser) => {
        if (regUser.isWaitList) return 'Wait';
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.REGISTERED) {
            if (event.isMatchEvent && regUser.isSelected) return 'Waiting for Payment';
            return 'Registered';
        }
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED) return 'Confirmed';
        return '';
    };

    const getStatusClass = (regUser: CmtyEventRegUser) => {
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.REGISTERED) {
            if (event.isMatchEvent && regUser.isSelected) return 'text-emerald-500';
            return 'text-amber-600';
        }
        if (regUser.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED) return 'text-emerald-600';
        return '';
    };

    const update = (regUser: CmtyEventRegUser, isWaitList: boolean) => {
        dispatch(
            showAlertModal({
                type: 'warning',
                title: `Move to ${isWaitList ? 'Waitlist' : 'List'}`,
                message: `Do you want to move this user to ${isWaitList ? 'Waitlist' : 'List'}?`,
                buttons: [
                    {
                        type: 'ok',
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        type: 'cancel',
                        label: 'No',
                        value: 'no',
                    },
                ],
                handler: (value: string) => {
                    if (value === 'yes') {
                        dispatch(setCommonLoading(true));
                        const params = { _id: regUser._id, isWaitList };
                        CmtyEventApi.updateRegUser(params)
                            .then((data) => {
                                dispatch(setCommonLoading(false));
                                const newRegUser = new CmtyEventRegUser(data.data as CmtyEventRegUserModel);
                                const newRegUsers = [...totalRegUsers];
                                const index = newRegUsers.findIndex((rgUser) => rgUser._id === regUser._id);
                                newRegUsers.splice(index, 1, newRegUser);
                                setTotalRegUsers(newRegUsers);
                            })
                            .catch((error) => {
                                dispatch(setCommonLoading(false));
                                dispatch(handleHTTPError(error));
                            });
                    }
                },
            })
        );
    };

    const onRequestPayment = (regUser: CmtyEventRegUser, flag = true) => {
        dispatch(
            showAlertModal({
                type: 'warning',
                title: flag ? 'Payment Request' : 'Cancel Payment Request',
                message: flag
                    ? 'This means this member is selected as a player for the match event. \nWe will request payment from this member.'
                    : 'Do you want to cancel payment request.',
                buttons: [
                    {
                        type: 'ok',
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        type: 'cancel',
                        label: 'No',
                        value: 'no',
                    },
                ],
                handler: (value: string) => {
                    if (value === 'yes') {
                        dispatch(setCommonLoading(true));
                        const params = { _id: regUser._id, isSelected: flag };
                        CmtyEventApi.updateRegUser(params)
                            .then((data) => {
                                dispatch(setCommonLoading(false));
                                const newRegUser = new CmtyEventRegUser(data.data as CmtyEventRegUserModel);
                                const newRegUsers = [...totalRegUsers];
                                const index = newRegUsers.findIndex((rgUser) => rgUser._id === regUser._id);
                                newRegUsers.splice(index, 1, newRegUser);
                                setTotalRegUsers(newRegUsers);
                            })
                            .catch((error) => {
                                dispatch(setCommonLoading(false));
                                dispatch(handleHTTPError(error));
                            });
                    }
                },
            })
        );
    };

    const deleteRegUser = (regUser: CmtyEventRegUser) => {
        dispatch(
            showAlertModal({
                type: 'warning',
                title: `Delete ${regUser.isWaitList ? 'Waitlist Member' : 'Registered Member'}`,
                message: 'Do you want to cancel registration of this user?',
                buttons: [
                    {
                        type: 'ok',
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        type: 'cancel',
                        label: 'No',
                        value: 'no',
                    },
                ],
                handler: (value: string) => {
                    if (value === 'yes') {
                        dispatch(setCommonLoading(true));
                        const params = { _id: regUser._id };
                        CmtyEventApi.unRegUser(params)
                            .then(() => {
                                dispatch(setCommonLoading(false));
                                const newRegUsers = [...totalRegUsers];
                                const index = newRegUsers.findIndex((rgUser) => rgUser._id === regUser._id);
                                newRegUsers.splice(index, 1);
                                setTotalRegUsers(newRegUsers);
                            })
                            .catch((error) => {
                                dispatch(setCommonLoading(false));
                                dispatch(handleHTTPError(error));
                            });
                    }
                },
            })
        );
    };

    const download = () => {
        const regUserData = regUsers
            .filter((regUser) => !regUser.isWaitList)
            .map((regUser, index) => {
                const member = regUser.user as User;
                let row: any = {
                    No: index + 1,
                    Name: member.fullName,
                    Gender: member.getGender(),
                    Age: member.age,
                };
                if (event.isRequireLevel()) row = { ...row, Level: regUser.gameLevel };
                if (event.allowGuests) row = { ...row, Guests: regUser.guestsCount || 0 };
                row = {
                    ...row,
                    Confirm:
                        regUser.status === constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED
                            ? regUser.isArrived
                                ? 'Checked In'
                                : 'Confirmed'
                            : 'UnConfirmed',
                };
                return row;
            });
        downloadCSV(regUserData, `CmtyEvent(${event.title})-(${moment(event.start).format('MMM DD')})`);
    };

    return (
        <div className="grid grid-cols-7 gap-6 mt-10">
            <div className="col-span-7 lg:col-span-3 flex justify-around items-center">
                <RadioBox
                    id="eligible"
                    name="isWaitList"
                    checked={!isWaitList}
                    label="Eligible Members"
                    onChange={() => {
                        setIsWaitList(false);
                    }}
                />
                <RadioBox
                    id="waitlist"
                    name="isWaitList"
                    checked={isWaitList}
                    label="WaitList Members"
                    onChange={() => {
                        setIsWaitList(true);
                    }}
                />
            </div>
            <div className="col-span-7 lg:col-span-4 flex justify-around lg:justify-end">
                {regUsers.length > 1 && (
                    <Button className="mr-3" onClick={toggleAutoMatch}>
                        <>
                            <UserAddIcon className="w-4 h-5 mr-3 inline-block" />
                            <span>Auto Match Generate</span>
                        </>
                    </Button>
                )}
                <Button className="mr-3" onClick={toggle}>
                    <>
                        <UserAddIcon className="w-4 h-5 mr-3 inline-block" />
                        <span>Register Member</span>
                    </>
                </Button>
                <Button onClick={download}>
                    <>
                        <DownloadIcon className="w-4 h-5 mr-3 inline-block" />
                        <span>Download</span>
                    </>
                </Button>
            </div>
            <div className="col-span-7 border rounded-md overflow-auto">
                <table className="w-full min-w-112">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">No</th>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Name</th>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Is Member</th>
                            {event.allowGuests && (
                                <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Guests</th>
                            )}
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Register By</th>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left">Status</th>
                            <th className=" text-sm text-gray-700 font-medium px-4 py-3 text-left"></th>
                        </tr>
                    </thead>
                    {regUsers.length === 0 ? (
                        <tbody>
                            <tr>
                                <td colSpan={6} className="text-red-600 text-center py-4">
                                    No Data
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {regUsers.map((regUser, index) => {
                                const member = regUser.user as User;
                                return (
                                    <tr
                                        key={index}
                                        className={classNames(
                                            index % 2 ? 'bg-gray-50' : 'bg-white',
                                            'border-t text-sm text-gray-600'
                                        )}
                                    >
                                        <td className=" px-4 py-3">{index + 1}</td>
                                        <td className=" px-4 py-3">
                                            {member.fullName} ({member.getGender()}
                                            {event.isMatchEvent && regUser.gameLevel ? `: ${regUser.gameLevel}` : ''})
                                        </td>
                                        <td className=" px-4 py-3">
                                            {member.isOriginClub(
                                                typeof event.club === 'string' ? event.club : event.club._id
                                            )
                                                ? 'Yes'
                                                : 'No'}
                                        </td>
                                        {event.allowGuests && <td className=" px-4 py-3">{regUser.guestsCount}</td>}
                                        <td className=" px-4 py-3 capitalize">{regUser.regBy}</td>
                                        <td className={classNames(' px-4 py-3 capitalize', getStatusClass(regUser))}>
                                            {getStatus(regUser)}
                                        </td>
                                        <td className=" px-4 py-3 capitalize flex justify-between">
                                            {regUser.isWaitList ? (
                                                <div
                                                    className="px-1 text-emerald-600 cursor-pointer hover:text-emerald-700"
                                                    onClick={() => update(regUser, false)}
                                                >
                                                    Assign To List
                                                </div>
                                            ) : (
                                                <>
                                                    {(regUser.status ===
                                                        constants.CMTYEVENT_REGUSER_STATUS.REGISTERED ||
                                                        (regUser.status ===
                                                            constants.CMTYEVENT_REGUSER_STATUS.CONFIRMED &&
                                                            regUser.regBy === 'admin')) && (
                                                        <div
                                                            className="px-1 text-amber-600 cursor-pointer hover:text-amber-700"
                                                            onClick={() => update(regUser, true)}
                                                        >
                                                            Exempt
                                                        </div>
                                                    )}
                                                    {event.isMatchEvent &&
                                                        regUser.status ===
                                                            constants.CMTYEVENT_REGUSER_STATUS.REGISTERED &&
                                                        regUser.regBy !== 'admin' && (
                                                            <div
                                                                className={classNames(
                                                                    'px-1 cursor-pointer text-center',
                                                                    regUser.isSelected
                                                                        ? 'text-amber-600 hover:text-amber-700'
                                                                        : 'text-emerald-600 hover:text-emerald-700'
                                                                )}
                                                                onClick={() => {
                                                                    onRequestPayment(regUser, !regUser.isSelected);
                                                                }}
                                                            >
                                                                {regUser.isSelected
                                                                    ? 'Cancel Payment Request'
                                                                    : 'Payment Request'}
                                                            </div>
                                                        )}
                                                </>
                                            )}

                                            <div
                                                className="px-1 text-red-500 hover:text-red-600 cursor-pointer"
                                                onClick={() => deleteRegUser(regUser)}
                                            >
                                                Delete
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </div>
            <div className="col-span-6">
                <Pagination
                    totalCount={totalCount}
                    page={currentPage}
                    pageRangeDisplayed={3}
                    countPerPage={countPerPage}
                    onChange={pageChange}
                />
            </div>
            <EventRegModal event={event} isOpen={isOpen} toggle={toggle} callback={search} />
            <AutoMatchGenModal
                event={event}
                isOpen={isOpenAutoMatch}
                toggle={toggleAutoMatch}
                regUsers={totalRegUsers.filter((x) => !x.isWaitList)}
            />
        </div>
    );
};

export default EventRegUsers;
