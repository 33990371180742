import { ReactNode } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { classNames } from 'utils';
import Loader from './Loader';
import ReactModal from 'react-modal';

type PropsType = {
    isOpen: boolean;
    toggle: () => void;
    size: string;
    title?: JSX.Element | string;
    titleClassName?: string;
    xButton?: boolean;
    loading?: boolean;
    disableOutClick?: boolean;
    headBorder?: boolean;
    children: ReactNode;
    header?: boolean;
};

const Modal = ({
    isOpen,
    toggle,
    size = 'lg',
    title = '',
    titleClassName,
    xButton = true,
    headBorder,
    loading,
    disableOutClick = false,
    children,
    header = true,
}: PropsType) => {
    ReactModal.setAppElement('#root');

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={toggle}
            contentLabel="Example Modal"
            shouldCloseOnOverlayClick={!disableOutClick}
            overlayClassName="z-40 fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 overflow-y-auto flex justify-center items-start md:items-center py-24 md:py-0"
            className={classNames(
                'bg-white rounded-lg text-left shadow-xl mx-4 sm:mx-0',
                size || 'sm:max-w-lg',
                'w-full'
            )}
        >
            <div className="">
                {header && (
                    <div
                        className={classNames(
                            'relative flex justify-between items-center px-4 sm:px-6 py-4',
                            headBorder ? 'border-b-2 border-gray-200' : ''
                        )}
                    >
                        <div
                            className={classNames(
                                'text-gray-700 text-xl font-medium whitespace-nowrap',
                                titleClassName || ''
                            )}
                        >
                            {title || ''}
                        </div>
                        {loading && (
                            <div className=" absolute top-4 right-20">
                                <Loader size={6} />
                            </div>
                        )}
                        {xButton && (
                            <button
                                className="focus:outline-none focus:ring-none focus:ring-offset-none"
                                // ref={cancelButtonRef}
                                onClick={toggle}
                            >
                                <XIcon className="w-6 h-6 text-gray-700" />
                            </button>
                        )}
                    </div>
                )}
                {children}
            </div>
        </ReactModal>

        // <Transition.Root show={isOpen} as={Fragment}>
        //     <Dialog
        //         as="div"
        //         className="fixed z-10 inset-0 overflow-y-auto"
        //         initialFocus={cancelButtonRef}
        //         onClose={() => {
        //             if (!disableOutClick) toggle();
        //         }}
        //     >
        //         <div className="min-h-screen pt-4 px-4 pb-20 text-center block p-0">
        //             <Transition.Child
        //                 as={Fragment}
        //                 enter="ease-out duration-300"
        //                 enterFrom="opacity-0"
        //                 enterTo="opacity-100"
        //                 leave="ease-in duration-200"
        //                 leaveFrom="opacity-100"
        //                 leaveTo="opacity-0"
        //             >
        //                 <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        //             </Transition.Child>
        //             {/* This element is to trick the browser into centering the modal contents. */}
        //             <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        //                 &#8203;
        //             </span>
        //             <Transition.Child
        //                 as={Fragment}
        //                 enter="ease-out duration-300"
        //                 enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        //                 enterTo="opacity-100 translate-y-0 sm:scale-100"
        //                 leave="ease-in duration-200"
        //                 leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        //                 leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        //             >
        //                 <div
        //                     className={classNames(
        //                         'relative inline-block bg-white rounded-lg text-left shadow-xl transform transition-all align-middle',
        //                         size || 'sm:max-w-lg',
        //                         'w-full'
        //                     )}
        //                 >
        //                     {header && (
        //                         <div
        //                             className={classNames(
        //                                 'flex justify-between items-center px-4 sm:px-6 py-4',
        //                                 headBorder ? 'border-b-2 border-gray-200' : ''
        //                             )}
        //                         >
        //                             <div
        //                                 className={classNames(
        //                                     'text-gray-700 text-xl font-medium',
        //                                     titleClassName || ''
        //                                 )}
        //                             >
        //                                 {title || ''}
        //                             </div>
        //                             {loading && (
        //                                 <div className=" absolute top-4 right-20">
        //                                     <Loader size={6} />
        //                                 </div>
        //                             )}
        //                             {xButton && (
        //                                 <button
        //                                     className="focus:outline-none focus:ring-none focus:ring-offset-none"
        //                                     ref={cancelButtonRef}
        //                                     onClick={toggle}
        //                                 >
        //                                     <XIcon className="w-6 h-6 text-gray-700" />
        //                                 </button>
        //                             )}
        //                         </div>
        //                     )}
        //                     {children}
        //                 </div>
        //             </Transition.Child>
        //         </div>
        //     </Dialog>
        // </Transition.Root>
    );
};

export default Modal;
