import ImageCarouselDlg from 'components/image-carousel-dlg';
import { useState } from 'react';
import { classNames } from 'utils';

const CmtyEventPhotos = ({ className, photos }: { className?: string; photos: string[] }) => {
    const [isOpen, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const toggle = () => {
        setOpen(!isOpen);
    };

    if (!photos || photos.length === 0) return null;

    return (
        <div className={classNames(className || 'flex')}>
            {photos.map((photo, index) => (
                <div
                    key={index}
                    className=" w-16 h-16 rounded-md mx-1 mb-1 cursor-pointer"
                    style={{
                        backgroundImage: `url(${photo})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                    onClick={() => {
                        setCurrentIndex(index);
                        toggle();
                    }}
                ></div>
            ))}
            <ImageCarouselDlg isOpen={isOpen} toggle={toggle} currentIndex={currentIndex} images={photos || []} />
        </div>
    );
};

export default CmtyEventPhotos;
