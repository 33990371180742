import Routes from 'routes';
import GlobalComponent from 'components/global';

const App = () => {
    return (
        // wrap theme, localization providers
        <div id="app">
            <Routes />
            <GlobalComponent />
        </div>
    );
};

export default App;
