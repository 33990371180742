import { Menu, Transition } from '@headlessui/react';
import { isIOS, isSafari } from 'react-device-detect';
import {
    DeviceMobileIcon,
    IdentificationIcon,
    LibraryIcon,
    LockClosedIcon,
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline';
import { Fragment } from 'react';
import { useAppDispatch } from 'store/hook';
import { toggleLoginModal, toggleRegModal } from 'store/ui';

const Header = () => {
    const dispatch = useAppDispatch();

    return (
        <div className="fixed top-0 left-0 w-full h-auto bg-white z-20 shadow-sm">
            <div className="flex justify-between items-center md:space-x-10 px-4 xl:px-0 max-w-7xl py-2 mx-auto">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                    <a href="/" className="flex items-center">
                        <span className="sr-only">MyCommunityLives</span>
                        <img className="w-auto" src="/logo.png" alt="logo" />
                        <div className="ml-3  text-emerald-600 hover:text-emerald-700">
                            <h1 className="text-2xl font-bold leading-5">MCTL</h1>
                            <p className="text-sm font-light">Opark, Inc</p>
                        </div>
                    </a>
                </div>
                <div className="hidden md:flex flex-grow justify-end items-center">
                    <a
                        className="text-lg px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer"
                        href={
                            isSafari
                                ? 'https://apps.apple.com/us/app/qwikdocs/id1571348846'
                                : 'https://play.google.com/store/apps/details?id=com.oparkinc.mydocs&hl=en&gl=US'
                        }
                        target="_black"
                    >
                        Qwikdocs
                    </a>
                    <a
                        className="text-lg px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer"
                        href="https://www.myclublives.com"
                        target="_black"
                    >
                        Go MyClubLives
                    </a>
                    <div
                        className="text-lg px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer"
                        onClick={() => {
                            dispatch(toggleLoginModal());
                        }}
                    >
                        Login
                    </div>
                    <button
                        className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-emerald-600 hover:bg-emerald-700"
                        onClick={() => {
                            dispatch(toggleRegModal());
                        }}
                    >
                        Join the Community
                    </button>
                </div>
                <div className="md:hidden flex items-center">
                    <Menu as="div">
                        <Menu.Button>
                            {({ open }) => (open ? <XIcon className="h-8 w-8" /> : <MenuIcon className="h-8 w-8" />)}
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute z-10 w-screen top-20 right-0 px-0">
                                <div className="overflow-hidden shadow-xl ring-1 ring-black ring-opacity-5  bg-white py-4 px-6 sm:px-20">
                                    <a
                                        href={
                                            isIOS
                                                ? 'https://apps.apple.com/us/app/qwikdocs/id1571348846'
                                                : 'https://play.google.com/store/apps/details?id=com.oparkinc.mydocs&hl=en&gl=US'
                                        }
                                        target="_black"
                                        className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                    >
                                        <DeviceMobileIcon className="w-8 h-8" />
                                        <div className="ml-3">Qwikdocs</div>
                                    </a>
                                    <a
                                        href="https://www.myclublives.com"
                                        target="_black"
                                        className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                    >
                                        <LibraryIcon className="w-8 h-8" />
                                        <div className="ml-3">Go MyClubLives</div>
                                    </a>
                                    <div
                                        onClick={() => {
                                            dispatch(toggleLoginModal());
                                        }}
                                        className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                    >
                                        <LockClosedIcon className="w-8 h-8" />
                                        <div className="ml-3">Login</div>
                                    </div>
                                    <div
                                        onClick={() => {
                                            dispatch(toggleRegModal());
                                        }}
                                        className="text-lg px-0 md:px-6 py-3 text-gray-600 hover:text-emerald-600 cursor-pointer flex items-center"
                                    >
                                        <IdentificationIcon className="w-8 h-8" />
                                        <div className="ml-3">Register</div>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    );
};

export default Header;
