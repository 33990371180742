import { CmtyEventIdeaApi } from 'apis';
import { useEffect, useState } from 'react';
import htmlParse from 'html-react-parser';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { CmtyEventIdea, CmtyEventIdeaModel, User } from 'utils/types';
import Button from 'components/form-controls/Button';
import moment from 'moment-timezone';
import { handleHTTPError } from 'store/error';
import { MailIcon, PhoneIcon, ThumbUpIcon } from '@heroicons/react/outline';
import PageContentLayout from 'layouts/PageContentLayout';
import LikeUserPhoto from 'components/utils/LikeUserPhoto';
import { setCommonLoading, toggleLoginModal } from 'store/ui';
import constants from 'utils/constants';

const CmtyEventIdeaDetails = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const [postedCount, setPostedCount] = useState(0);
    const [awardedCount, setAwardedCount] = useState(0);
    const [postedIdeas, setPostedIdeas] = useState<CmtyEventIdea[]>([]);
    const ideaDefaultClub = useAppSelector((state) => state.club.ideaDefaultClub);
    const navigate = useNavigate();
    const urlParams = useParams();
    const [idea, setIdea] = useState<CmtyEventIdea | null>(null);

    useEffect(() => {
        const { id } = urlParams as { id: string };
        if (!id) return goBack();
        CmtyEventIdeaApi.read({ _id: id })
            .then((data) => {
                const idea = new CmtyEventIdea(data.data as CmtyEventIdeaModel);
                setPostedCount(data.postedIdeaCount || 0);
                setAwardedCount(data.awardedIdeaCount || 0);
                setIdea(idea);
                const params = {
                    '_id.ne': idea._id,
                    user: user?._id,
                    limit: 3,
                    sort_by: 'createdAt',
                    order_by: 'asc',
                };
                CmtyEventIdeaApi.retrieve(params).then((data) => {
                    const ideas = (data.data as CmtyEventIdeaModel[]).map((idata) => new CmtyEventIdea(idata));
                    setPostedIdeas(ideas);
                });
            })
            .catch((error) => {
                dispatch(handleHTTPError(error));
                goBack();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!idea) return null;

    const goBack = () => {
        navigate('/eventideas');
    };

    const likeEvent = () => {
        if (!user) return dispatch(toggleLoginModal());
        if (idea.isMyLikeIdea(user)) {
            dispatch(setCommonLoading(true));
            const params = { _id: idea._id, user: user?._id };
            CmtyEventIdeaApi.unlike(params)
                .then((data) => {
                    dispatch(setCommonLoading(false));
                    goBack();
                })
                .catch((error) => {
                    dispatch(setCommonLoading(false));
                    dispatch(handleHTTPError(error));
                });
        } else {
            dispatch(setCommonLoading(true));
            const params = { _id: idea._id, user: user?._id };
            CmtyEventIdeaApi.like(params)
                .then((data) => {
                    dispatch(setCommonLoading(false));
                    goBack();
                })
                .catch((error) => {
                    dispatch(setCommonLoading(false));
                    dispatch(handleHTTPError(error));
                });
        }
    };

    return (
        <PageContentLayout title={idea.title}>
            <div className="grid grid-cols-3 gap-4 md:gap-6">
                <div className="col-span-3 md:col-span-2">
                    <div className=" pt-5 pb-3">
                        <label className="text-sm text-gray-500 font-normal mb-1 block">
                            Posted Date | Expire Date
                        </label>
                        <p className="text-base text-gray-700 font-medium">
                            {moment(idea.createdAt).format('dddd, MMM DD, YYYY')}
                            <span className="text-gray-400">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            {moment(idea.expireDate).format('dddd, MMM DD, YYYY')}
                        </p>
                    </div>
                    <div className=" py-3">
                        <label className="text-sm text-gray-500 font-normal mb-1 block">
                            Preferred Event Date Range
                        </label>
                        <p className="text-base text-gray-700 font-medium">
                            {moment(idea.apxStart).format('dddd, MMM DD, YYYY')} ~{' '}
                            {moment(idea.apxEnd).format('dddd, MMM DD, YYYY')}
                        </p>
                    </div>
                    <div className=" py-3">
                        <label className="text-sm text-gray-500 font-normal mb-1 block">Preferred event place</label>
                        {idea.getClub() ? (
                            <p className="text-base text-gray-700 font-medium">{idea.getClub()?.displayName || ''}</p>
                        ) : (
                            <>
                                {idea.venue === constants.VIRTUAL_CMTYEVENT_VENUE ? (
                                    <p className="text-base text-gray-700 font-medium">
                                        Any Club supports Virtual event
                                    </p>
                                ) : (
                                    <p className="text-base text-gray-700 font-medium">
                                        <span className=" font-medium capitalize">
                                            {ideaDefaultClub?.displayName}{' '}
                                            {idea.getLocation() ? ` | ${idea.getLocation()?.name}` : ''}
                                        </span>
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                    {idea.venue === constants.VIRTUAL_CMTYEVENT_VENUE && idea.url && (
                        <div className=" py-3">
                            <label className="text-sm text-gray-500 font-normal mb-1 block">Reference URL</label>
                            <p className="text-base text-gray-700 font-medium">{idea.url}</p>
                        </div>
                    )}
                    <div className="py-3">
                        <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                            {idea.eventType}
                        </span>
                        <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                            {idea.venue}
                        </span>
                        <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                            {idea.eligibleGender === 'all' ? 'All Genders' : idea.eligibleGender}
                        </span>
                        <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                            {idea.ageRanges.join(', ')}
                        </span>
                        {idea.isMatchEvent && (
                            <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                                {idea.isAllEligibleLevel()
                                    ? 'All Levels'
                                    : `Level: (${idea.eligibleLevel?.from} ~ ${idea.eligibleLevel?.to})`}
                            </span>
                        )}

                        <span className="px-4 py-2 text-sm text-gray-600 rounded-full border border-emerald-600 inline-block mb-2 mr-2 capitalize">
                            {idea.isFree ? 'Free' : `$${idea.price}`}
                        </span>
                    </div>
                    <div className="py-3">
                        <label className="text-base text-gray-700 font-medium mb-2 block">Description</label>
                        <div className=" text-sm text-gray-600">{htmlParse(idea.description)}</div>
                    </div>
                </div>
                <div className="col-span-3 md:col-span-1 border rounded-md p-4 md:p-6">
                    <div>
                        <div className="flex items-center pb-3">
                            {(idea.user as User).photoUrl ? (
                                <img
                                    src={(idea.user as User).photoUrl}
                                    className="h-12 w-12 rounded-full border-gray-300 border"
                                    alt="club logo"
                                />
                            ) : (
                                <div className=" rounded-full w-12 h-12 bg-gray-300 flex justify-center items-center">
                                    {(idea.user as User).fullName[0].toUpperCase()}
                                </div>
                            )}
                            <div className="ml-2">
                                <p className="text-gray-500 text-sm font-medium mb-1">{(idea.user as User).fullName}</p>
                                <p className="text-gray-500 text-xs">({(idea.user as User).getGameLevel()})</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between py-4 border-t text-gray-800">
                            <p className="text-sm">Posted Ideas</p>
                            <p className="text-sm font-medium">{postedCount}</p>
                        </div>
                        <div className="flex items-center justify-between py-4 border-y text-gray-800">
                            <p className="text-sm">Awarded Ideas</p>
                            <p className="text-sm font-medium">{awardedCount}</p>
                        </div>
                    </div>
                    <div className="py-4 md:py-6 border-b">
                        <label className="text-base font-normal text-gray-800 mb-3 block">Contact Information</label>
                        <a
                            className="flex items-center text-sm text-gray-500 mb-1 hover:text-gray-600 cursor-pointer hover:underline"
                            href={`mailto:${idea.contactEmail}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <MailIcon className="w-4 h-4 mr-2" />
                            <span>{idea.contactEmail}</span>
                        </a>
                        {idea.contactNumber && (
                            <div className="flex items-center text-sm text-gray-500 mb-1 ">
                                <PhoneIcon className="w-4 h-4 mr-2" />
                                <span>{idea.contactNumber}</span>
                            </div>
                        )}
                    </div>
                    {postedIdeas.length > 0 && (
                        <div className="py-4 md:py-6 border-b">
                            <label className="text-base font-normal text-gray-800 mb-3 block">
                                More Ideas from {(idea.user as User).fullName}
                            </label>
                            {postedIdeas.map((eventIdea, index) => (
                                <div className="mb-2" key={index}>
                                    <a
                                        className="text-sm text-emerald-600 hover:text-emerald-700 block underline"
                                        href={`/eventideas/${eventIdea._id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {eventIdea.title}
                                    </a>
                                    <p className="text-xs text-gray-500">
                                        {moment(eventIdea.createdAt).format('MMMM DD, YYYY')}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="py-4 md:py-6">
                        <label className="text-base font-normal text-gray-800 mb-3 block">
                            Members who like this idea
                        </label>
                        <div className="flex flex-wrap">
                            {idea.likeMembers.slice(0, 10).map((member, index) => (
                                <LikeUserPhoto size={10} user={member} className="m-1" key={index} />
                            ))}
                        </div>
                        <div className="mt-6">
                            <ThumbUpIcon className="w-6 h-6 text-emerald-600 mx-auto" />
                            <p className="text-base font-medium text-center text-emerald-600">
                                ({idea.likeMembers.length})
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-span-3 flex justify-end">
                    <Button
                        textColor="text-gray-700"
                        className="border border-gray-300 min-w-24 mr-3"
                        color="bg-white"
                        hoverColor="bg-gray-50"
                        onClick={() => {
                            goBack();
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        color={idea.isMyLikeIdea(user) ? 'bg-amber-600' : 'bg-emerald-600'}
                        hoverColor={idea.isMyLikeIdea(user) ? 'bg-amber-700' : 'bg-emerald-700'}
                        className=" w-42"
                        onClick={likeEvent}
                    >
                        <>
                            <ThumbUpIcon className="w-4 h-4 mr-2 inline-block" />
                            {idea.isMyLikeIdea(user) ? <span>Cancel Like</span> : <span>I like this idea</span>}
                        </>
                    </Button>
                </div>
            </div>
        </PageContentLayout>
    );
};

export default CmtyEventIdeaDetails;
