import { ReactNode } from 'react';
import { useAppSelector } from 'store/hook';
import ScrollTop from './ScrollTop';
import { classNames } from 'utils';

interface LayoutProps {
    title?: JSX.Element | string;
    className?: string;
    children?: ReactNode;
    scrollTop?: boolean;
}

const PageContentLayout = ({ title, className, children, scrollTop = true }: LayoutProps) => {
    const user = useAppSelector((state) => state.auth.user);

    return (
        <div className={classNames('bg-white w-full', user ? 'rounded-md p-4 md:p-10' : 'min-h-96', className || '')}>
            {scrollTop && <ScrollTop />}
            {title && <div className="page-title">{title}</div>}
            {children}
        </div>
    );
};
export default PageContentLayout;
