import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ClubApi } from 'apis';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleHTTPError } from 'store/error';
import DatePicker from 'components/form-controls/datepicker';
import moment from 'moment-timezone';
import Button from 'components/form-controls/Button';
import { classNames } from 'utils';
import { SearchIcon } from '@heroicons/react/outline';
import SearchableSelect from 'components/form-controls/SearchableSelect';
import { City, CmtyEventFilterOptions } from 'utils/types';
import { putCmtyEventFilterOptions } from 'store/criteria';
// import { TagIcon } from '@heroicons/react/outline';

type HomeSearchpanelProps = {
    className?: string;
};

const HomeSearchPanel: React.FC<HomeSearchpanelProps> = ({ className = '' }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const zipcodes = useAppSelector((state) => state.club.systemSetting.zipCodes);
    const user = useAppSelector((state) => state.auth.user);
    // const orgTags = useAppSelector((state) => state.club.systemSetting.orgTags);
    const filterOptions = useAppSelector((state) => state.criteria.cmtyEventFilterOptions);
    const clubs = useAppSelector((state) => state.club.clubs);
    const [cities, setCities] = useState<City[]>([]);

    useEffect(() => {
        if (user?.favoriteClubs && user?.favoriteClubs.length > 0 && !filterOptions.organization)
            updateFilterOptions({ organization: user?.favoriteClubs[0] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (filterOptions.zipCode) {
            ClubApi.retriveCities({ 'zipCode.in': filterOptions.zipCode })
                .then((data) => {
                    setCities(
                        (data.data as City[]).filter(
                            (city, index, self) => self.findIndex((c) => c.city === city.city) === index
                        )
                    );
                })
                .catch((error) => {
                    dispatch(handleHTTPError(error));
                });
        } else {
            setCities([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOptions.zipCode]);

    const updateFilterOptions = (options: CmtyEventFilterOptions) => {
        const newFilterOptions = { ...filterOptions, ...options };
        dispatch(putCmtyEventFilterOptions(newFilterOptions));
    };

    const getZipCodeOptions = () => {
        return zipcodes.map((code) => {
            return { value: code.value, label: `${code.name} (Zip Code: ${code.value})` };
        });
    };

    const getCityOptions = () => {
        return cities.map((city: City) => {
            return {
                value: city.city,
                label: `${city.city}, ${city.county}, ${city.state}`,
            };
        });
    };

    const getOrganizationOptions = () => {
        const clubOptions = clubs.map((club) => {
            return {
                value: club._id,
                label: club.displayName,
            };
        });
        // const tagOptions = orgTags.map((tag) => {
        //     return { value: tag, label: tag, icon: <TagIcon /> };
        // });
        // return [...clubOptions, ...tagOptions].sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        return clubOptions.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
    };

    return (
        <div className={classNames('absolute bottom-0 z-10 inset-x-0 flex justify-center', className)}>
            <div className=" absolute bottom-0 h-1/2 bg-gray-50 w-full z-0" />
            <div className="max-w-5xl w-full p-0 md:p-10 bg-white rounded-lg grid grid-cols-1 md:grid-cols-6 gap-3 md:gap-6 relative shadow-2xl">
                <div className="col-span-2 mb-2 md:mb-0">
                    <SearchableSelect
                        name="organization"
                        label="Specific Location"
                        labelClassName="text-xs mt-4"
                        placeholder="Park District or Club Name​"
                        clearable
                        value={filterOptions.organization}
                        options={getOrganizationOptions()}
                        onChange={(value) => {
                            updateFilterOptions({ organization: value as string, city: undefined });
                        }}
                    />
                </div>
                <div className="col-span-2 mb-2 md:mb-0">
                    <SearchableSelect
                        name="zipCode"
                        label="Enter first couple of letters of the state code, and then pull down and select the area. "
                        labelClassName="text-xs"
                        clearable
                        value={filterOptions.zipCode}
                        placeholder="Geographic Area"
                        options={getZipCodeOptions()}
                        onChange={(value) => {
                            updateFilterOptions({ zipCode: value as string, city: undefined });
                        }}
                    />
                </div>
                <div className=" col-span-2 mb-2 md:mb-0">
                    <SearchableSelect
                        name="city"
                        label="If you entered a state, you can enter first couple letters of city and then select from the pull down​"
                        labelClassName="text-xs"
                        placeholder="City"
                        clearable
                        value={filterOptions.city?.city}
                        options={getCityOptions()}
                        onChange={(value) => {
                            const city = cities.find((ct) => ct.city === value);
                            updateFilterOptions({ city, organization: undefined });
                        }}
                    />
                </div>
                <div className=" col-span-4 block sm:flex items-center">
                    <DatePicker
                        className=" flex-grow mb-2 mr-0 sm:mr-2 md:mr-0 md:mb-0"
                        name="filterStDate"
                        selected={moment(filterOptions.stDate).toDate()}
                        dateFormat="MMMM dd, yyyy"
                        onChange={(date) => {
                            updateFilterOptions({ stDate: moment(date).format('YYYY-MM-DD HH:mm') });
                        }}
                    />
                    <p className="px-3 text-sm text-gray-700 hidden md:block">To</p>
                    <DatePicker
                        className=" flex-grow mb-2 md:mb-0"
                        name="filterEtDate"
                        selected={moment(filterOptions.etDate).toDate()}
                        dateFormat="MMMM dd, yyyy"
                        onChange={(date) => {
                            updateFilterOptions({ etDate: moment(date).format('YYYY-MM-DD HH:mm') });
                        }}
                    />
                </div>
                <div className="col-span-2">
                    <Button className="w-full" onClick={() => navigate('/cmtyevents')}>
                        <>
                            <SearchIcon className="w-5 h-5 inline-block mr-6" />
                            <span>Find Community Event</span>
                        </>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default HomeSearchPanel;
