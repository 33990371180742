import { useForm } from 'react-hook-form';
import { AuthApi } from 'apis';
import Button from 'components/form-controls/Button';
import TextBox from 'components/form-controls/TextBox';
import { putUser } from 'store/auth';
import { handleHTTPError } from 'store/error';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setCommonLoading, showAlert } from 'store/ui';
import { User, UserModel } from 'utils/types';

type FormValues = {
    tempClubName?: string;
    tempClubPhone?: string;
};

const TempClub = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);

    const { register, formState, handleSubmit } = useForm<FormValues>({
        defaultValues: {
            tempClubName: user?.tempClub?.name || '',
            tempClubPhone: user?.tempClub?.phoneNumber || '',
        },
    });

    const save = (data: FormValues) => {
        const params = {
            _id: user?._id,
            ...data,
        };
        dispatch(setCommonLoading(true));
        AuthApi.update(params)
            .then((data) => {
                dispatch(setCommonLoading(false));
                dispatch(putUser(new User(data.data as UserModel)));
                dispatch(showAlert({ type: 'success', message: 'Club information has been saved.' }));
            })
            .catch((error) => {
                console.log(error);
                dispatch(setCommonLoading(false));
                dispatch(handleHTTPError(error));
            });
    };

    if ((user?.originClubs || []).length > 0) return null;

    return (
        <form className="pb-6 border-b" onSubmit={handleSubmit(save)} autoComplete="off">
            <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 md:col-span-1 flex flex-col">
                    <h5 className="text-xl text-gray-800">Member Club Information</h5>
                    <p className="text-gray-500 text-sm">
                        It will be helpful to prove you if you share this information
                    </p>
                </div>
                <div className="bg-white col-span-3 md:col-span-2 rounded-md shadow grid grid-cols-6 gap-6 p-4 md:p-10">
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="tempClubName"
                        label="Temp Club Name"
                        placeholder="Mission Tennis Club"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                            rules: {
                                required: true,
                            },
                        }}
                    />
                    <TextBox
                        className="col-span-6 md:col-span-3"
                        name="tempClubPhone"
                        label="Temp Club Phone Number"
                        placeholder="9456213584"
                        defaultValue=""
                        type="text"
                        validation={{
                            register,
                            formState,
                        }}
                    />
                    <div className="col-span-6 flex justify-end mt-5">
                        <Button className=" w-36" type="submit">
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default TempClub;
