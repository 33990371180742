import { useAppDispatch } from 'store/hook';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { showAlert, toggleRegModal } from 'store/ui';
import { AuthApi } from 'apis';
import TextBox from 'components/form-controls/TextBox';
import Button from 'components/form-controls/Button';
import { useState } from 'react';
import { login } from 'store/auth';
import { Club, User } from 'utils/types';
import { handleHTTPError } from 'store/error';
import Select from 'components/form-controls/Select';

type FormValues = {
    email: string;
    club: string;
};

type PropsType = {
    className?: string;
    clubs: Club[];
};

const WithClub = ({ className, clubs }: PropsType) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { register, formState, handleSubmit, control } = useForm<FormValues>({
        defaultValues: { email: '', club: '' },
    });
    const [loading, setLoading] = useState(false);

    const submit = (data: FormValues) => {
        if (loading) return;
        const { club, email } = data;
        const params = { email, isCmtyEventUser: true, originClub: club };
        setLoading(true);
        AuthApi.register(params)
            .then((data) => {
                dispatch(showAlert({ type: 'success', message: data.message }));
                setLoading(false);
                const { user, token } = data;
                if (user?.isActivated) {
                    dispatch(login({ user: new User(user), token }));
                    navigate('/profile?redirect=/cmtyevents');
                }
                dispatch(toggleRegModal());
            })
            .catch((error) => {
                setLoading(false);
                dispatch(handleHTTPError(error));
            });
    };
    const getClubOptions = () => {
        return clubs.map((club) => {
            return { value: club._id, label: club.displayName };
        });
    };

    return (
        <form className={className || ''} onSubmit={handleSubmit(submit)} autoComplete="off">
            <div className="block sm:grid sm:grid-cols-2 gap-3 mb-5">
                <Select
                    className=" col-span-2"
                    name="club"
                    required
                    label="My Club"
                    placeholder="Mission Tennis Club"
                    options={getClubOptions()}
                    defaultValue=""
                    validation={{
                        control,
                        formState,
                        rules: {
                            required: true,
                        },
                    }}
                />
                <TextBox
                    className="col-span-2"
                    name="email"
                    label={
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1 text-left">
                            Email Address
                            <span className=" text-red-500 ml-1">*</span>
                            <span className="text-xs">(please input email address you use for your club)</span>
                        </label>
                    }
                    placeholder="John@mail.com"
                    defaultValue=""
                    required
                    type="text"
                    validation={{
                        register,
                        formState,
                        rules: {
                            required: true,
                            pattern:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        },
                    }}
                />
            </div>
            <Button className="w-full" type="submit" loading={loading}>
                Register
            </Button>
        </form>
    );
};

export default WithClub;
