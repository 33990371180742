import { Navigate, useLocation } from 'react-router';
import { useAppSelector } from 'store/hook';
import AuthLayout from './authlayout';
import PublicLayout from './publiclayout';

const Layouts = ({ publicRoutes }: { publicRoutes: string[] }) => {
    const location = useLocation();
    const user = useAppSelector((state) => state.auth.user);

    if (!user && !publicRoutes.some((route) => location.pathname.includes(route)))
        return <Navigate to="/cmtyevents" replace />;

    return <>{user ? <AuthLayout /> : <PublicLayout />}</>;
};
export default Layouts;
