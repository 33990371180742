import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import './index.scss';
import { NewspaperIcon, OfficeBuildingIcon, UserGroupIcon } from '@heroicons/react/outline';

const HomeAboutUs: React.FC = () => {
    return (
        <div className="w-full h-auto lg:h-screen white relative bg-gray-50 px-4 lg:px-32 py-20 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-20">
            <div className="col-span-1">
                <Swiper
                    slidesPerView={2}
                    spaceBetween={30}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    className="aboutus-carousel"
                >
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/1.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/2.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/3.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/4.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/5.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/6.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/7.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/8.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/9.jpg" alt="about us carousel" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/aboutus-carousel/10.jpg" alt="about us carousel" />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="col-span-1 flex justify-center items-center">
                <div className="w-full">
                    <p className="text-center text-emerald-600 font-bold mb-5 text-xl">About Us</p>
                    <h2 className="text-gray-800 text-4xl lg:text-5xl text-center font-bold pl-4 lg:pl-10 mb-10">
                        My Community Lives
                    </h2>
                    <p className=" text-gray-500 text-xl lg:text-2xl text-center">
                        Expands the boundaries of communities to cross geographic and organizational limitations​
                    </p>
                    <p className=" text-gray-500 text-xl lg:text-2xl text-center mb-20">
                        Enables community residents to view, register, and pay for activities in neighboring communities
                        and far away places. ​
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="col-span-1 h-60 bg-emerald-500 text-center text-white flex justify-center items-center">
                            <div className="w-full">
                                <UserGroupIcon className="h-10 w-10 mx-auto" />
                                <p className="text-5xl text-center py-2">4896</p>
                                <p className="text-sm">Community Users</p>
                            </div>
                        </div>
                        <div className="col-span-1 h-60 bg-emerald-500 text-center text-white flex justify-center items-center">
                            <div className="w-full">
                                <OfficeBuildingIcon className="h-10 w-10 mx-auto" />
                                <p className="text-5xl text-center py-2">52</p>
                                <p className="text-sm">Community Organizations</p>
                            </div>
                        </div>
                        <div className="col-span-1 h-60 bg-emerald-500 text-center text-white flex justify-center items-center">
                            <div className="w-full">
                                <NewspaperIcon className="h-10 w-10 mx-auto" />
                                <p className="text-5xl text-center py-2">38964</p>
                                <p className="text-sm">Community Events</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutUs;
